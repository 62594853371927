import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { AuthProvider } from "./auth/AuthProvider.tsx";
import { ApiClientProvider } from "./api/ApiClientProvider.tsx";
import ShareInfoProvider from "./ShareInfoProvider.tsx";
import { ThemeProvider } from "./styles/themes.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthProvider>
      <ApiClientProvider>
        <ShareInfoProvider>
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </ShareInfoProvider>
      </ApiClientProvider>
    </AuthProvider>
  </React.StrictMode>,
);
