import React, { PropsWithChildren } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTrigger,
  DialogTitle,
  DialogContent,
  DialogBody,
  Button,
  tokens,
  TableColumnDefinition,
  createTableColumn,
  TableCellLayout,
  Spinner,
  DataGridHeader,
  DataGridRow,
  DataGridHeaderCell,
  DataGridBody,
  DataGridCell,
  DataGrid,
  Image,
} from "@fluentui/react-components";
import { DismissRegular, FolderOpenRegular } from "@fluentui/react-icons";
import { IReport } from "scout-sharing-models";
import { ArrowDownloadRegular } from "@fluentui/react-icons/lib/fonts";
import { FileStatusEnum } from "../../../../../../interface.ts";
import moment from "moment";
import { downloadFile } from "../../../../../../utils.ts";
import NonIdealState from "../../../../../../components/NonIdealState/NonIdealState.tsx";

type FileCell = {
  label: string;
  filetype: string;
  icon: JSX.Element;
};

type CreatedCell = {
  label: string;
  timestamp: moment.Moment;
};

type Item = {
  file: FileCell;
  status: string;
  created: CreatedCell;
  actions: {
    download: {
      handleOnClick: () => void;
      disabled: boolean;
    };
  };
};

const columns: TableColumnDefinition<Item>[] = [
  createTableColumn<Item>({
    columnId: "file",
    compare: (a, b) => {
      return a.file.filetype.localeCompare(b.file.filetype);
    },
    renderHeaderCell: () => {
      return "Filetype";
    },
    renderCell: (item) => {
      return <TableCellLayout media={item.file.icon}></TableCellLayout>;
    },
  }),
  createTableColumn<Item>({
    columnId: "status",
    compare: (a, b) => {
      return a.status.localeCompare(b.status);
    },
    renderHeaderCell: () => {
      return "Status";
    },

    renderCell: (item) => {
      return item.status;
    },
  }),
  createTableColumn<Item>({
    columnId: "created",
    compare: (a, b) => {
      return a.created.timestamp.diff(b.created.timestamp);
    },
    renderHeaderCell: () => {
      return "Created";
    },

    renderCell: (item) => {
      return item.created.label;
    },
  }),
  createTableColumn<Item>({
    columnId: "actions",
    renderHeaderCell: () => {
      return "Actions";
    },
    renderCell: (item) => {
      return (
        <div style={{ display: "flex", gap: tokens.spacingHorizontalS }}>
          <Button
            aria-label="Downlaod"
            icon={<ArrowDownloadRegular />}
            onClick={item.actions.download.handleOnClick}
            disabled={item.actions.download.disabled}
            appearance={"subtle"}
          />
        </div>
      );
    },
  }),
];

type ReportDialogProps = {
  reports: IReport[];
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const ReportDialog: React.FC<PropsWithChildren<ReportDialogProps>> = (
  props,
) => {
  const items = props.reports.map((report, i) => ({
    file: {
      label: `Report_${i}`,
      filetype: report.file !== null ? ".docx" : "Unknown",
      icon:
        report.file !== null ? (
          <Image src={"/docx.png"} alt="docx" style={{ width: "24px" }} />
        ) : (
          <Spinner size={"extra-small"} />
        ),
    },
    status: FileStatusEnum[report.file_status],
    created: {
      label: moment(report.created).format("LLL"),
      timestamp: moment(report.created),
    },
    actions: {
      download: {
        handleOnClick: () =>
          report.file &&
          downloadFile(
            report.file,
            `Report_${report.inspection.name}_${report.created}.docx`,
          ),
        disabled: report.file === null,
      },
    },
  }));

  return (
    <Dialog
      open={props.open}
      onOpenChange={(_, data) => {
        props.setOpen(data.open);
      }}
    >
      <DialogSurface>
        <DialogBody style={{ minHeight: "300px" }}>
          <DialogTitle
            action={
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="subtle" icon={<DismissRegular />} />
              </DialogTrigger>
            }
          >
            Reports
          </DialogTitle>
          <DialogContent>
            <DataGrid
              items={items}
              columns={columns}
              sortable
              getRowId={(item) => item.file.label}
              focusMode="composite"
            >
              <DataGridHeader>
                <DataGridRow>
                  {({ renderHeaderCell }) => (
                    <DataGridHeaderCell>
                      {renderHeaderCell()}
                    </DataGridHeaderCell>
                  )}
                </DataGridRow>
              </DataGridHeader>
              <DataGridBody<Item>>
                {({ item, rowId }) => (
                  <DataGridRow<Item> key={rowId}>
                    {({ renderCell }) => (
                      <DataGridCell>{renderCell(item)}</DataGridCell>
                    )}
                  </DataGridRow>
                )}
              </DataGridBody>
            </DataGrid>
            {items.length === 0 && (
              <div
                style={{
                  height: "80%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <NonIdealState
                  icon={<FolderOpenRegular />}
                  title={"No reports"}
                  description={""}
                />
              </div>
            )}
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
