import { IFinding } from "scout-sharing-models";

export type FilterOption = {
  name: string;
  predicate: (finding: IFinding) => boolean;
};

export type FilterGroup = {
  groupName: string;
  filters: { [key: string]: FilterOption };
};

const FindingsFilters: {
  [key: string]: FilterGroup;
} = {
  flagged: {
    groupName: "Flagged",
    filters: {
      flagged: {
        name: "Flagged",
        predicate: (finding: IFinding) => finding.flagged,
      },
      notFlagged: {
        name: "Not flagged",
        predicate: (finding: IFinding) => !finding.flagged,
      },
    },
  },
  report: {
    groupName: "Report",
    filters: {
      flagged: {
        name: "In report",
        predicate: (finding: IFinding) => finding.include_in_report,
      },
      notFlagged: {
        name: "Not in report",
        predicate: (finding: IFinding) => !finding.include_in_report,
      },
    },
  },
  type: {
    groupName: "Type",
    filters: {
      crack: {
        name: "Crack",
        predicate: (finding: IFinding) => finding.type.name === "Crack",
      },
      corrosion: {
        name: "Corrosion",
        predicate: (finding: IFinding) => finding.type.name === "Corrosion",
      },
      deformation: {
        name: "Deformation",
        predicate: (finding: IFinding) => finding.type.name === "Deformation",
      },
      other: {
        name: "Other",
        predicate: (finding: IFinding) => finding.type.name === "Other",
      },
      unclassified: {
        name: "Unclassified",
        predicate: (finding: IFinding) => finding.type.name === "Unclassified",
      },
    },
  },
  severity: {
    groupName: "Severity",
    filters: {
      low: {
        name: "Low",
        predicate: (finding: IFinding) => finding.severity === "LOW",
      },
      medium: {
        name: "Medium",
        predicate: (finding: IFinding) => finding.severity === "MEDIUM",
      },
      high: {
        name: "High",
        predicate: (finding: IFinding) => finding.severity === "HIGH",
      },
      notApplicable: {
        name: "Not applicable",
        predicate: (finding: IFinding) => finding.severity === "NOT APPLICABLE",
      },
    },
  },
};

export default FindingsFilters;
