import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import FindingCard, {
  FindingCardFallback,
} from "../FindingCard/FindingCard.tsx";
import React, { useEffect, useMemo, useState } from "react";
import { GroupedFindings, orderGroupedFindings } from "./utils.ts";
import { useSearchParams } from "react-router-dom";
import { FindingsDialog } from "../FindingsDialog/FindingsDialog.tsx";
import FindingsControllerProvider from "../FindingsDialog/FindingsDialogControllerProvider.tsx";
import { IFinding } from "scout-sharing-models";

const useStyles = makeStyles({
  grid: {
    ...shorthands.gap(tokens.spacingHorizontalM),
    display: "grid",
    gridTemplateRows: "max-content",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    "@container (min-width: 480px) and (max-width: 720px)": {
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    },
    "@container (min-width: 720px) and (max-width: 1200px)": {
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    },
    "@container (min-width: 1200px)": {
      gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
    },
  },
});

export type FindingsGalleryProps = {
  groupedFindings: GroupedFindings;
  handleViewInVideo: (finding: IFinding) => void;
};

const FindingsGallery: React.FC<FindingsGalleryProps> = (props) => {
  const styles = useStyles();

  const [findingsDialogOpen, setFindingsDialogOpen] = useState(false);
  const [openItems, setOpenItems] = useState<string[]>([]);

  const [searchParams] = useSearchParams();

  const orderedFindings = useMemo(
    () => orderGroupedFindings(props.groupedFindings),
    [props.groupedFindings],
  );

  const handleToggle: AccordionToggleEventHandler<string> = (_, data) => {
    setOpenItems(data.openItems);
  };

  useEffect(() => {
    setOpenItems(Object.keys(props.groupedFindings));
  }, [props.groupedFindings]);

  useEffect(() => {
    setFindingsDialogOpen(searchParams.get("poiDialog") === "true");
  }, [searchParams]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        containerType: "inline-size",
        overflowY: "auto",
      }}
    >
      <Accordion
        collapsible
        openItems={openItems}
        multiple
        onToggle={handleToggle}
      >
        {Object.entries(props.groupedFindings).map(([groupName, findings]) => {
          return (
            <AccordionItem value={groupName} key={groupName}>
              <AccordionHeader>
                {groupName} ({findings.length})
              </AccordionHeader>
              <AccordionPanel>
                <div className={styles.grid}>
                  {findings.map((finding) => {
                    return (
                      <FindingCard
                        key={finding.id}
                        finding={finding}
                        handleViewInVideo={() =>
                          props.handleViewInVideo(finding)
                        }
                      />
                    );
                  })}
                </div>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      <FindingsControllerProvider findings={orderedFindings}>
        <FindingsDialog
          open={findingsDialogOpen}
          handleViewInVideo={props.handleViewInVideo}
        />
      </FindingsControllerProvider>
    </div>
  );
};

export const FindingsGalleryFallback = () => {
  const styles = useStyles();
  return (
    <div
      style={{ height: "100%", width: "100%", containerType: "inline-size" }}
    >
      <div className={styles.grid}>
        {Array(7)
          .fill(0)
          .map((_, i) => {
            return <FindingCardFallback key={i} />;
          })}
      </div>
    </div>
  );
};

export default FindingsGallery;
