import { ShaderMaterial, Texture } from "three";

type ShaderMaterialUniform = typeof ShaderMaterial.prototype.uniforms;
export interface Uniforms extends ShaderMaterialUniform {
  uMaxZ: { value: number };
  uMinZ: { value: number };
  /**
   * Actual point size should be divided by 2.0.
   * This is because of a performance optimization in the shader.
   * This avoids us performing an extra division operation in each shader when calculating the gl_PointSize.
   */
  uPointSize: { value: number };
  uViewPortHeight: { value: number };
  uOpacity: { value: number };
  uColorMode: { value: number };
  uPaletteTexture: { value: Texture | undefined };
  uPaletteUVOffset: { value: number };
}

export const DEF_HAS_INTENSITY = "HAS_INTENSITY";
export const DEF_HAS_REFLECTIVITY = "HAS_REFLECTIVITY";

export const availableColoringModes = {
  height: 0,
  intensity: 1,
  reflectivity: 2,
  // Add more modes as needed
};

export const vertexShader = /* glsl */ `
#ifdef ${DEF_HAS_INTENSITY}
attribute float intensity;
#endif

#ifdef ${DEF_HAS_REFLECTIVITY}
attribute float reflectivity;
#endif

uniform float uOpacity;
uniform float uMaxZ;
uniform float uMinZ;
uniform float uPointSize;
uniform float uViewPortHeight;
uniform float uPaletteUVOffset;
uniform int uColorMode;

// load 2d palette texture
uniform sampler2D uPaletteTexture;

// position of the point in object space
out vec4 vColor;

void main() {
    int colorMode = uColorMode;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    // scale the point size based on distance from camera
    // projectionMatrix[1][1] is the scale factor for y
    // we scale the view port height to get the view port height in clip space
    // we then scale the point size by the inverse of the distance from the camera
    // gl_Position.w is the 4th component of the position homogenous vector
    // we divide by gl_Position.w to get the normalized device scalar value for point size.
    gl_PointSize = (uViewPortHeight * projectionMatrix[1][1] * uPointSize / gl_Position.w);

    vColor = texture(uPaletteTexture, vec2((position.z - uMinZ) / (uMaxZ - uMinZ), uPaletteUVOffset ));
    #ifdef ${DEF_HAS_INTENSITY}
        if (colorMode == ${availableColoringModes.intensity}) {
            vColor = texture(uPaletteTexture, vec2(intensity, uPaletteUVOffset ));
        }
    #endif
    #ifdef ${DEF_HAS_REFLECTIVITY}
        if (colorMode == ${availableColoringModes.reflectivity}) {
            vColor = texture(uPaletteTexture, vec2(reflectivity, uPaletteUVOffset ));
        }
    #endif

    vColor.a = uOpacity;
}
`;

export const fragmentShader = /* glsl */ `
in vec4 vColor;

void main() {
    gl_FragColor = vColor;
}
`;
