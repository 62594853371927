import style from "./additional-data.module.scss";
import React from "react";
import { ILEDBrightness } from "scout-sharing-models";
import { additionalDataInfo } from "../../../../../utils.ts";
import { Subtitle2 } from "@fluentui/react-components";

interface IProps {
  ledBrightness?: ILEDBrightness;
}

const LightsPane: React.FC<IProps> = (props: IProps) => {
  const info = additionalDataInfo.lights;
  return (
    <div className={style.pane}>
      <div
        className={style.paneElementContainer}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Subtitle2 align={"center"}>Lights</Subtitle2>
      </div>
      <div className={style.paneElementContainer}>
        {props.ledBrightness ? (
          <div className={style.columnContainer}>
            <div className={style.rowContainer}>
              <div className={style.columnContainer}>
                Left
                <div className={style.lightInfo}>
                  <div className={style.singleLight}>
                    <div>Top</div>
                    <div>{info.formatValue(props.ledBrightness.left_top)}</div>
                  </div>
                  <div className={style.singleLight}>
                    <div>Middle</div>
                    <div>
                      {info.formatValue(props.ledBrightness.left_middle)}
                    </div>
                  </div>
                  <div className={style.singleLight}>
                    <div>Bottom</div>
                    <div>
                      {info.formatValue(props.ledBrightness.left_bottom)}
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.columnContainer}>
                Right
                <div className={style.lightInfo}>
                  <div className={style.singleLight}>
                    <div>Top</div>
                    <div>{info.formatValue(props.ledBrightness.right_top)}</div>
                  </div>
                  <div className={style.singleLight}>
                    <div>Middle</div>
                    <div>
                      {info.formatValue(props.ledBrightness.right_middle)}
                    </div>
                  </div>
                  <div className={style.singleLight}>
                    <div>Bottom</div>
                    <div>
                      {info.formatValue(props.ledBrightness.right_bottom)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className={style.columnContainer}>NA</div>
        )}
      </div>
      <div className={style.paneElementContainer} />
    </div>
  );
};

export default LightsPane;
