import { useContext, useEffect } from "react";
import * as THREE from "three";
import { SceneContext } from "../virtualScene.tsx";

import { useInScene } from "../../hooks/useInScene.ts";

export const MeasurementsCylinder = (props: {
  idx: number;
  position: THREE.Vector3;
  orientation: THREE.Quaternion;
}) => {
  const scene = useContext(SceneContext);
  useInScene(
    true,
    true,
    () => {
      const cylinder = new THREE.Mesh(
        new THREE.CylinderGeometry(0.1, 0.1, 0.05, 32),
        new THREE.MeshLambertMaterial({
          color: 0xa0ff00,
          opacity: 0.7,
          transparent: true,
        }),
      );

      cylinder.position.copy(props.position);
      cylinder.quaternion.copy(props.orientation);

      // Rotate cylinder such that the base points to the front of the drone
      cylinder.rotateZ(Math.PI / 2);
      // Translate cylinder such that it is in front of the drone
      cylinder.translateY(-0.4);

      return cylinder;
    },
    { type: "measurement", idx: props.idx },
    [props.position, props.orientation],
  );
  useEffect(() => {
    scene.invalidate();
  });
  return <></>;
};
