import React, { useContext } from "react";
import ErrorPage from "./ErrorPage";
import {
  ArrowLeftRegular,
  EmojiMehRegular,
  EmojiSadRegular,
} from "@fluentui/react-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "@fluentui/react-components";
import { AuthContext } from "../../auth/AuthProvider.tsx";

const useRetryLoginButton = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const shareId = searchParams.get("si");
  return (
    <>
      {shareId !== null && (
        <Button
          appearance={"primary"}
          icon={<ArrowLeftRegular />}
          onClick={() => {
            logout();
            navigate({ pathname: "/login", search: `si=${shareId}` });
          }}
        >
          Retry login
        </Button>
      )}
    </>
  );
};

export const NotFoundPage: React.FC<{ error?: Error }> = (props) => {
  return (
    <ErrorPage
      code={404}
      title={"Page not found"}
      message={"Sorry, we could not find the page you are looking for."}
      icon={<EmojiSadRegular />}
      error={props.error}
    />
  );
};

export const UnauthorizedPage: React.FC<{ error?: Error }> = (props) => {
  const retryLoginButton = useRetryLoginButton();
  return (
    <ErrorPage
      code={401}
      title={"Unauthorized"}
      message={
        "Sorry, it seems you don't have access to the shared inspections."
      }
      icon={<EmojiSadRegular />}
      action={retryLoginButton}
      error={props.error}
    />
  );
};

export const ForbiddenPage: React.FC<{ error?: Error }> = (props) => {
  const retryLoginButton = useRetryLoginButton();
  return (
    <ErrorPage
      code={403}
      title={"Forbidden"}
      message={
        "Sorry, it seems you don't have access to the shared inspections."
      }
      icon={<EmojiSadRegular />}
      action={retryLoginButton}
      error={props.error}
    />
  );
};

export const ExpiredPage: React.FC<{ error?: Error }> = (props) => {
  const retryLoginButton = useRetryLoginButton();
  return (
    <ErrorPage
      code={410}
      title={"Gone"}
      message={"The sharing link you have tried to access has expired."}
      icon={<EmojiMehRegular />}
      action={retryLoginButton}
      error={props.error}
    />
  );
};

export const SomethingWentWrongPage: React.FC<{ error?: Error }> = (props) => {
  console.log(props.error);
  const retryLoginButton = useRetryLoginButton();
  return (
    <ErrorPage
      title={"Something went wrong"}
      message={"An unexpected error occurred."}
      icon={<EmojiSadRegular />}
      action={retryLoginButton}
      hideContactText
      error={props.error}
    />
  );
};
