import { useContext, useEffect } from "react";
import * as THREE from "three";
import { SceneContext } from "../virtualScene.tsx";

import { useInScene } from "../../hooks/useInScene.ts";

export const FindingCone = (props: {
  idx: number;
  position: THREE.Vector3;
  orientation: THREE.Quaternion;
}) => {
  const scene = useContext(SceneContext);
  useInScene(
    true,
    true,
    () => {
      const cone = new THREE.Mesh(
        new THREE.ConeGeometry(0.15, 0.2, 4),
        new THREE.MeshLambertMaterial({
          color: 0xff0000,
          opacity: 0.7,
          transparent: true,
        }),
      );

      cone.position.copy(props.position);
      cone.quaternion.copy(props.orientation);

      // Rotate the cone such that the base points in the camera direction
      cone.rotateZ(Math.PI / 2);
      cone.rotateY(Math.PI / 4);
      return cone;
    },
    { type: "finding", idx: props.idx },
    [props.position, props.orientation],
  );
  useEffect(() => {
    scene.invalidate();
  });
  return <></>;
};
