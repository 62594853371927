import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";
import {
  ISession,
  IShareAsset,
  IShareInspection,
  IShareTarget,
} from "scout-sharing-models";
import { useSearchParams } from "react-router-dom";
import { ShareHierarchyContext } from "../ShareHierarchyProvider.tsx";

export type Resource = {
  asset?: IShareAsset;
  target?: IShareTarget;
  inspection?: IShareInspection;
  session?: ISession;
};

export const SelectedResourceContext = createContext<Resource>({
  asset: undefined,
  target: undefined,
  inspection: undefined,
  session: undefined,
});

/**
 * Responsible for parsing the resource ids from the url and providing the corresponding resources.
 */
const SelectedResourceProvider: React.FC<PropsWithChildren> = (props) => {
  const { state, hierarchy } = useContext(ShareHierarchyContext);
  const [selectedResource, setSelectedResource] = useState<Resource>({
    asset: undefined,
    target: undefined,
    inspection: undefined,
    session: undefined,
  });

  const [searchParams] = useSearchParams();
  const ri = useMemo(() => searchParams.get("ri"), [searchParams]);

  useEffect(() => {
    if (state !== "initialized") return;
    const resourceIds = ri?.split("/");
    const asset = hierarchy.assets.find((a) => a.id === resourceIds?.at(0));
    const target = asset?.targets.find((t) => t.id === resourceIds?.at(1));
    const inspection = target?.inspections.find(
      (i) => i.id === resourceIds?.at(2),
    );
    const session = inspection?.sessions.find(
      (s) => s.id === resourceIds?.at(3),
    );

    setSelectedResource({ asset, target, inspection, session });
  }, [ri, hierarchy, state]);

  return (
    <SelectedResourceContext.Provider value={selectedResource}>
      {props.children}
    </SelectedResourceContext.Provider>
  );
};

export default SelectedResourceProvider;
