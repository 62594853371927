import {
  MenuList,
  MenuGroup,
  MenuGroupHeader,
  MenuItemCheckbox,
  Menu,
  MenuPopover,
  MenuTrigger,
  Button,
  MenuProps,
} from "@fluentui/react-components";
import React, { useContext, useEffect, useState } from "react";
import { Filter24Regular } from "@fluentui/react-icons/lib/fonts";
import { ShareHierarchyContext } from "../../../ShareHierarchyProvider.tsx";
import useWindowDimensions from "../../../../../../hooks/useWindowDimensions.tsx";
import FindingsFilters, { FilterOption } from "./FindingsFilters.tsx";

type FilterMenuProps = {
  setSelectedFilters: (filter: FilterOption[]) => void;
};

const FilterMenu: React.FC<FilterMenuProps> = ({ setSelectedFilters }) => {
  const [checkedValues, setCheckedValues] = useState<Record<string, string[]>>(
    {},
  );

  const { state } = useContext(ShareHierarchyContext);
  const { width } = useWindowDimensions();

  const onChange: MenuProps["onCheckedValueChange"] = (
    _,
    { name, checkedItems },
  ) => {
    setCheckedValues((s) => {
      return s ? { ...s, [name]: checkedItems } : { [name]: checkedItems };
    });
  };

  useEffect(() => {
    const filters = Object.entries(checkedValues).flatMap(([groupKey, v]) => {
      return v.map((filterKey) => FindingsFilters[groupKey].filters[filterKey]);
    });
    setSelectedFilters(filters);
  }, [checkedValues, setSelectedFilters]);

  return (
    <Menu checkedValues={checkedValues} onCheckedValueChange={onChange}>
      <MenuTrigger disableButtonEnhancement>
        <Button
          appearance={"transparent"}
          icon={<Filter24Regular />}
          disabled={state !== "initialized"}
        >
          {width > 900 ? "Filter" : null}
        </Button>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {Object.entries(FindingsFilters).map(([groupKey, groupValues]) => {
            return (
              <MenuGroup key={groupKey}>
                <MenuGroupHeader>{groupValues.groupName}</MenuGroupHeader>
                {Object.entries(groupValues.filters).map(
                  ([filterKey, filter]) => {
                    return (
                      <MenuItemCheckbox
                        name={groupKey}
                        value={filterKey}
                        key={filter.name}
                      >
                        {filter.name}
                      </MenuItemCheckbox>
                    );
                  },
                )}
              </MenuGroup>
            );
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default FilterMenu;
