import { FC, MutableRefObject, useEffect, useRef, useState } from "react";
import "./videoplayer.scss";
import "videojs-hls-quality-selector";
import videojs, {
  VideoJsPlayer,
  VideoJsPlayerOptions,
  VideoJsPlayerPluginOptions,
} from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-quality-levels";
import "../plugins/frame-navigation/framebyframe.js";
import "../plugins/frame-navigation/framebyframe.css";
import { VideoJsSource } from "./videojs.ts";
import "video.js/dist/video-js.css";

interface IProps {
  videoElementRef: MutableRefObject<HTMLVideoElement | undefined>;
  onPlayerReady: (player: VideoJsPlayer) => void;
  src: string | VideoJsSource | VideoJsSource[];
}

export const VideoPlayer: FC<IProps> = (props: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [playerError, setPlayerError] = useState<boolean>(false);

  const { src, onPlayerReady } = props;

  useEffect(() => {
    const container = containerRef.current!;

    const videoJsContainerDiv = document.createElement("div");
    videoJsContainerDiv.setAttribute("data-vjs-player", "");
    videoJsContainerDiv.className = "player-container";
    container.appendChild(videoJsContainerDiv);

    const videoElement = document.createElement("video");
    videoElement.id = "video-element";
    videoElement.className = "video-js vjs-default-skin vjs-custom vjs-waiting";
    videoJsContainerDiv.appendChild(videoElement);

    props.videoElementRef.current = videoElement;

    const videojsPlugins: Partial<VideoJsPlayerPluginOptions> = {
      qualityLevels: true,
      hlsQualitySelector: {},
      framebyframe: {
        fps: 30,
        steps: [
          { text: "<<", step: -10 },
          { text: "<", step: -1 },
          { text: ">", step: 1 },
          { text: ">>", step: 10 },
        ],
      },
    };

    // Note to any poor developer trying to modify these in the future:
    // Adding children to the controlBar does not necessarily make them visible.
    // Adding a child adds an element to the DOM, but some of them have the CSS
    // property "display: none" by default. If thats the case, you need to
    // override it to "display: block".
    const videoConfig: VideoJsPlayerOptions = {
      autoplay: false,
      muted: true,
      controls: true,
      responsive: true,
      html5: {
        vhs: {
          // until bandwidth info is available, assume user has a 30mbps internet connection
          bandwidth: 30 * 1000 * 1000,
        },
      },
      controlBar: {
        children: {
          //@ts-expect-error - We manually set child options
          playToggle: true,
          progressControl: true,
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          fullscreenToggle: true,
        },
      },
      plugins: videojsPlugins,
    };
    // @ts-expect-error - We manually set GOAL_BUFFER_LENGTH
    videojs.Vhs.GOAL_BUFFER_LENGTH = 10;
    // @ts-expect-error - We manually set MAX_GOAL_BUFFER_LENGTH
    videojs.Vhs.MAX_GOAL_BUFFER_LENGTH = 20;
    const player: VideoJsPlayer = videojs(videoElement, videoConfig);
    if (!player) {
      setPlayerError(true);
      return;
    }
    player.tech({ IWillNotUseThisInPlugins: true }).manualTimeUpdatesOn();
    player.src(src);
    player.qualityLevels();
    player.hlsQualitySelector({ displayCurrentQuality: true });
    player.on(player.el(), "canplay", () => {
      onPlayerReady(player);
    });
    return () => {
      player.dispose();
    };
  }, [src, onPlayerReady, containerRef, setPlayerError, props.videoElementRef]);
  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {playerError && (
        <h3>
          Error loading video player, ensure you are on an uptodate browser.
        </h3>
      )}
    </div>
  );
};
