import {
  makeStyles,
  shorthands,
  Skeleton,
  SkeletonItem,
  tokens,
  ToolbarButtonProps,
  BreadcrumbDivider,
} from "@fluentui/react-components";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { ShareHierarchyContext } from "../../scenes/root/share/ShareHierarchyProvider";
import ResourceBreadcrumb, { Resource } from "./ResourceBreadcrumb";
import ActionToolbar from "./ActionToolbar.tsx";

const useStyle = makeStyles({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: tokens.colorNeutralBackground2,
    paddingLeft: tokens.spacingHorizontalL,
    ...shorthands.gap(tokens.spacingHorizontalXXL),
    height: tokens.lineHeightBase600,
  },
});

export type Action = ToolbarButtonProps & {
  label: string;
};

export const ActionBarContext = createContext<{
  setActions: (actions: Action[]) => void;
  setResourcePath: (resourcePath: Resource[]) => void;
}>({ setActions: () => {}, setResourcePath: () => {} });

const ActionBarProvider: React.FC<PropsWithChildren> = (props) => {
  const styles = useStyle();
  const [resourcePath, setResourcePath] = useState<Resource[]>([]);
  const [actions, setActions] = useState<Action[]>([]);
  const { state } = useContext(ShareHierarchyContext);

  const renderBreadCrumbsFallback = () => {
    return (
      <Skeleton
        appearance={"translucent"}
        style={{
          margin: tokens.spacingHorizontalS,
          display: "flex",
          gap: tokens.spacingHorizontalS,
          alignItems: "center",
        }}
      >
        <SkeletonItem
          style={{
            width: "50px",
            height: tokens.spacingHorizontalXXL,
          }}
        />
        <BreadcrumbDivider />
        <SkeletonItem
          style={{
            width: "50px",
            height: tokens.spacingHorizontalXXL,
          }}
        />
        <BreadcrumbDivider />
        <SkeletonItem
          style={{
            width: "50px",
            height: tokens.spacingHorizontalXXL,
          }}
        />
      </Skeleton>
    );
  };

  return (
    <>
      <div className={styles.toolbar}>
        {state === "initialized" ? (
          <>
            <div style={{ width: "60%" }}>
              <ResourceBreadcrumb resourcePath={resourcePath} />
            </div>
            <div style={{ width: "40%" }}>
              <ActionToolbar actions={actions} />
            </div>
          </>
        ) : (
          renderBreadCrumbsFallback()
        )}
      </div>
      <ActionBarContext.Provider value={{ setActions, setResourcePath }}>
        {/*<Sidebar open={sidebarOpen} />*/}
        {props.children}
      </ActionBarContext.Provider>
    </>
  );
};

export default ActionBarProvider;
