import React, { CSSProperties, useContext } from "react";
import { Vector } from "./vector.tsx";
import style from "./additional-data.module.scss";
import { ThemeContext } from "../../../../../styles/themes.tsx";

interface IProps {
  distanceUp?: number;
  distanceDown?: number;
  distanceFront?: number;
  style?: CSSProperties;
}

const DistancePane: React.FC<IProps> = (props: IProps) => {
  const { theme } = useContext(ThemeContext);
  return (
    <div
      style={{
        ...props.style,
        display: "flex",
        flexDirection: "row",
        padding: "20px",
        height: "100%",
      }}
    >
      <div
        style={{
          flex: 1,
        }}
      ></div>
      <div
        style={{
          height: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "center",
          }}
        >
          <Vector direction={"up"} value={props.distanceUp} />
        </div>
        <img
          className={style.scoutDrone}
          style={{
            maxWidth: "160px",
            position: "relative",
            left: "-20px", // Adjust for centering the arrows
          }}
          alt="Scout 137 Drone Silhouette"
          src={
            theme.dark
              ? "/scout137_2023_silhouette.svg"
              : "/scout137_2023_silhouette_black.svg"
          }
        />
        <div
          style={{
            flex: 1,
          }}
        >
          <Vector direction={"down"} value={props.distanceDown} />
        </div>
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        <Vector direction={"front"} value={props.distanceFront} />
      </div>
    </div>
  );
};

export default DistancePane;
