import { useEffect } from "react";

export const useKeyboardShortcut = (
  keys: string[],
  callback: (key: string) => void,
) => {
  useEffect(() => {
    const inputElements = new Set(["input", "textarea", "select"]);
    const handler = (event: KeyboardEvent) => {
      const target = event.target as HTMLElement;
      // check if the event was fired on an input element, if so, ignore it
      const tagName = target.tagName.toLowerCase();
      if (inputElements.has(tagName)) return;

      if (
        keys.includes(event.key.toLocaleLowerCase()) ||
        keys.includes(event.key.toUpperCase())
      ) {
        callback(event.key);
      }
    };
    const current = window;
    current?.addEventListener("keydown", handler);
    return () => {
      current?.removeEventListener("keydown", handler);
    };
  }, [keys, callback]);
};
