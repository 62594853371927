import { useEffect, useMemo, useRef, useState } from "react";

export const useWidthObserver = () => {
  const [width, setWidth] = useState(0);
  const containerDivRef = useRef<HTMLDivElement>(null);
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const entry = entries[0];
        if (entry) {
          setWidth(entry.contentRect.width);
        }
      }),
    [],
  );

  useEffect(() => {
    if (containerDivRef.current) {
      resizeObserver.observe(containerDivRef.current);
    }
    return () => resizeObserver.disconnect();
  }, [resizeObserver]);

  return { width, containerDivRef };
};
