import * as React from "react";
import { FolderRegular, DocumentFolderRegular } from "@fluentui/react-icons";
import {
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  TableColumnDefinition,
  createTableColumn,
  Card,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { ShareHierarchyContext } from "./ShareHierarchyProvider.tsx";
import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ActionBarContext } from "../../../components/ActionBarProvider/ActionBarProvider.tsx";
import { Resource } from "../../../components/ActionBarProvider/ResourceBreadcrumb.tsx";

const useStyles = makeStyles({
  pane: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingHorizontalL),
    ...shorthands.padding(tokens.spacingHorizontalXL),
  },

  paneHeader: {
    paddingLeft: "8px",
    paddingRight: "8px",
  },

  paneContent: {
    overflowY: "auto",
    ...shorthands.flex(1),
  },

  actions: {
    display: "flex",
    flexDirection: "row",
  },
});

type FileCell = {
  label: string;
  icon: JSX.Element;
};

type Item = {
  file: FileCell;
  type: string;
  onClick: () => void;
};

const columns: TableColumnDefinition<Item>[] = [
  createTableColumn<Item>({
    columnId: "file",
    compare: (a, b) => {
      return a.file.label.localeCompare(b.file.label);
    },
    renderHeaderCell: () => {
      return "File";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout media={item.file.icon}>
          {item.file.label}
        </TableCellLayout>
      );
    },
  }),
  createTableColumn<Item>({
    columnId: "type",
    compare: (a, b) => {
      return a.type.localeCompare(b.type);
    },
    renderHeaderCell: () => {
      return "Type";
    },

    renderCell: (item) => {
      return item.type;
    },
  }),
];

/**
 * Responsible for displaying the file hierarchy of the share.
 *
 * Uses the SelectedResourceContext to determine which files to display.
 * If nothing is selected, the assets from the ShareHierarchyContext are displayed.
 */
export const Browse = () => {
  const styles = useStyles();

  const { state, hierarchy } = useContext(ShareHierarchyContext);
  const { setResourcePath, setActions } = useContext(ActionBarContext);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const resourceIds = searchParams.get("ri")?.split("/");
  const asset = hierarchy?.assets.find((a) => a.id === resourceIds?.at(0));
  const target = asset?.targets.find((t) => t.id === resourceIds?.at(1));

  const addToRi = (id: string) => {
    const ri = searchParams.get("ri") ?? "";
    const newRi = ri.length > 0 ? `${ri}/${id}` : id;
    setSearchParams({ ri: newRi });
  };

  const getItems = () => {
    if (state !== "initialized") {
      return [];
    }

    if (asset === undefined) {
      return (
        hierarchy?.assets.map((asset) => {
          return {
            file: { label: asset.name, icon: <FolderRegular /> },
            type: "Folder",
            onClick: () => addToRi(asset.id),
          };
        }) ?? []
      );
    }

    if (target === undefined) {
      return (
        asset.targets.map((target) => {
          return {
            file: { label: target.name, icon: <FolderRegular /> },
            type: "Folder",
            onClick: () => addToRi(target.id),
          };
        }) ?? []
      );
    }

    return (
      target.inspections.map((inspection) => {
        return {
          file: { label: inspection.name, icon: <DocumentFolderRegular /> },
          type: "Inspection",
          onClick: () => navigate(`./inspections/${inspection.id}`),
        };
      }) ?? []
    );
  };

  useEffect(() => {
    if (state === "initialized") {
      const resourcePath: Resource[] = [];
      if (asset !== undefined) {
        resourcePath.push({
          type: "directory",
          name: asset.name,
          id: asset.id,
        });
      }
      if (target !== undefined) {
        resourcePath.push({
          type: "directory",
          name: target.name,
          id: target.id,
        });
      }
      setResourcePath(resourcePath);
    }
  }, [asset, setResourcePath, state, target]);

  useEffect(() => {
    setActions([]);
  }, [setActions]);

  return (
    <div className={styles.pane}>
      <Card>
        <DataGrid
          items={getItems()}
          columns={columns}
          sortable
          getRowId={(item) => item.file.label}
          focusMode="composite"
        >
          <DataGridHeader>
            <DataGridRow>
              {({ renderHeaderCell }) => (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>
          <DataGridBody<Item>>
            {({ item, rowId }) => (
              <DataGridRow<Item>
                key={rowId}
                onClick={item.onClick}
                style={{ cursor: "pointer" }}
              >
                {({ renderCell }) => (
                  <DataGridCell>{renderCell(item)}</DataGridCell>
                )}
              </DataGridRow>
            )}
          </DataGridBody>
        </DataGrid>
      </Card>
    </div>
  );
};

export default Browse;
