import { createContext, PropsWithChildren, useEffect } from "react";
import { SceneContextData } from "../models.ts";

import { useThreeJsScene } from "../hooks/useInThreeJsScene.ts";
import WebGL from "three/examples/jsm/capabilities/WebGL";
import { Vector3 } from "three";

export const SceneContext = createContext<SceneContextData>({
  containerRef: undefined,
  scene: undefined,
  camera: undefined,
  renderer: undefined,
  hoverables: new Set(),
  clickables: new Set(),
  events: new EventTarget(),
  invalidate: () => {},
});

interface VirtualSceneProps {
  readonly initialCameraSubjectPosition: Vector3;
  readonly onMouseClick?: (e: Event) => void;
  readonly onMouseEnter?: (e: Event) => void;
  readonly onMouseLeave?: (e: Event) => void;
}

const isWebGLAvailable = WebGL.isWebGL2Available() || WebGL.isWebGLAvailable();

export const VirtualScene = (props: PropsWithChildren<VirtualSceneProps>) => {
  const sceneContext = useThreeJsScene(props.initialCameraSubjectPosition);

  useEffect(() => {
    if (props.onMouseClick) {
      sceneContext.events.addEventListener("click", props.onMouseClick);
    }
    if (props.onMouseEnter) {
      sceneContext.events.addEventListener("mouseenter", props.onMouseEnter);
    }
    if (props.onMouseLeave) {
      sceneContext.events.addEventListener("mouseleave", props.onMouseLeave);
    }

    return () => {
      if (props.onMouseClick) {
        sceneContext.events.removeEventListener("click", props.onMouseClick);
      }
      if (props.onMouseEnter) {
        sceneContext.events.removeEventListener(
          "mouseenter",
          props.onMouseEnter,
        );
      }
      if (props.onMouseLeave) {
        sceneContext.events.removeEventListener(
          "mouseleave",
          props.onMouseLeave,
        );
      }
    };
  }, [
    sceneContext,
    props.onMouseEnter,
    props.onMouseLeave,
    props.onMouseClick,
  ]);

  return (
    <div
      ref={sceneContext.containerRef}
      style={{
        width: "100%",
        height: "100%",
        background: "black",
        overflow: "hidden",
      }}
    >
      {isWebGLAvailable ? (
        <SceneContext.Provider value={sceneContext}>
          {props.children}
        </SceneContext.Provider>
      ) : (
        <WebGLUnAvailableBanner />
      )}
    </div>
  );
};

const WebGLUnAvailableBanner = () => {
  // center contents
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
      }}
    >
      <div
        style={{
          maxWidth: "400px",
        }}
      >
        <h4>Your browser or your graphics card does not support WebGL</h4>
        <p style={{ color: "lightgrey" }}>
          Cannot render 3D view due to un-supported graphics card or
          un-supported browser. Please ensure that you are using a fairly modern
          graphics card with an up-to-date browser with WebGL support enabled.
        </p>
        <p>
          <a
            href={"https://updatemybrowser.org/"}
            target={"_blank"}
            rel="noreferrer"
          >
            Check if you have the latest browser
          </a>
        </p>
      </div>
    </div>
  );
};
