import { useContext, useEffect } from "react";
import { SceneContext } from "../virtualScene.tsx";
import { Quaternion, Vector3 } from "three";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

export const GltfModel = (props: {
  gltf: GLTF;
  position: Vector3;
  quaternion: Quaternion;
  scale?: Vector3;
}) => {
  const scene = useContext(SceneContext);

  useEffect(() => {
    const gltfSceneObject = props.gltf.scene;
    gltfSceneObject.position.copy(props.position);
    gltfSceneObject.quaternion.copy(props.quaternion);
    if (props.scale) {
      gltfSceneObject.scale.copy(props.scale);
    }
    scene.scene?.add(gltfSceneObject);
    scene.invalidate();
    return () => {
      scene.scene?.remove(gltfSceneObject);
    };
  }, [props.gltf, props.position, props.quaternion, props.scale, scene]);

  return <></>;
};
