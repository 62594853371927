import {
  MenuList,
  MenuItemRadio,
  MenuGroup,
  Menu,
  MenuPopover,
  MenuTrigger,
  MenuProps,
  MenuItem,
} from "@fluentui/react-components";
import React, { useContext } from "react";
import { DarkThemeRegular } from "@fluentui/react-icons";
import { ThemeContext, ThemeId } from "../../styles/themes.tsx";
const ThemeMenu: React.FC = () => {
  const { theme, themes, setThemeId } = useContext(ThemeContext);

  const [checkedValues, setCheckedValues] = React.useState<
    Record<string, string[]>
  >({ theme: [theme.id] });

  const onChange: MenuProps["onCheckedValueChange"] = (
    _,
    { name, checkedItems },
  ) => {
    setCheckedValues((s) => ({ ...s, [name]: checkedItems }));
    setThemeId(checkedItems[0] as ThemeId);
  };
  return (
    <Menu checkedValues={checkedValues} onCheckedValueChange={onChange}>
      <MenuTrigger disableButtonEnhancement>
        <MenuItem icon={<DarkThemeRegular />}>Theme</MenuItem>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuGroup>
            {Object.entries(themes).map(([id, t]) => (
              <MenuItemRadio key={id} name="theme" value={t.id}>
                {t.name}
              </MenuItemRadio>
            ))}
          </MenuGroup>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default ThemeMenu;
