import {
  Theme,
  makeStaticStyles,
  makeStyles,
  tokens,
} from "@fluentui/react-components";
import { useContext } from "react";
import { ALTERNATIVE_LIGHT_THEME_ID, ThemeContext } from "./themes";

export const useBaseStyles = makeStyles({
  outline: {
    outlineStyle: "solid",
    outlineWidth: tokens.strokeWidthThick,
    outlineOffset: tokens.strokeWidthThick,
    outlineColor: tokens.colorNeutralForeground3,
  },
  hoverOutline: {
    ":hover": {
      outlineStyle: "solid",
      outlineWidth: tokens.strokeWidthThick,
      outlineOffset: tokens.strokeWidthThick,
      outlineColor: tokens.colorBrandForeground1,
    },
  },
});

export const useStaticStyles = makeStaticStyles({
  "*": {
    margin: 0,
    padding: 0,
    boxSizing: "border-box",
  },
  "::-webkit-scrollbar": {
    width: tokens.spacingHorizontalXS,
    height: tokens.spacingHorizontalXS,
  },
  "::-webkit-scrollbar-track": {
    // boxShadow: "inset 0 0 1px grey",
    borderRadius: tokens.borderRadiusCircular,
    backgroundColor: tokens.colorNeutralBackground3,
  },

  "::-webkit-scrollbar-thumb": {
    borderRadius: tokens.borderRadiusCircular,
    background: tokens.colorNeutralForeground3,
  },
});

export const useAlternativeLightTheme = (): Theme => {
  const { theme, themes } = useContext(ThemeContext);
  return theme.dark
    ? theme.fluentTheme
    : themes[ALTERNATIVE_LIGHT_THEME_ID].fluentTheme;
};
