import React, { useEffect, useState } from "react";
import style from "./measurements.module.scss";
import MeasurementAScan from "./measurement-a-scan";
import { IPOIMeasurement } from "scout-sharing-models";
import { useWidthObserver } from "./hooks";
import {
  Body2,
  Button,
  InfoLabel,
  Link,
  tokens,
} from "@fluentui/react-components";

import MeasurementPositionTable from "./measurement-position-table";

export interface MeasurementComponentProps {
  measurement: IPOIMeasurement;
  isSelected: boolean;
  measurementIdx: number;
  position?: IPosition | undefined;
  selectMeasurement?: (idx: number) => void;
  height?: number;
}

export interface IPosition {
  distance_up?: number;
  distance_down?: number;
  distance_front?: number;
}

export const MeasurementComponent = (props: MeasurementComponentProps) => {
  const { width, containerDivRef } = useWidthObserver();
  const [selectionHighlighted, setSelectionHighlighted] =
    useState<boolean>(false);

  useEffect(() => {
    if (props.isSelected && !selectionHighlighted) {
      setSelectionHighlighted(true);
      containerDivRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (!props.isSelected) {
      // this item was deselected
      setSelectionHighlighted(false);
    }
  }, [props.isSelected, selectionHighlighted, containerDivRef]);

  const containerClasses = [style.measurement_container];

  if (props.isSelected) {
    containerClasses.push(style.measurement_selected);
  }

  return (
    <div
      className={containerClasses.join(" ")}
      style={{
        width: "100%",
        overflow: "hidden",
        padding: tokens.spacingHorizontalM,
        gap: tokens.spacingHorizontalM,
      }}
    >
      <div
        ref={containerDivRef}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          overflow: "hidden",
        }}
      >
        <Body2>
          Measurement {props.measurementIdx + 1}:
          <strong> {props.measurement.thickness_mm.toFixed(2)} mm</strong>
        </Body2>
        {props.measurement.a_scan_data !== null &&
        props.measurement.a_scan_sampling_rate !== null ? (
          <MeasurementAScan
            data={props.measurement.a_scan_data}
            samplingRate={props.measurement.a_scan_sampling_rate}
            speedOfSound={props.measurement.speed_of_sound}
            width={width}
            height={props.height ?? 300}
            xAxisTickCount={width / 50}
          />
        ) : (
          <div
            className={style.measurement_no_ascan}
            style={{
              backgroundColor: tokens.colorNeutralBackground2,
              height: props.height ?? 300,
            }}
          >
            <InfoLabel
              info={
                <p style={{ width: "200px" }}>
                  A-scan data is not available for this measurement. This may be
                  due to the age of the session or an issue during data capture.
                  For assistance, please{" "}
                  <Link href={"mailto:support@scoutdi.com"}>
                    contact our support team
                  </Link>
                  .
                </p>
              }
            >
              A-scan data is not available
            </InfoLabel>
          </div>
        )}
      </div>
      {props.position !== undefined && (
        <div
          style={{
            width: "150px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: tokens.spacingVerticalM,
          }}
        >
          <MeasurementPositionTable position={props.position} />
          {props.selectMeasurement !== undefined && (
            <Button
              onClick={() => props.selectMeasurement?.(props.measurementIdx)}
            >
              Show in 3D
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
