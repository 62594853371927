import React, { useContext, useEffect, useState } from "react";
import { ShareInfoContext } from "../../ShareInfoProvider";
import { ThemeContext } from "../../styles/themes";
import CompanyBrand from "./CompanyBrand";
import { tokens } from "@fluentui/react-components";

const useBrandLogo = (
  size: "small" | "medium" | "large",
  mode?: "dark" | "light",
) => {
  const shareInfo = useContext(ShareInfoContext);
  const { theme } = useContext(ThemeContext);

  const storedLogo = sessionStorage.getItem("brandLogo");

  const [logo, setLogo] = useState<string>(
    storedLogo ||
      (theme.dark || mode === "light" ? "/logo_white.svg" : "/logo_black.svg"),
  );
  const [showPoweredBy, setShowPoweredBy] = useState<boolean>(!!storedLogo);

  useEffect(() => {
    if (storedLogo) {
      return;
    }
    if (shareInfo?.group_logo) {
      setLogo(shareInfo.group_logo);
      setShowPoweredBy(true);
      sessionStorage.setItem("brandLogo", shareInfo.group_logo);
      return;
    }
  }, [shareInfo, storedLogo]);

  return (
    <CompanyBrand
      src={logo}
      alt={"Brand Logo"}
      showPoweredBy={showPoweredBy}
      logoSize={size}
      poweredByColor={tokens.colorNeutralForeground1}
    />
  );
};

export default useBrandLogo;
