import * as THREE from "three";
import { useContext, useEffect } from "react";
import { SceneContext } from "../components/virtualScene.tsx";
import { ScoutObject3DData } from "../models.ts";

export const useInScene = (
  hoverable: boolean,
  clickable: boolean,
  obj: THREE.Object3D | (() => THREE.Object3D),
  userData?: ScoutObject3DData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps?: any[],
) => {
  const scene = useContext(SceneContext);

  useEffect(() => {
    let object: THREE.Object3D;
    if (typeof obj === "function") {
      object = obj();
    } else {
      object = obj;
    }
    if (userData) {
      object.userData = userData;
    }
    scene.scene?.add(object);
    scene.invalidate();
    if (hoverable) {
      scene.hoverables.add(object);
    }
    if (clickable) {
      scene.clickables.add(object);
    }
    return () => {
      if (hoverable) {
        scene.hoverables.delete(object);
      }
      if (clickable) {
        scene.clickables.delete(object);
      }
      scene?.scene?.remove(object);
      object.userData = {};
      scene?.invalidate();
    };
    // It is considered an anti-pattern to include additional dependencies in the depencency array.
    // Some other mechanism should be used to re-register the callback when the dependencies change.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene, ...(deps ?? [])]);
};
