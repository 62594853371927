import { additionalDataInfo } from "../../../../../../../utils.ts";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { ILEDBrightness } from "scout-sharing-models";

const useStyles = makeStyles({
  lightsInfo: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    paddingTop: tokens.spacingVerticalM,
    paddingBottom: tokens.spacingVerticalM,
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
  },

  lightsPane: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap(tokens.spacingHorizontalL),
  },
});

const LightInformationPanel = (props: { lightData?: ILEDBrightness }) => {
  const styles = useStyles();
  const formatLight = additionalDataInfo.lights.formatValue;

  const brightness = (intensity: number | null | undefined) => {
    const min = 0.1;
    const max = 0.7;
    const i = intensity !== undefined && intensity !== null ? intensity : 0;
    const scaledIntensity = (i * (max - min)) / 100 + min;
    const brightness = Math.round(255 * scaledIntensity);
    return `rgb(${brightness}, ${brightness}, ${brightness})`;
  };

  return (
    <div className={styles.lightsInfo}>
      <div className={styles.lightsPane}>
        <div style={{ height: "100%" }}>
          <svg
            width="26"
            height="70"
            viewBox="0 0 26 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4C0 1.79087 1.79086 0 4 0H21.6098C23.8189 0 25.6098 1.79086 25.6098 4V18.7805H0V4Z"
              stroke={tokens.colorNeutralBackground3}
              fill={brightness(props.lightData?.left_top)}
            />
            <path
              d="M0 25.6097H25.6098V44.3902H0V25.6097Z"
              stroke={tokens.colorNeutralBackground3}
              fill={brightness(props.lightData?.left_middle)}
            />
            <path
              d="M0 51.2195H25.6098V66C25.6098 68.2091 23.8189 70 21.6098 70H4C1.79086 70 0 68.2091 0 66V51.2195Z"
              stroke={tokens.colorNeutralBackground3}
              fill={brightness(props.lightData?.left_bottom)}
            />
          </svg>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            textAlign: "right",
          }}
        >
          <h6>{formatLight(props.lightData?.left_top)}</h6>
          <h6>{formatLight(props.lightData?.left_middle)}</h6>
          <h6>{formatLight(props.lightData?.left_bottom)}</h6>
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            textAlign: "right",
          }}
        >
          <h6>{formatLight(props.lightData?.right_top)}</h6>
          <h6>{formatLight(props.lightData?.right_middle)}</h6>
          <h6>{formatLight(props.lightData?.right_bottom)}</h6>
        </div>
        <div>
          <svg
            width="26"
            height="70"
            viewBox="0 0 26 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4C0 1.79087 1.79086 0 4 0H21.6098C23.8189 0 25.6098 1.79086 25.6098 4V18.7805H0V4Z"
              stroke={tokens.colorNeutralBackground3}
              fill={brightness(props.lightData?.right_top)}
            />
            <path
              d="M0 25.6097H25.6098V44.3902H0V25.6097Z"
              stroke={tokens.colorNeutralBackground3}
              fill={brightness(props.lightData?.right_middle)}
            />
            <path
              d="M0 51.2195H25.6098V66C25.6098 68.2091 23.8189 70 21.6098 70H4C1.79086 70 0 68.2091 0 66V51.2195Z"
              stroke={tokens.colorNeutralBackground3}
              fill={brightness(props.lightData?.right_bottom)}
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default LightInformationPanel;
