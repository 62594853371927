import { createContext } from "react";
import { IShareHierarchy, IShareInfo } from "scout-sharing-models";
import { ISessionFlightData } from "scout-sharing-models";

export type JwtTokenResponse = {
  token: string;
};

export interface IApiClient {
  getShareInfo: (shareId: string) => Promise<IShareInfo>;
  getShareHierarchy: (shareId: string) => Promise<IShareHierarchy>;
  getFlightData: (
    shareId: string,
    sessionId: string,
  ) => Promise<ISessionFlightData>;
  requestTotp: (shareId: string, email: string) => Promise<void>;
  verifyTotp: (
    shareId: string,
    totp: string,
    email: string,
  ) => Promise<JwtTokenResponse>;
  addErrorHandler: (handler: (response: Response) => void) => void;
  removeErrorHandler: (handler: (response: Response) => void) => void;
}

const VERIFICATION_BASE_API_URL =
  import.meta.env.VITE_VERIFICATION_BASE_API_URL ||
  "http://localhost:8000/api/v1";

export const buildApiClient = (
  getToken: () => Promise<string | null>,
): IApiClient => {
  const buildHeaders = async () => {
    const token = await getToken();
    return {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Auth-Token": `Share ${token}`,
    };
  };

  const errorHandlers: ((response: Response) => void)[] = [];

  const callApi = async (url: string, body: unknown): Promise<Response> => {
    const headers = await buildHeaders();
    const response = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify(body),
    });
    if (!response.ok) {
      errorHandlers.forEach((handler) => handler(response));
      throw new Error(response.statusText);
    }
    return response;
  };

  return {
    getShareInfo: async (shareId: string) => {
      const response = await callApi(`/api/share-info/${shareId}/`, {});
      return (await response.json()) as IShareInfo;
    },
    getShareHierarchy: async (shareId: string) => {
      const response = await callApi(`/api/shares/${shareId}/hierarchy/`, {});
      // const response = await callApi(`${SHARE_URL}/${shareId}/hierarchy/`, {});
      return (await response.json()) as IShareHierarchy;
    },
    getFlightData: async (shareId: string, sessionId: string) => {
      const response = await callApi(
        `/api/shares/${shareId}/session/${sessionId}/flightData`,
        {},
      );
      return (await response.json()) as ISessionFlightData;
    },
    requestTotp: async (shareId, email) => {
      const response = await callApi(
        `${VERIFICATION_BASE_API_URL}/shares/${shareId}/send-totp/`,
        {
          email: email,
        },
      );
      return await response.json();
    },
    verifyTotp: async (shareId, totp, email) => {
      const response = await callApi(
        `${VERIFICATION_BASE_API_URL}/shares/${shareId}/get-jwt/`,
        {
          totp: totp,
          email: email,
        },
      );
      return (await response.json()) as JwtTokenResponse;
    },
    addErrorHandler: (handler) => {
      errorHandlers.push(handler);
    },
    removeErrorHandler: (handler) => {
      const index = errorHandlers.indexOf(handler);
      if (index >= 0) {
        errorHandlers.splice(index, 1);
      }
    },
  };
};

export const ApiContext = createContext(
  buildApiClient(() => Promise.resolve(null)),
);
