import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Popover,
  PopoverSurface,
  PositioningImperativeRef,
  Subtitle2,
  Toast,
  ToastBody,
  ToastFooter,
  ToastTitle,
  ToastTrigger,
  tokens,
  useToastController,
} from "@fluentui/react-components";
import {
  ChevronLeftRegular,
  ChevronRightRegular,
  DismissRegular,
} from "@fluentui/react-icons/lib/fonts";
import { SelectedResourceContext } from "../../scenes/root/share/inspections/SelectedResourceProvider.tsx";
import { ArrowFlowUpRightFilled } from "@fluentui/react-icons";

export type Step = {
  ref: React.RefObject<HTMLElement>;
  title: string;
  text: string;
  priority: number;
};

export const GuidedTourContext = createContext<{
  openTour: () => void;
  addStep: (id: string, step: Step) => void;
}>({
  openTour: () => {},
  addStep: () => {},
});

const GuidedTourProvider: React.FC<PropsWithChildren> = (props) => {
  const [steps, setSteps] = useState<{ [key: string]: Step }>({});
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(false);

  const positioningRef = useRef<PositioningImperativeRef>(null);

  const { dispatchToast } = useToastController();
  const selectedResource = useContext(SelectedResourceContext);

  const stepArray = Object.values(steps).sort((a, b) => {
    return a.priority - b.priority;
  });

  const addStep = useCallback(
    (id: string, step: Step) => {
      setSteps((prev) => {
        const newSteps = { ...prev };
        newSteps[id] = step;
        return newSteps;
      });
    },
    [setSteps],
  );

  const nextStep = () => {
    if (step < stepArray.length - 1) {
      setStep((prev) => prev + 1);
    }
  };

  const prevStep = () => {
    if (step > 0) {
      setStep((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("gt") === "true" || selectedResource === null) {
      return;
    }
    localStorage.setItem("gt", "true");
    dispatchToast(
      <Toast>
        <ToastTitle>Guided Tour</ToastTitle>
        <ToastBody>
          Welcome to the ScoutDI portal! <br /> Do you want to start a guided
          tour?
        </ToastBody>
        <ToastFooter>
          <ToastTrigger>
            <Button icon={<DismissRegular />} appearance={"secondary"}>
              Dismiss
            </Button>
          </ToastTrigger>
          <ToastTrigger>
            <Button
              icon={<ArrowFlowUpRightFilled />}
              onClick={() => setOpen(true)}
              appearance={"primary"}
            >
              Start tour
            </Button>
          </ToastTrigger>
        </ToastFooter>
      </Toast>,
      { timeout: -1, intent: "info" },
    );
  }, [dispatchToast, selectedResource]);

  useEffect(() => {
    if (open) {
      setStep(0);
    }
  }, [open]);

  useEffect(() => {
    const target = stepArray[step]?.ref?.current;
    if (target) {
      positioningRef.current?.setTarget(target);
    }
  }, [positioningRef, step, stepArray, steps]);

  return (
    <GuidedTourContext.Provider
      value={{
        openTour: () => setOpen(true),
        addStep: addStep,
      }}
    >
      {props.children}
      <div
        style={{
          height: "100vh",
          width: "100%",
          position: "absolute",
          backgroundColor: tokens.colorBackgroundOverlay,
          top: "0",
          left: "0",
          zIndex: 100,
          display: open ? "block" : "none",
        }}
      />
      <Popover positioning={{ positioningRef }} open={open} withArrow={true}>
        <PopoverSurface
          style={{
            maxWidth: "300px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Subtitle2>{stepArray[step]?.title}</Subtitle2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {step > 0 && (
                  <Button
                    appearance={"transparent"}
                    icon={<ChevronLeftRegular />}
                    onClick={prevStep}
                  />
                )}
                {step + 1} / {stepArray.length}
                {step < stepArray.length - 1 && (
                  <Button
                    appearance={"transparent"}
                    icon={<ChevronRightRegular />}
                    onClick={nextStep}
                  />
                )}
              </div>
              <Button
                appearance={"transparent"}
                icon={<DismissRegular />}
                onClick={() => setOpen(false)}
              />
            </div>
            {stepArray[step]?.text}
          </div>
        </PopoverSurface>
      </Popover>
    </GuidedTourContext.Provider>
  );
};

export default GuidedTourProvider;
