import {
  Card,
  CardHeader,
  makeStyles,
  shorthands,
  Subtitle1,
  tokens,
} from "@fluentui/react-components";
import React, { useMemo, useState } from "react";
import FilterMenu from "./FilterMenu.tsx";
import GroupingMenu from "./GroupingMenu.tsx";
import FindingsGallery, {
  FindingsGalleryFallback,
} from "./FindingsGallery.tsx";
import { filterAndGroupFindings } from "./utils.ts";
import GuidedTourStep from "../../../../../../components/GuidedTour/GuidedTourStep.tsx";
import { IFinding } from "scout-sharing-models";
import { useNavigate, useParams } from "react-router-dom";
import FindingsFilters, {
  FilterGroup,
  FilterOption,
} from "./FindingsFilters.tsx";

const useStyles = makeStyles({
  pane: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingHorizontalL),
  },

  paneHeader: {
    paddingLeft: tokens.spacingHorizontalM,
    paddingRight: tokens.spacingHorizontalM,
    height: tokens.spacingVerticalXXL,
  },

  paneContent: {
    ...shorthands.flex(1),
  },

  actions: {
    display: "flex",
    flexDirection: "row",
  },
});

export type FindingsPaneProps = {
  findings: IFinding[];
};

const FindingsPane: React.FC<FindingsPaneProps> = (props) => {
  const styles = useStyles();

  const [selectedFilters, setSelectedFilters] = useState<FilterOption[]>([]);
  const [selectedGrouping, setSelectedGrouping] = useState<FilterGroup>(
    FindingsFilters["flagged"],
  );

  const navigate = useNavigate();
  const { shareId } = useParams();

  const filteredAndGroupedFindings = useMemo(() => {
    return filterAndGroupFindings(
      props.findings,
      selectedFilters,
      selectedGrouping,
    );
  }, [props.findings, selectedFilters, selectedGrouping]);

  const handleViewInVideo = (finding: IFinding) => {
    navigate({
      pathname: `/share/${shareId}/session/${finding.session}`,
      search: `?poi=${finding.id}&atSeconds=${finding.at_seconds}`,
    });
  };

  return (
    <div className={styles.pane}>
      <CardHeader
        header={<Subtitle1>Points of interest</Subtitle1>}
        // description={<Subtitle2>22 POIs</Subtitle2>}
        className={styles.paneHeader}
        action={
          <div className={styles.actions}>
            <FilterMenu setSelectedFilters={setSelectedFilters} />
            <GroupingMenu setSelectedGrouping={setSelectedGrouping} />
          </div>
        }
      />
      <GuidedTourStep
        title={"POIs"}
        text={
          "These are the POIs captured for the selected inspection. You can filter and group them, and click on them to see their details."
        }
        priority={2}
      >
        <Card appearance={"filled-alternative"} className={styles.paneContent}>
          <div style={{ height: "100%" }}>
            <FindingsGallery
              groupedFindings={filteredAndGroupedFindings}
              handleViewInVideo={handleViewInVideo}
            />
          </div>
        </Card>
      </GuidedTourStep>
    </div>
  );
};

export const FindingsPaneFallback: React.FC = () => {
  const styles = useStyles();
  return (
    <div className={styles.pane}>
      <CardHeader
        header={<Subtitle1>Points of interest</Subtitle1>}
        // description={<Subtitle2>22 POIs</Subtitle2>}
        className={styles.paneHeader}
        action={
          <div className={styles.actions}>
            <FilterMenu setSelectedFilters={() => {}} />
            <GroupingMenu setSelectedGrouping={() => {}} />
          </div>
        }
      />
      <Card
        appearance={"filled-alternative"}
        title={"Points of interest"}
        className={styles.paneContent}
      >
        <div style={{ height: "100%" }}>
          <FindingsGalleryFallback />
        </div>
      </Card>
    </div>
  );
};

export default FindingsPane;
