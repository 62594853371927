import { Button } from "reactstrap";
import style from "./annotation-inputs.module.scss";

export interface ICustomInputWithTextProps {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  onDelete: () => void;
}

export const CustomInputWithText = ({
  value,
  onChange,
  onDelete,
  placeholder = "Annotation description",
}: ICustomInputWithTextProps) => {
  return (
    <div className={`${style.inputSection}`}>
      <input
        className={`${style.inputSection}_input`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <Button
        className={`btn btn-danger ${style.inputSection}_delete`}
        onClick={() => onDelete()}
      >
        <i className="fa fa-trash" />
      </Button>
    </div>
  );
};
