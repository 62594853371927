import videojs from "video.js";

let VjsButton = videojs.getComponent("Button");
let FBFButton = videojs.extend(VjsButton, {
  constructor: function (player, options) {
    VjsButton.call(this, player, options);
    this.player = player;
    this.frameTime = 1 / options.fps;
    this.step_size = options.value;
  },

  handleClick: function () {
    // Start by pausing the player
    this.player.pause();
    // Calculate movement distance
    var dist = this.frameTime * this.step_size;
    this.player.currentTime(this.player.currentTime() + dist);
  },
});

function framebyframe(options) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const player = this;

  player.ready(() => {
    options.steps.forEach(function (opt) {
      var b = player.controlBar.addChild(
        new FBFButton(player, {
          el: videojs.dom.createEl(
            "button",
            {
              className: "vjs-res-button vjs-control",
              style: "cursor: pointer",
              innerHTML:
                '<div class="vjs-control-content"><span class="vjs-fbf">' +
                opt.text +
                "</span></div>",
            },
            {
              role: "button",
            },
          ),
          value: opt.step,
          fps: options.fps,
        }),
        {},
        opt.index,
      );
      player.controlBar
        .el()
        .insertBefore(b.el(), player.controlBar.progressControl.el());
    });
  });
}

videojs.registerPlugin("framebyframe", framebyframe);
