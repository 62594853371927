import React from "react";
import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTrigger,
  DialogTitle,
  DialogContent,
  Button,
  Field,
  tokens,
} from "@fluentui/react-components";
import { IShareInspection } from "scout-sharing-models";
import InspectionDetailsTable from "./InspectionDetailsTable.tsx";
import { DismissRegular, EditRegular } from "@fluentui/react-icons";
import NonIdealState from "../../../../../../components/NonIdealState/NonIdealState.tsx";

type DetailsDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  inspection: IShareInspection;
};

const DetailsDialog: React.FC<DetailsDialogProps> = (
  props: DetailsDialogProps,
) => {
  return (
    <Dialog
      open={props.open}
      onOpenChange={(_, data) => {
        props.setOpen(data.open);
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle
            action={
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="subtle" icon={<DismissRegular />} />
              </DialogTrigger>
            }
          >
            Inspection Details
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              gap: tokens.spacingVerticalXXXL,
              paddingTop: tokens.spacingVerticalL,
            }}
          >
            <Field label="Description" size={"large"}>
              {props.inspection.description ? (
                props.inspection.description
              ) : (
                <NonIdealState
                  icon={<EditRegular />}
                  title={""}
                  description={"No description for this inspection"}
                  iconSize={"small"}
                />
              )}
              {/*<Textarea*/}
              {/*  value={props.inspection.description}*/}
              {/*  onChange={() => {}}*/}
              {/*  resize={"vertical"}*/}
              {/*  readOnly*/}
              {/*/>*/}
            </Field>
            <Field label="Content" size={"large"}>
              <InspectionDetailsTable inspection={props.inspection} />
            </Field>
          </DialogContent>
          {/*<DialogActions>*/}
          {/*  <DialogTrigger disableButtonEnhancement>*/}
          {/*    <Button appearance="primary">Close</Button>*/}
          {/*  </DialogTrigger>*/}
          {/*</DialogActions>*/}
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default DetailsDialog;
