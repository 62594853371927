import { ILocalizedPOIMeasurement } from "../../../../../interface";
import { MeasurementComponent } from "./measurement-component";

export const MeasurementPane = (props: {
  selectedMeasurementIdx: number | undefined;
  selectMeasurement: (idx: number) => void;
  localizedMeasurements: ILocalizedPOIMeasurement[];
}) => {
  return (
    <div
      style={{
        overflowY: "auto",
        height: "100%",
        width: "100%",
        padding: "8px",
      }}
    >
      <div
        style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
      ></div>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {props.localizedMeasurements.map((entry, idx) => {
          const additionalData = entry.droneState.state.additional_data;
          return (
            <MeasurementComponent
              isSelected={idx === props.selectedMeasurementIdx}
              measurementIdx={idx}
              measurement={entry.measurement}
              selectMeasurement={props.selectMeasurement}
              position={additionalData}
              key={`measurement-component-${idx}`}
            />
          );
        })}
      </div>
    </div>
  );
};
