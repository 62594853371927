import {
  Button,
  Caption1,
  DialogTrigger,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  shorthands,
  tokens,
  Toolbar,
  ToolbarButton,
  useRestoreFocusTarget,
} from "@fluentui/react-components";
import {
  ArrowLeftRegular,
  ArrowRightRegular,
  DismissRegular,
  FullScreenMaximizeRegular,
  MoreHorizontalRegular,
  VideoRegular,
} from "@fluentui/react-icons";
import { ArrowDownloadRegular } from "@fluentui/react-icons/lib/fonts";
import React, { useContext } from "react";
import useWindowDimensions from "../../../../../../../hooks/useWindowDimensions.tsx";
import { FindingsDialogControllerContext } from "../FindingsDialogControllerProvider.tsx";
import { downloadFile } from "../../../../../../../utils.ts";

const useStyles = makeStyles({
  row: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    ...shorthands.gap(tokens.spacingHorizontalL),
    justifyContent: "center",
  },
});

type TitleActionsProps = {
  handleFullscreen: () => void;
  handleViewInVideo: () => void;
};

const TitleActions: React.FC<TitleActionsProps> = (props) => {
  const styles = useStyles();
  const { width } = useWindowDimensions();
  const restoreFocusTargetAttribute = useRestoreFocusTarget();

  const {
    prevFinding,
    nextFinding,
    totalFindings,
    currentFindingIndex,
    currentFinding,
  } = useContext(FindingsDialogControllerContext);

  const handleDownload = () => {
    if (!currentFinding?.image) return;
    const filename = `POI_${currentFinding.id}.jpg`;
    downloadFile(currentFinding.image, filename);
  };

  const renderNavigation = () => {
    return (
      <>
        <Button
          icon={<ArrowLeftRegular />}
          appearance={"subtle"}
          onClick={prevFinding}
        />
        <Caption1>
          {currentFindingIndex + 1} / {totalFindings}
        </Caption1>
        <Button
          icon={<ArrowRightRegular />}
          appearance={"subtle"}
          onClick={nextFinding}
        />
      </>
    );
  };
  const renderToolbar = () => {
    return (
      <Toolbar>
        <ToolbarButton
          icon={<VideoRegular />}
          appearance={"subtle"}
          onClick={props.handleViewInVideo}
        >
          View in session
        </ToolbarButton>
        <ToolbarButton
          icon={<FullScreenMaximizeRegular />}
          appearance={"subtle"}
          onClick={props.handleFullscreen}
        >
          Fullscreen
        </ToolbarButton>
        <ToolbarButton
          icon={<ArrowDownloadRegular />}
          appearance={"subtle"}
          disabled={!currentFinding?.image}
          onClick={handleDownload}
        >
          Download
        </ToolbarButton>
      </Toolbar>
    );
  };

  const renderMenu = () => {
    return (
      <Popover mouseLeaveDelay={1}>
        <PopoverTrigger disableButtonEnhancement>
          <Button
            icon={<MoreHorizontalRegular />}
            appearance={"subtle"}
            {...restoreFocusTargetAttribute}
          />
        </PopoverTrigger>
        <PopoverSurface>
          <Menu open={true}>
            <MenuList>
              <MenuItem
                icon={<VideoRegular />}
                onClick={props.handleViewInVideo}
              >
                View in session
              </MenuItem>
              <MenuItem
                icon={<FullScreenMaximizeRegular />}
                onClick={props.handleFullscreen}
              >
                Fullscreen
              </MenuItem>
              <MenuItem
                icon={<ArrowDownloadRegular />}
                onClick={handleDownload}
                disabled={!currentFinding?.image}
              >
                Download
              </MenuItem>
            </MenuList>
          </Menu>
        </PopoverSurface>
      </Popover>
    );
  };

  return (
    <div className={styles.row} style={{ textAlign: "center" }}>
      {renderNavigation()}
      {width > 1024 ? renderToolbar() : renderMenu()}
      <DialogTrigger disableButtonEnhancement>
        <ToolbarButton
          icon={<DismissRegular />}
          appearance={"subtle"}
          {...restoreFocusTargetAttribute}
        >
          {width > 1024 ? "Close" : ""}
        </ToolbarButton>
      </DialogTrigger>
    </div>
  );
};

export default TitleActions;
