import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  makeStyles,
  shorthands,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { FindingsDialogControllerContext } from "../FindingsDialogControllerProvider.tsx";
import { PreviewImage } from "./PreviewImage.tsx";
import FindingImage from "./FindingImage/FindingImage.tsx";
import { IZoomableImageHandler } from "./FindingImage/zoomable-image/zoomable-image.tsx";

const useStyles = makeStyles({
  spinner: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  realsenseNavigationOverlay: {
    position: "absolute",
    bottom: 0,
    right: "50%",
    transform: "translateX(50%)",
    display: "flex",
    ...shorthands.gap("10px"),
    zIndex: 1000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    ...shorthands.padding("5px"),
    opacity: 0.5,
    "&:hover": {
      opacity: 1,
    },
  },
});

type FindingsDialogImageProps = {
  containerRef: React.RefObject<HTMLDivElement>;
};

export type ImageSize = {
  width: number;
  height: number;
  marginTop: number;
  marginLeft: number;
};

const FindingsDialogImage: React.FC<FindingsDialogImageProps> = (props) => {
  const styles = useStyles();

  const { loaded, currentFinding } = useContext(
    FindingsDialogControllerContext,
  );

  const [currentView, setCurrentView] = useState<"finding" | "realsenseImage">(
    "finding",
  );
  const [imageSize, setImageSize] = useState<ImageSize>({
    width: 0,
    height: 0,
    marginLeft: 0,
    marginTop: 0,
  });
  const [zoomResetHandler, setZoomResetHandler] =
    useState<IZoomableImageHandler | null>(null);

  const [isFullscreen, setIsFullscreen] = useState(false);

  const isPreview = useMemo(
    () => currentFinding?.image === null && currentFinding?.preview !== null,
    [currentFinding],
  );

  const sizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        const size = entries[0].contentRect;
        setImageSize(calculateSize(size));
      }),
    [],
  );

  const fullscreenEventHandler = useCallback(() => {
    zoomResetHandler?.resetTransform();
    setIsFullscreen(document.fullscreenElement !== null);
  }, [zoomResetHandler]);

  useEffect(() => {
    const ref = props.containerRef.current;
    if (ref) {
      const ns = calculateSize({
        width: ref.clientWidth,
        height: ref.clientHeight,
      });
      setImageSize(ns);
      sizeObserver.observe(ref);
      document.addEventListener("fullscreenchange", fullscreenEventHandler);
    }
    return () => {
      sizeObserver.unobserve(ref!);
      document.removeEventListener("fullscreenchange", fullscreenEventHandler);
    };
  }, [sizeObserver, props.containerRef, fullscreenEventHandler]);

  useEffect(() => {
    if (currentFinding?.realsense_image === null && currentView !== "finding") {
      setCurrentView("finding");
    }
  }, [currentFinding, currentView]);

  useEffect(() => {
    if (currentView === "realsenseImage") {
      zoomResetHandler?.resetTransform();
    }
  }, [currentView, zoomResetHandler]);

  const renderRealsenseNavigationOverlay = (marginBottom: number) => {
    if (!currentFinding?.image || !currentFinding?.realsense_image) return null;

    return (
      <div
        className={styles.realsenseNavigationOverlay}
        style={{ marginBottom: marginBottom }}
      >
        <PreviewImage
          selected={currentView === "finding"}
          src={currentFinding.image}
          alt={"POI preview"}
          onClick={() => setCurrentView("finding")}
        />
        <PreviewImage
          selected={currentView === "realsenseImage"}
          src={currentFinding.realsense_image}
          alt={"Realsense preview"}
          onClick={() => setCurrentView("realsenseImage")}
        />
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: tokens.colorNeutralBackground2,
      }}
      ref={props.containerRef}
      onContextMenu={
        // Prevent the context menu from showing up when the user right-clicks on the image.
        (e) => e.preventDefault()
      }
    >
      <Spinner
        size={"extra-large"}
        className={styles.spinner}
        style={{
          display: loaded ? "none" : "",
          zIndex: 500,
        }}
      />
      <div
        style={{
          zIndex: 1000,
          width: "100%",
          height: "100%",
          display: "flex",
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {renderRealsenseNavigationOverlay(10)}
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            marginTop: 2 * imageSize.marginTop,
            marginLeft: 2 * imageSize.marginLeft,
            visibility: currentView === "finding" ? "visible" : "hidden",
          }}
        >
          {currentFinding !== undefined && (
            <FindingImage
              finding={currentFinding}
              isFullscreen={isFullscreen}
              isPreview={isPreview}
              imageSize={imageSize}
              setZoomResetHandler={setZoomResetHandler}
            />
          )}
        </div>
        <div
          style={{
            position: "absolute",
            visibility: currentView === "realsenseImage" ? "visible" : "hidden",
          }}
        >
          {currentFinding?.realsense_image !== null && (
            <img
              alt={"Realsense"}
              src={currentFinding?.realsense_image}
              style={{
                width: imageSize.width,
                height: imageSize.height,
                objectFit: "contain",
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const calculateSize = (size: { width: number; height: number }) => {
  const width = Math.min(size.width, (size.height * 16) / 9);
  const height = (width * 9) / 16;
  const marginTop = (size.height - height) / 2;
  const marginLeft = (size.width - width) / 2;
  return { width, height, marginTop, marginLeft };
};

export default FindingsDialogImage;
