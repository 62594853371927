import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  BrandVariants,
  createDarkTheme,
  createLightTheme,
  Theme,
} from "@fluentui/react-components";
import { ShareInfoContext } from "../ShareInfoProvider";
import tinycolor from "tinycolor2";

const scoutBackgroundRamp: BrandVariants = {
  10: "#020206",
  20: "#101422",
  30: "#1F2431",
  40: "#2A303D",
  50: "#363C48",
  60: "#424954",
  70: "#4F5660",
  80: "#5C636D",
  90: "#6A7179",
  100: "#787F86",
  110: "#868D93",
  120: "#959BA1",
  130: "#A4A9AE",
  140: "#B3B8BC",
  150: "#C3C7CA",
  160: "#D3D6D8",
};

const scoutBackgroundOverrides = {
  colorNeutralBackground1: scoutBackgroundRamp[40],
  colorNeutralBackground2: scoutBackgroundRamp[30],
  colorNeutralBackground3: scoutBackgroundRamp[20],
  colorNeutralBackground1Hover: scoutBackgroundRamp[50],
  colorNeutralBackground1Pressed: scoutBackgroundRamp[20],
  colorSubtleBackgroundHover: scoutBackgroundRamp[50],
  colorSubtleBackgroundPressed: scoutBackgroundRamp[30],
};

type CustomTheme = {
  id: string;
  name: string;
  fluentTheme: Theme;
  dark: boolean;
};

const generateColorRamp = (primaryColor: string): BrandVariants => {
  const colorRamp: Partial<BrandVariants> = {};
  const sameInterval = [60, 120];
  const adjustmentStep = 5; // specify adjustment amount as a constant
  for (let i = 10; i <= 160; i += 10) {
    if (i >= sameInterval[0] && i <= sameInterval[1]) {
      colorRamp[i as keyof BrandVariants] = primaryColor;
    } else {
      const adjustmentAmount =
        i < sameInterval[0]
          ? (sameInterval[0] - i) / (adjustmentStep * 2) // use adjustmentStep
          : (i - sameInterval[1]) / (adjustmentStep * 2); // use adjustmentStep
      const adjustedColor = tinycolor(primaryColor);
      if (i < sameInterval[0]) {
        adjustedColor.darken(adjustmentAmount * adjustmentStep); // use adjustmentStep
      } else {
        adjustedColor.lighten(adjustmentAmount * adjustmentStep); // use adjustmentStep
      }
      colorRamp[i as keyof BrandVariants] = adjustedColor.toString();
    }
  }
  return colorRamp as BrandVariants;
};

const createTheme = (
  props: Omit<CustomTheme, "fluentTheme"> & {
    primaryColor: string;
    overrides?: Partial<Theme>;
  },
) => {
  const primaryRamp = generateColorRamp(props.primaryColor);
  const baseTheme = props.dark
    ? createDarkTheme(primaryRamp)
    : createLightTheme(primaryRamp);
  const fluentTheme = {
    ...baseTheme,
    ...props.overrides,
    colorBrandForeground1: primaryRamp[110],
    colorBrandForeground2: primaryRamp[120],
  };
  return {
    ...props,
    fluentTheme: fluentTheme,
  };
};

export type ThemeId = "scout" | "dark" | "light";

const isThemeId = (value: string): value is ThemeId => {
  return ["light", "dark", "scout"].includes(value);
};

export const ALTERNATIVE_LIGHT_THEME_ID: ThemeId = "scout";

const createThemes = (
  primaryColor: string,
): { [key in ThemeId]: CustomTheme } => {
  return {
    scout: createTheme({
      id: "scout",
      name: "ScoutDI",
      primaryColor: primaryColor,
      dark: true,
      overrides: scoutBackgroundOverrides,
    }),
    dark: createTheme({
      id: "dark",
      name: "Dark",
      primaryColor: primaryColor,
      dark: true,
    }),
    light: createTheme({
      id: "light",
      name: "Light",
      primaryColor: primaryColor,
      dark: false,
    }),
  };
};

const SCOUT_PRIMARY_COLOR = "#E83B35";

const scoutThemes = createThemes(SCOUT_PRIMARY_COLOR);

export const ThemeContext = createContext<{
  theme: CustomTheme;
  themes: { [key in ThemeId]: CustomTheme };
  setThemeId: (id: ThemeId) => void;
}>({
  theme: scoutThemes["scout"],
  themes: scoutThemes,
  setThemeId: () => {},
});

export const ThemeProvider = (props: PropsWithChildren) => {
  const sessionStoragePrimaryColor = sessionStorage.getItem("primaryColor");
  const localStorageId = localStorage.getItem("theme");

  const [themeId, setThemeId] = useState<ThemeId>(
    localStorageId && isThemeId(localStorageId) ? localStorageId : "scout",
  );
  const [themes, setThemes] = useState<{ [key in ThemeId]: CustomTheme }>(
    createThemes(sessionStoragePrimaryColor || SCOUT_PRIMARY_COLOR),
  );
  const [theme, setTheme] = useState<CustomTheme>(themes[themeId]);

  const shareInfo = useContext(ShareInfoContext);

  const setThemeIdAndSave = (id: ThemeId) => {
    setThemeId(id);
    localStorage.setItem("theme", id);
  };

  useEffect(() => {
    const newTheme = themes[themeId];
    setTheme(newTheme);
  }, [themeId, themes]);

  useEffect(() => {
    const localStorageId = localStorage.getItem("theme");
    if (localStorageId && isThemeId(localStorageId)) {
      setThemeId(localStorageId);
    }
  }, []);

  useEffect(() => {
    if (shareInfo && shareInfo.theme_color) {
      setThemes(createThemes(shareInfo.theme_color));
      sessionStorage.setItem("primaryColor", shareInfo.theme_color);
    }
  }, [shareInfo]);

  return (
    <ThemeContext.Provider
      value={{ theme, themes, setThemeId: setThemeIdAndSave }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};
