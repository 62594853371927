import { Navigate, useLocation } from "react-router-dom";
import React, { PropsWithChildren, useContext } from "react";
import { AuthContext } from "./AuthProvider.tsx";

type PrivateComponentProps = PropsWithChildren<{
  loginSearchParams?: string;
}>;

const PrivateComponent: React.FC<PrivateComponentProps> = (props) => {
  const location = useLocation();
  const { user } = useContext(AuthContext);
  return user !== null ? (
    <>{props.children}</>
  ) : (
    <Navigate
      to={{
        pathname: "/login",
        search: props.loginSearchParams,
      }}
      state={{ from: location }}
    />
  );
};

export default PrivateComponent;
