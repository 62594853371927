import React, { FC, useCallback, useEffect } from "react";
import {
  Button,
  Caption1,
  Card,
  CardHeader,
  CardPreview,
  Image,
  makeStyles,
  mergeClasses,
  Popover,
  PopoverProps,
  PopoverSurface,
  PositioningImperativeRef,
  shorthands,
  Skeleton,
  SkeletonItem,
  Text,
  tokens,
} from "@fluentui/react-components";
import { ISession } from "scout-sharing-models";
import { useBaseStyles } from "../../../../../../styles/styles.ts";
import { PlayFilled } from "@fluentui/react-icons";
import SessionCardMenu from "./SessionCardMenu.tsx";
import { SessionDetailsTable } from "./SessionDetailsTable.tsx";
import { getSessionDuration } from "../../../../../../utils.ts";

const useStyles = makeStyles({
  image: {
    aspectRatio: "16/9",
  },

  skeletonCard: {
    height: "auto",
    aspectRatio: "16/12",
  },

  smallRadius: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
  },

  cardPreview: {
    aspectRatio: "16/9",
    backgroundColor: tokens.colorNeutralBackground3,
    position: "relative",
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  playButton: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
  },
  playIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none",
    height: "50%",
    aspectRatio: "1/1",
    backgroundColor: tokens.colorNeutralBackground1,
    opacity: 0.8,
    ...shorthands.borderRadius(tokens.borderRadiusCircular),
  },
  playIcon: {
    height: "80%",
    width: "auto",
  },
});

type SessionCardProps = {
  session: ISession;
  onClick?: (sessionId: string) => void;
};

const SessionCard: FC<SessionCardProps> = (props: SessionCardProps) => {
  const styles = useStyles();
  const baseStyles = useBaseStyles();

  const cardRef = React.useRef<HTMLDivElement>(null);
  const positioningRef = React.useRef<PositioningImperativeRef>(null);

  const [detailsOpen, setDetailsOpen] = React.useState(false);
  const handleDetailsOpenChange: PopoverProps["onOpenChange"] = (_, data) =>
    setDetailsOpen(data.open || false);

  useEffect(() => {
    if (cardRef.current) {
      positioningRef.current?.setTarget(cardRef.current);
    }
  }, [cardRef, positioningRef]);

  const onShowDetails = useCallback(() => {
    setDetailsOpen(true);
  }, []);

  return (
    <Card
      className={mergeClasses(baseStyles.hoverOutline, styles.smallRadius)}
      ref={cardRef}
    >
      <Popover
        withArrow
        open={detailsOpen}
        onOpenChange={handleDetailsOpenChange}
        positioning={{ positioningRef, position: "below" }}
      >
        <PopoverSurface style={{ width: "300px" }}>
          <SessionDetailsTable session={props.session} />
        </PopoverSurface>
      </Popover>
      <CardPreview
        className={mergeClasses(styles.cardPreview, styles.smallRadius)}
      >
        <Image
          className={mergeClasses(styles.image, styles.smallRadius)}
          src={props.session.thumbnail || "/defaultThumbnail.png"}
          alt="Session thumbnail"
        />
        <Button
          appearance={"transparent"}
          className={styles.playButton}
          style={{ display: "flex" }}
          onClick={() => {
            props.onClick?.(props.session.id);
          }}
        >
          <div className={styles.playIconContainer}>
            <PlayFilled className={styles.playIcon} />
          </div>
        </Button>
      </CardPreview>

      <CardHeader
        style={{ maxWidth: "100%" }}
        header={
          <Text
            weight="semibold"
            truncate={true}
            // wrap={false}
            style={{ wordBreak: "break-word" }}
          >
            {props.session.title || "Unamed Session"}
          </Text>
        }
        description={
          <Caption1 className={styles.caption}>
            Duration: {getSessionDuration(props.session)}
          </Caption1>
        }
        action={<SessionCardMenu onShowDetails={onShowDetails} />}
      />
    </Card>
  );
};

export const SessionCardFallback: React.FC = () => {
  const styles = useStyles();
  // const baseStyles = useBaseStyles();
  return (
    <Skeleton appearance={"translucent"}>
      <SkeletonItem
        className={mergeClasses(styles.skeletonCard, styles.smallRadius)}
      ></SkeletonItem>
    </Skeleton>
  );
};

export default SessionCard;
