import {
  Link,
  makeStyles,
  shorthands,
  Subtitle2,
  Text,
  Title1,
  tokens,
} from "@fluentui/react-components";
import React, { cloneElement } from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

type ErrorPageProps = {
  title?: string;
  code?: number;
  message: string;
  icon: React.ReactElement;
  action?: React.ReactElement;
  hideContactText?: boolean;
  error?: Error;
};

const useStyles = makeStyles({
  page: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingVerticalXXL),
  },
  card: {
    backgroundColor: tokens.colorNeutralBackground2,
  },
});

const ErrorPage: React.FC<ErrorPageProps> = (props) => {
  const styles = useStyles();

  const error = useRouteError() || props.error;
  let errorMessage: string;
  if (isRouteErrorResponse(error)) {
    errorMessage = error.data?.message || error.statusText;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === "string") {
    errorMessage = error;
  } else {
    errorMessage = "Unknown error";
  }

  return (
    <div className={styles.page}>
      {cloneElement(props.icon, { style: { width: "75px", height: "auto" } })}
      <Title1>{props.code}</Title1>
      <Subtitle2 style={{ color: tokens.colorNeutralForeground3 }}>
        {props.title}
      </Subtitle2>
      <Text>{props.message}</Text>
      {!props.hideContactText && (
        <Text>
          If you think we've made a mistake, please{" "}
          <Link href={"mailto:support@scoutdi.com"}>contact support</Link>.
        </Text>
      )}
      {import.meta.env.DEV && <Text>{errorMessage}</Text>}
      {props.action}
    </div>
  );
};

export default ErrorPage;
