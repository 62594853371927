import DistancePane from "./distance-pane";
import CameraPane from "./camera-pane";
import LightsPane from "./lights-pane";
import React from "react";
import { IDroneState } from "../../../../../interface.ts";
import {
  getCameraPitchInRadians,
  radiansToDegrees,
} from "../../../../../utils.ts";
import { Divider, Spinner } from "@fluentui/react-components";

interface IProps {
  droneState: IDroneState | undefined;
}

const AdditionalData: React.FC<IProps> = (props: IProps) => {
  const droneState = props.droneState?.state;
  const additionalData = droneState?.additional_data;
  let cameraPitchDegrees: number | undefined = undefined;
  if (droneState !== undefined) {
    const cameraPitch = getCameraPitchInRadians(droneState);
    if (cameraPitch !== undefined) {
      cameraPitchDegrees = Math.floor(-radiansToDegrees(cameraPitch));
    }
  }

  return (
    <>
      {props.droneState ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "100%",
          }}
        >
          <DistancePane
            distanceUp={additionalData?.distance_up}
            distanceDown={additionalData?.distance_down}
            distanceFront={additionalData?.distance_front}
            style={{
              flex: 1,
            }}
          />
          <Divider vertical style={{ height: "100%", flex: 0 }} />
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <CameraPane cameraPitch={cameraPitchDegrees} />
            <Divider />
            <LightsPane ledBrightness={additionalData?.lights} />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default AdditionalData;
