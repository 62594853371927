import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { IFinding } from "scout-sharing-models";

export const FindingsDialogControllerContext = createContext<{
  prevFinding: () => void;
  nextFinding: () => void;
  currentFindingIndex: number;
  totalFindings: number;
  loaded: boolean;
  currentFinding?: IFinding;
}>({
  prevFinding: () => {},
  nextFinding: () => {},
  currentFindingIndex: 0,
  totalFindings: 0,
  loaded: false,
  currentFinding: undefined,
});

type FindingsControllerProviderProps = {
  findings: IFinding[];
};

const FindingsDialogControllerProvider: React.FC<
  PropsWithChildren<FindingsControllerProviderProps>
> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loaded, setLoaded] = useState(false);

  const currentFindingIndex = useMemo(() => {
    const currentId = searchParams.get("poi");
    return props.findings.findIndex((f) => f.id.toString() === currentId);
  }, [searchParams, props.findings]);

  const currentFinding = useMemo(() => {
    return props.findings.at(currentFindingIndex);
  }, [currentFindingIndex, props.findings]);

  const navigateFinding = useCallback(
    (left: boolean) => {
      setLoaded(false);
      let newFindingIndex = currentFindingIndex;
      if (left) {
        newFindingIndex = newFindingIndex - 1;
        if (newFindingIndex < 0) {
          newFindingIndex = props.findings.length - 1;
        }
      } else {
        newFindingIndex = newFindingIndex + 1;
        if (newFindingIndex > props.findings.length - 1) {
          newFindingIndex = 0;
        }
      }
      const newFindingId = props.findings.at(newFindingIndex)!.id;
      setSearchParams((prev) => {
        prev.set("poi", newFindingId.toString());
        return prev;
      });
    },
    [currentFindingIndex, props.findings, setSearchParams],
  );

  const nextFinding = useCallback(() => {
    navigateFinding(false);
  }, [navigateFinding]);

  const prevFinding = useCallback(() => {
    navigateFinding(true);
  }, [navigateFinding]);

  return (
    <FindingsDialogControllerContext.Provider
      value={{
        prevFinding: prevFinding,
        nextFinding: nextFinding,
        currentFinding: currentFinding,
        currentFindingIndex: currentFindingIndex,
        loaded: loaded,
        totalFindings: props.findings.length,
      }}
    >
      {props.children}
    </FindingsDialogControllerContext.Provider>
  );
};

export default FindingsDialogControllerProvider;
