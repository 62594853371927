import React, { useEffect, useState } from "react";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch";
import style from "./zoomable-image.module.scss";

interface IProps {
  imageSource: string;
  className: string;
  setZoomableImageHandler: React.Dispatch<
    React.SetStateAction<IZoomableImageHandler | null>
  >;
}

interface IZoomableImageHandler {
  resetTransform: () => void;
}

const ZoomableImage = (props: IProps) => {
  const wrapperRef = React.useRef<ReactZoomPanPinchRef>(null);

  // Super hack to get the wrapper ref to be set on first render. Please fix if you know how.
  // If using dependency array in useEffect, it will be set to null after first render and parent
  // components  cant use the ref to e.g. reset transformation (zoom)
  const [handlerIsSet, setHandlerIsSet] = useState<boolean>(false);
  const { setZoomableImageHandler } = props;
  useEffect(() => {
    if (wrapperRef.current && !handlerIsSet) {
      setZoomableImageHandler(wrapperRef.current as IZoomableImageHandler);
      setHandlerIsSet(true);
    }
  }, [wrapperRef, handlerIsSet, setZoomableImageHandler]);

  return (
    <TransformWrapper ref={wrapperRef}>
      <TransformComponent
        wrapperClass={style.theWrapper}
        contentClass={style.theZoomContent}
      >
        <img src={props.imageSource} alt="test" className={style.theImage} />
      </TransformComponent>
    </TransformWrapper>
  );
};

export default ZoomableImage;
export type { IZoomableImageHandler };
