import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory();

export const reactPlugin = new ReactPlugin();

export const loadApplicationInsights = () => {
  if (import.meta.env.DEV) {
    return;
  }
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: import.meta.env.VITE_APPLICATION_INSIGHTS_CONN_STRING,
      // *** If you're adding the Click Analytics plug-in, delete the next line. ***
      extensions: [reactPlugin],
      // *** Add the Click Analytics plug-in. ***
      // extensions: [reactPlugin, clickPluginInstance],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        // *** Add the Click Analytics plug-in. ***
        // [clickPluginInstance.identifier]: clickPluginConfig
      },
    },
  });

  appInsights.loadAppInsights();
};
