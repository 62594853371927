import React from "react";
import { Label, makeStyles, Subtitle2 } from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

type NonIdealStateProps = {
  icon: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  iconSize?: "small" | "medium" | "large";
};

const iconSizes = {
  small: "1.5rem",
  medium: "3rem",
  large: "4.5rem",
};

const NonIdealState: React.FC<NonIdealStateProps> = (props) => {
  const styles = useStyles();
  const iconFontSize = iconSizes[props.iconSize ?? "large"];
  return (
    <div className={styles.root}>
      <div style={{ fontSize: iconFontSize }}>{props.icon}</div>
      <Subtitle2>{props.title}</Subtitle2>
      <Label>{props.description}</Label>
    </div>
  );
};

export default NonIdealState;
