import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Card,
  CardHeader,
  makeStyles,
  shorthands,
  Subtitle1,
  tokens,
} from "@fluentui/react-components";
import React from "react";
import SessionCard, {
  SessionCardFallback,
} from "../SessionCard/SessionCard.tsx";
import GuidedTourStep from "../../../../../../components/GuidedTour/GuidedTourStep.tsx";
import { ISession } from "scout-sharing-models";
import NonIdealState from "../../../../../../components/NonIdealState/NonIdealState.tsx";
import { FolderOpenRegular } from "@fluentui/react-icons";
import { SessionStatus } from "../../../../../../interface.ts";

const useStyles = makeStyles({
  pane: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingHorizontalL),
  },

  paneHeader: {
    paddingLeft: tokens.spacingHorizontalM,
    paddingRight: tokens.spacingHorizontalM,
    height: tokens.spacingVerticalXXL,
  },

  card: {
    containerType: "inline-size",
    ...shorthands.flex(1),
  },

  paneContent: {
    overflowY: "auto",
    height: "100%",
  },

  actions: {
    ...shorthands.gap(tokens.spacingHorizontalM),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  grid: {
    ...shorthands.margin(tokens.spacingHorizontalM),
    ...shorthands.gap(tokens.spacingHorizontalM),
    display: "grid",
    gridAutoRows: "max-content",
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    "@container (min-width: 480px)": {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    },
    "@container (min-width: 1200px)": {
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    },
  },

  search: {
    ...shorthands.borderRadius(tokens.borderRadiusCircular),
  },
});

interface ISessionsPaneProps {
  onSessionClicked: (sessionId: string) => void;
  sessions: ISession[];
}
export const SessionsPane: React.FC<ISessionsPaneProps> = (
  props: ISessionsPaneProps,
) => {
  const styles = useStyles();

  const sessionGroups = [
    {
      sessions: props.sessions.filter(
        (session) => session.status === SessionStatus.COMPLETED,
      ),
      header: "Uploaded",
    },
    {
      sessions: props.sessions.filter(
        (session) => session.status === SessionStatus.LIVE_COMPLETED,
      ),
      header: "Recorded Livestreams",
    },
  ];

  const renderSessions = () => {
    return (
      <Accordion
        collapsible
        multiple
        defaultOpenItems={sessionGroups.map((group) => group.header)}
      >
        {sessionGroups.map((group) => (
          <AccordionItem value={group.header} key={group.header}>
            <AccordionHeader>{group.header}</AccordionHeader>
            <AccordionPanel>
              <div className={styles.grid}>
                {group.sessions.map((session) => {
                  return (
                    <SessionCard
                      session={session}
                      key={session.id}
                      onClick={() => props.onSessionClicked(session.id)}
                    />
                  );
                })}
              </div>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    );
  };

  return (
    <div className={styles.pane}>
      <CardHeader
        header={<Subtitle1>Sessions</Subtitle1>}
        className={styles.paneHeader}
        // action={<SearchBox className={styles.search} />}
      />
      <GuidedTourStep
        title={"Sessions"}
        text={
          "These are the flight sessions of the selected inspection. Click on them to view the recorded video and associated 3D data."
        }
        priority={3}
      >
        <Card appearance={"filled-alternative"} className={styles.card}>
          <div className={styles.paneContent}>
            {props.sessions.length > 0 ? (
              renderSessions()
            ) : (
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <NonIdealState
                  icon={<FolderOpenRegular />}
                  title={"No sessions"}
                  description={"There are no sessions in this inspection"}
                />
              </div>
            )}
          </div>
        </Card>
      </GuidedTourStep>
    </div>
  );
};

export const SessionsPaneFallback: React.FC = () => {
  const styles = useStyles();
  return (
    <div className={styles.pane}>
      <CardHeader
        header={<Subtitle1>Sessions</Subtitle1>}
        className={styles.paneHeader}
        // action={<SearchBox className={styles.search} />}
      />
      <Card appearance={"filled-alternative"} className={styles.paneContent}>
        <div className={styles.grid}>
          {Array(5)
            .fill(0)
            .map((_, i) => {
              return <SessionCardFallback key={i} />;
            })}
        </div>
      </Card>
    </div>
  );
};
