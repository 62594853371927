import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IShareInspection } from "scout-sharing-models";
import FindingsPane, {
  FindingsPaneFallback,
} from "./components/FindingsPane/FindingsPane";
import {
  SessionsPane,
  SessionsPaneFallback,
} from "./components/SessionsPane/SessionsPane.tsx";
import { ActionBarContext } from "../../../../components/ActionBarProvider/ActionBarProvider.tsx";
import { ShareHierarchyContext } from "../ShareHierarchyProvider.tsx";
import { DocumentRegular, EditRegular } from "@fluentui/react-icons";
import DetailsDialog from "./components/DetailsDialog/DetailsDialog.tsx";
import { ReportDialog } from "./components/ReportDialog/ReportDialog.tsx";

const useStyles = makeStyles({
  content: {
    ...shorthands.flex(1),
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  doublePane: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    ...shorthands.gap(tokens.spacingHorizontalXL),
    ...shorthands.padding(tokens.spacingHorizontalXXXL),
    paddingBottom: 0,
    "@media screen and (max-width: 1024px)": {
      flexDirection: "column-reverse",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: tokens.spacingVerticalXL,
    },
  },
});

type InspectionContentProps = {
  inspection: IShareInspection;
};

const InspectionScene: React.FC<InspectionContentProps> = (props) => {
  const styles = useStyles();

  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);

  const { getResourcePath } = useContext(ShareHierarchyContext);
  const { setResourcePath, setActions } = useContext(ActionBarContext);

  const { shareId } = useParams();
  const navigate = useNavigate();

  const sessions = props.inspection?.sessions ?? [];
  const findings = sessions.flatMap((s) => s.findings ?? []) ?? [];

  useEffect(() => {
    setResourcePath(
      getResourcePath({
        type: "inspection",
        id: props.inspection.id,
        name: props.inspection.name,
      }),
    );
    return () => {
      setResourcePath([]);
    };
  }, [props.inspection, getResourcePath, setResourcePath]);

  useEffect(() => {
    setActions([
      {
        icon: <EditRegular />,
        label: "Details",
        onClick: () => setDetailsDialogOpen(true),
        disabled: false,
      },
      {
        icon: <DocumentRegular />,
        label: "Reports",
        onClick: () => setReportDialogOpen(true),
        disabled: false,
      },
    ]);
    return () => {
      setActions([]);
    };
  }, [setActions]);

  return (
    // <GuidedTourProvider>
    <div className={styles.content}>
      <DetailsDialog
        open={detailsDialogOpen}
        setOpen={setDetailsDialogOpen}
        inspection={props.inspection}
      />
      <ReportDialog
        open={reportDialogOpen}
        setOpen={setReportDialogOpen}
        reports={props.inspection.reports}
      />
      <div className={styles.doublePane}>
        <FindingsPane findings={findings} />
        <SessionsPane
          onSessionClicked={(sessionId: string) => {
            navigate(`/share/${shareId}/session/${sessionId}`);
          }}
          sessions={sessions}
        />
      </div>
    </div>
    // </GuidedTourProvider>
  );
};

export const InspectionSceneFallback: React.FC = () => {
  const styles = useStyles();
  return (
    <div className={styles.content}>
      <div className={styles.doublePane}>
        <FindingsPaneFallback />
        <SessionsPaneFallback />
      </div>
    </div>
  );
};

export default InspectionScene;
