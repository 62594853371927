import moment from "moment";
import * as THREE from "three";
import { DronePathEntry, IPCD } from "../models.ts";
import { IDroneState, ITimeDepTransform } from "../../../../../../interface.ts";

interface IPointCloudModel {
  id: string;
  session: string;
  timestamp: string;
  file: string;
}

export const relativeTimePointClouds = (
  sessionStart: moment.Moment,
  pointClouds: IPointCloudModel[],
): IPCD[] => {
  let timestamp;
  let parsedDate;
  return pointClouds.map((pointCloud) => {
    timestamp = pointCloud.timestamp;
    parsedDate = moment.utc(timestamp, true);
    return {
      file: pointCloud.file,
      opacity: 1.0,
      time: parsedDate.diff(sessionStart, "seconds", true),
      points: undefined,
    };
  });
};

export const transformPose: (
  position: THREE.Vector3,
  orientation: THREE.Quaternion,
  transform: ITimeDepTransform,
) => {
  position: THREE.Vector3;
  orientation: THREE.Quaternion;
} = (() => {
  // Temporary variables to avoid creating new objects (and garbage collection) during animation
  const vecScale = new THREE.Vector3(1, 1, 1);
  const matTransform = new THREE.Matrix4();
  const matPose = new THREE.Matrix4();

  return (
    position: { x: number; y: number; z: number },
    orientation: {
      x: number;
      y: number;
      z: number;
      w: number;
    },
    transform: ITimeDepTransform,
  ) => {
    const tempVecPosition = new THREE.Vector3();
    const tempQuartOrientation = new THREE.Quaternion();
    tempVecPosition.set(position.x, position.y, position.z);
    tempQuartOrientation.set(
      orientation.x,
      orientation.y,
      orientation.z,
      orientation.w,
    );
    matPose.compose(tempVecPosition, tempQuartOrientation, vecScale);

    tempVecPosition.set(
      transform.position_transform.x,
      transform.position_transform.y,
      transform.position_transform.z,
    );
    tempQuartOrientation.set(
      transform.orientation_transform.x,
      transform.orientation_transform.y,
      transform.orientation_transform.z,
      transform.orientation_transform.w,
    );
    matTransform.compose(tempVecPosition, tempQuartOrientation, vecScale);

    matTransform.multiply(matPose);

    tempVecPosition.setFromMatrixPosition(matTransform);
    tempQuartOrientation.setFromRotationMatrix(matTransform);
    return {
      position: tempVecPosition,
      orientation: tempQuartOrientation,
    };
  };
})();

/**
 * Utility function used to a datum from an array of time series data sorted by time.
 *
 * N.B. This function assumes that the array is sorted by time.
 *
 * @param entries <b>sorted</b> array of time series data
 * @param atTime time to get the entry for
 * @param getTime function to get the time of a given entry in the array
 *
 * @returns the first item in the [entries] array that has a time greater than or equal to [atTime]
 */
export const getEntryAtTime = <T>(
  entries: T[],
  atTime: number,
  getTime: (e: T) => number,
) => {
  const duration = getTime(entries[entries.length - 1]) - getTime(entries[0]);
  const secondsPerEntry = duration / entries.length;
  // Get estimated index of the item by dividing the time by the average time per entry.
  // Since our data (positions, pcds) are not perfect, they might have gaps or irregular intervals between
  // data points.
  // So we try to move 10 index positions back and start searching from their.
  let idx = Math.max(0, Math.floor(atTime / secondsPerEntry) - 10);
  while (idx < entries.length - 1 && getTime(entries[idx + 1]) < atTime) {
    idx++;
  }
  let otherIdx = idx;
  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    const time = getTime(entry);
    if (time >= atTime) {
      otherIdx = i;
      break;
    }
  }

  if (otherIdx >= entries.length) {
    return entries[entries.length - 1];
  }

  return entries[otherIdx];
};
export const getDronePose = (
  time: number,
  poses: IDroneState[],
  transforms?: ITimeDepTransform[],
): IDroneState => {
  // poseRef is a reference to the pose at the given time, so don't mutate it
  let poseRef = getEntryAtTime(poses, time, (e) => e.relativeTime);

  if (transforms !== undefined) {
    const transformAtTime = getEntryAtTime(
      transforms,
      time,
      (e) => e.relativeTime,
    );
    const { position, orientation } = transformPose(
      poseRef.state.position,
      poseRef.state.orientation,
      transformAtTime,
    );
    poseRef = {
      ...poseRef,
      state: {
        ...poseRef.state,
        // override the position and orientation with the transformed ones
        position,
        orientation,
      },
    };
  }

  return poseRef;
};

export function buildDronePathVectors(
  sanitisedPositions: IDroneState[],
  transforms?: ITimeDepTransform[],
): DronePathEntry[] {
  if (sanitisedPositions.length === 0) {
    return [];
  }

  const zeroOrientationTransform = new THREE.Quaternion(0, 0, 0, 1);

  let startVector = sanitisedPositions[0].state.position;
  const pathEntries: DronePathEntry[] = [];
  let trFn: (p: IDroneState, idx: number) => DronePathEntry;
  if (transforms) {
    trFn = (p) => {
      const transform = getEntryAtTime(
        transforms,
        p.relativeTime,
        (t) => t.relativeTime,
      );
      const { position } = transformPose(
        p.state.position,
        zeroOrientationTransform,
        transform,
      );
      return {
        time: p.relativeTime,
        position,
      };
    };
  } else {
    trFn = (p) => {
      return {
        time: p.relativeTime,
        position: p.state.position,
      };
    };
  }
  // add points which are at least 0.1m apart
  sanitisedPositions.forEach((p, idx) => {
    const v = p.state.position;
    if (v.distanceTo(startVector) > 0.1) {
      pathEntries.push(trFn(p, idx));
      startVector = v;
    }
  });
  return pathEntries;
}
