import React from "react";
import {
  ButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  OverflowDivider,
  useIsOverflowItemVisible,
  useOverflowMenu,
  BreadcrumbDivider,
} from "@fluentui/react-components";
import { MoreHorizontalFilled } from "@fluentui/react-icons";

export type CustomOverflowItem = {
  key: number;
  item?: string;
  itemProps?: {
    icon?: ButtonProps["icon"];
    disabled?: boolean;
    onClick?: () => void;
  };
};

export const OverflowMenuItem: React.FC<{
  id: string;
  item: CustomOverflowItem;
}> = (props) => {
  const { item, id } = props;
  const isVisible = useIsOverflowItemVisible(id);

  if (isVisible) {
    return null;
  }

  return <MenuItem {...item.itemProps}>{item.item}</MenuItem>;
};

export const OverflowGroupDivider: React.FC<{
  groupId: string;
}> = (props) => {
  return (
    <OverflowDivider groupId={props.groupId}>
      <BreadcrumbDivider data-group={props.groupId} />
    </OverflowDivider>
  );
};

export type OverflowMenuProps = {
  items: CustomOverflowItem[];
};

export const OverflowMenu: React.FC<OverflowMenuProps> = (props) => {
  const { ref, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

  if (!isOverflowing) {
    return null;
  }

  return (
    <Menu hasIcons>
      <MenuTrigger>
        <MenuButton
          ref={ref}
          icon={<MoreHorizontalFilled />}
          role="button"
          appearance={"subtle"}
        />
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          {props.items.map((i) => {
            return (
              <OverflowMenuItem
                item={i}
                id={i.key.toString()}
                key={`ofm-${i.key}`}
              />
            );
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
