import { useContext } from "react";
import { StreamerScene } from "./StreamerScene.tsx";
import { ShareHierarchyContext } from "../share/ShareHierarchyProvider.tsx";
import { ISession } from "scout-sharing-models";
import { Spinner } from "@fluentui/react-components";
import { useParams } from "react-router-dom";

export const StreamerRoute = () => {
  const { state, hierarchy } = useContext(ShareHierarchyContext);
  const { shareId, sessionId } = useParams();

  if (state !== "initialized") {
    // TODO: Add loading skeleton
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  } else {
    const session: ISession | undefined = hierarchy.assets
      .flatMap((a) =>
        a.targets.flatMap((t) => t.inspections.flatMap((i) => i.sessions)),
      )
      .find((s) => s.id === sessionId);

    if (session === undefined || shareId === undefined) {
      return <p>Session not found</p>;
    } else {
      return <StreamerScene session={session} shareId={shareId} />;
    }
  }
};
