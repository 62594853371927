import { useEffect, useState } from "react";
import { Quaternion, Vector3 } from "three";
import { getDroneModel } from "../../utils/3dutils.ts";
import { GltfModel } from "./gltfModel.tsx";
import { GLTF } from "three/examples/jsm/loaders/GLTFLoader";

export const DroneModel = (props: {
  orientation: Quaternion;
  position: Vector3;
}) => {
  const [droneGLTF, setDroneGLTF] = useState<GLTF | undefined>(undefined);

  useEffect(() => {
    let cleanedUp = false;
    getDroneModel().then((drone) => {
      if (cleanedUp) {
        return;
      }
      setDroneGLTF(drone);
    });
    return () => {
      cleanedUp = true;
    };
  }, []);

  return droneGLTF !== undefined ? (
    <GltfModel
      gltf={droneGLTF}
      position={props.position}
      quaternion={props.orientation}
    />
  ) : (
    <></>
  );
};
