import React, { useState } from "react";
import {
  createTableColumn,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableColumnSizingOptions,
  TableRow,
  Tooltip,
  useTableColumnSizing_unstable,
  useTableFeatures,
} from "@fluentui/react-components";
import { IPosition } from "./measurement-component";
import {
  ArrowDownRegular,
  ArrowRightRegular,
  ArrowUpRegular,
} from "@fluentui/react-icons";
import { additionalDataInfo } from "../../../../../utils";

type PositionDatum = {
  key: string;
  icon: JSX.Element;
  value: string;
};

const columnsDef: TableColumnDefinition<PositionDatum>[] = [
  createTableColumn({
    columnId: "icon",
  }),
  createTableColumn<PositionDatum>({
    columnId: "value",
  }),
];

const MeasurementPositionTable: React.FC<{ position?: IPosition }> = (
  props,
) => {
  const [columns] =
    useState<TableColumnDefinition<PositionDatum>[]>(columnsDef);
  const [columnSizingOptions] = useState<TableColumnSizingOptions>({
    icon: {
      idealWidth: 80,
      minWidth: 50,
    },
    value: {
      minWidth: 100,
      defaultWidth: 100,
    },
  });

  const positions: PositionDatum[] = [
    {
      key: "distance_up",
      icon: (
        <Tooltip content={"Distance up"} relationship="label">
          <ArrowUpRegular />
        </Tooltip>
      ),
      value: additionalDataInfo.distance_up.formatValue(
        props.position?.distance_up,
      ),
    },
    {
      key: "distance_down",
      icon: (
        <Tooltip content={"Distance down"} relationship="label">
          <ArrowDownRegular />
        </Tooltip>
      ),
      value: additionalDataInfo.distance_down.formatValue(
        props.position?.distance_down,
      ),
    },
    {
      key: "distance_front",
      icon: (
        <Tooltip content={"Distance forward"} relationship="label">
          <ArrowRightRegular />
        </Tooltip>
      ),
      value: additionalDataInfo.distance_front.formatValue(
        props.position?.distance_front,
      ),
    },
  ];

  const { getRows, columnSizing_unstable, tableRef } = useTableFeatures(
    {
      columns: columns,
      items: positions,
    },
    [useTableColumnSizing_unstable({ columnSizingOptions })],
  );

  const rows = getRows();

  return (
    <Table ref={tableRef} {...columnSizing_unstable.getTableProps()}>
      <TableBody>
        {rows.map(({ item }) => (
          <TableRow key={item.key}>
            <TableCell {...columnSizing_unstable.getTableCellProps("icon")}>
              <TableCellLayout>{item.icon}</TableCellLayout>
            </TableCell>
            <TableCell {...columnSizing_unstable.getTableCellProps("value")}>
              <TableCellLayout>{item.value}</TableCellLayout>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default MeasurementPositionTable;
