import React, { useContext, useEffect, useRef } from "react";
import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogContent,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { useSearchParams } from "react-router-dom";
import FindingDataPanel from "./FindingsDataPanel/FindingDataPanel.tsx";
import FindingsDialogFooter from "./FindingsDialogFooter/FindingsDialogFooter.tsx";
import FindingsDialogTitle from "./FindingsDialogTitle/FindingsDialogTitle.tsx";
import { FindingsDialogControllerContext } from "./FindingsDialogControllerProvider.tsx";
import { IFinding } from "scout-sharing-models";
import FindingsDialogImage from "./FindingsDialogImage/FindingsDialogImage.tsx";

const useStyles = makeStyles({
  dialog: {
    minWidth: "90%",
    height: "90%",
    ...shorthands.overflow("hidden", "hidden"),
  },
  body: {
    height: "100%",
    ...shorthands.overflow("hidden", "hidden"),
  },
  content: {
    ...shorthands.flex(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width: 900px)": {
      flexDirection: "column",
      ...shorthands.gap(tokens.spacingVerticalL),
    },
    ...shorthands.overflow("hidden"),
  },
  imageContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    "@media (max-width: 900px)": {
      aspectRatio: "16/9",
      height: "auto",
    },
  },
});

type FindingsDialogProps = {
  open: boolean;
  handleViewInVideo: (finding: IFinding) => void;
};

export const FindingsDialog: React.FC<FindingsDialogProps> = (
  props: FindingsDialogProps,
) => {
  const styles = useStyles();
  const findingImageContainerRef = useRef<HTMLDivElement>(null);

  const { prevFinding, nextFinding, currentFinding } = useContext(
    FindingsDialogControllerContext,
  );

  const [, setSearchParams] = useSearchParams();

  const handleFullscreen = () => {
    findingImageContainerRef.current?.requestFullscreen();
  };

  useEffect(() => {
    if (nextFinding === undefined || prevFinding === undefined) {
      return;
    }
    const arrowEventListener = (e: KeyboardEvent) => {
      if (e.key === "ArrowLeft") {
        prevFinding();
      } else if (e.key === "ArrowRight") {
        nextFinding();
      }
    };
    document.addEventListener("keydown", arrowEventListener);
    return () => {
      document.removeEventListener("keydown", arrowEventListener);
    };
  }, [nextFinding, prevFinding]);

  return (
    <Dialog
      open={props.open}
      onOpenChange={(_, data) => {
        setSearchParams((prev) => {
          prev.set("poiDialog", data.open.toString());
          return prev;
        });
      }}
    >
      <DialogSurface className={styles.dialog}>
        <DialogBody className={styles.body}>
          <FindingsDialogTitle
            handleFullscreen={handleFullscreen}
            handleViewInVideo={() =>
              currentFinding !== undefined &&
              props.handleViewInVideo(currentFinding)
            }
          />
          <DialogContent className={styles.content}>
            <div className={styles.imageContainer}>
              <div style={{ flex: 1 }}>
                <FindingsDialogImage containerRef={findingImageContainerRef} />
              </div>
            </div>
            <FindingDataPanel />
          </DialogContent>
          <FindingsDialogFooter />
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
