import { PropsWithChildren, useEffect, useRef } from "react";

import panzoom, { PanZoom } from "panzoom";
import { isMobile } from "react-device-detect";

import style from "./video-pane.module.scss";
import { VideoJsSource } from "../videoplayer/videojs.ts";
import { VideoPlayer } from "../videoplayer/videoplayer.tsx";
import { VideoJsPlayer } from "video.js";
import { Button } from "@fluentui/react-components";
import { ZoomFitFilled } from "@fluentui/react-icons";

export interface Option<T> {
  label: string;
  value: string | number | boolean;
  data: T;
}

type VideoPaneProps = PropsWithChildren<{
  onPlayerReady: (player: VideoJsPlayer) => void;
  inspectionId: string;
  videoSrc: string | VideoJsSource | VideoJsSource[];
}>;

export const VideoPane = (props: VideoPaneProps) => {
  const videoElementRef = useRef<HTMLVideoElement>();
  const panzoomObjectRef = useRef<PanZoom>();
  useEffect(() => {
    const videoElement = videoElementRef.current;
    if (videoElement == null) {
      return;
    }
    try {
      panzoomObjectRef.current = panzoom(videoElement, {
        bounds: true,
        zoomDoubleClickSpeed: 1,
        maxZoom: 5,
        minZoom: 1,
        beforeMouseDown: function () {
          return true;
        },
      });
    } catch (err) {
      console.warn(
        "Error when setting up panzoom. Ignoring exception. If we dont ignore it, IOS devices crash at this point. TODO: Handle this exception properly",
        err,
      );
    }

    return () => {
      panzoomObjectRef.current?.dispose();
    };
  }, [videoElementRef, panzoomObjectRef]);

  const resetVideoZoom = () => {
    const panzoomObject = panzoomObjectRef.current;
    try {
      if (panzoomObject) {
        panzoomObject.pause();
        panzoomObject.setTransformOrigin({ x: 0, y: 0 });
        panzoomObject.moveTo(0, 0);
        panzoomObject.zoomAbs(0, 0, 1);
        // TODO: Properly apply transform origin instead of setting it to null and ts-ignore
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        panzoomObject.setTransformOrigin(null);
        panzoomObject.resume();
      }
    } catch (err) {
      console.warn(
        "Error when resetting video zoom with panzoom. Ignoring exception. If we dont ignore it, IOS devices crash at this point. TODO: Handle this exception properly",
        err,
      );
    }
  };

  return (
    <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <VideoPlayer
        videoElementRef={videoElementRef}
        onPlayerReady={props.onPlayerReady}
        src={props.videoSrc}
      />

      <div className={style.videoButtonGroup}>
        {!isMobile && (
          <Button
            className={style.videoButton}
            onClick={resetVideoZoom}
            icon={<ZoomFitFilled />}
          />
        )}
      </div>
      {props.children}
    </div>
  );
};
