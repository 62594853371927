import TitleActions from "./TitleActions.tsx";
import { DialogTitle } from "@fluentui/react-components";
import React, { useContext } from "react";
import useWindowDimensions from "../../../../../../../hooks/useWindowDimensions.tsx";
import { FindingsDialogControllerContext } from "../FindingsDialogControllerProvider.tsx";

type FindingsDialogTitleProps = {
  handleFullscreen: () => void;
  handleViewInVideo: () => void;
};

const FindingsDialogTitle: React.FC<FindingsDialogTitleProps> = (props) => {
  const { width } = useWindowDimensions();
  const { currentFinding } = useContext(FindingsDialogControllerContext);
  return (
    <DialogTitle
      action={
        <TitleActions
          handleFullscreen={props.handleFullscreen}
          handleViewInVideo={props.handleViewInVideo}
        />
      }
    >
      {width > 600 ? `Point of interest ${currentFinding?.id}` : ""}
    </DialogTitle>
  );
};

export default FindingsDialogTitle;
