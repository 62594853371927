import React, { useContext, useEffect, useRef } from "react";
import {
  Button,
  FluentProvider,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Persona,
  PositioningImperativeRef,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import ThemeMenu from "./ThemeMenu.tsx";
import {
  ArrowExitRegular,
  CaretDownFilled,
  NavigationRegular,
} from "@fluentui/react-icons";
import useWindowDimensions from "../../hooks/useWindowDimensions.tsx";
import { AuthContext } from "../../auth/AuthProvider.tsx";
import { useNavigate, useParams } from "react-router-dom";
import useBrandLogo from "../../components/CompanyBrand/useBrandLogo.tsx";
import { useAlternativeLightTheme } from "../../styles/styles.ts";

const useStyles = makeStyles({
  nav: {
    ...shorthands.padding(tokens.spacingVerticalM),
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: tokens.colorNeutralBackground3,
  },

  navGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap(tokens.spacingHorizontalM),
  },
});

type NavbarProps = {
  onToggleNavDrawer: () => void;
};

const Navbar: React.FC<NavbarProps> = (props) => {
  const styles = useStyles();

  const personaCaretRef = useRef<SVGSVGElement>(null);
  const positioningRef = useRef<PositioningImperativeRef>(null);

  const { user, logout } = useContext(AuthContext);

  const { shareId } = useParams();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const logo = useBrandLogo("small", "light");
  const alternativeLightTheme = useAlternativeLightTheme();

  useEffect(() => {
    if (personaCaretRef.current) {
      positioningRef.current?.setTarget(personaCaretRef.current);
    }
  }, [personaCaretRef, positioningRef]);

  return (
    <FluentProvider theme={alternativeLightTheme}>
      <div className={styles.nav}>
        <div className={styles.navGroup}>
          <Button
            icon={<NavigationRegular />}
            appearance={"subtle"}
            size={"large"}
            onClick={props.onToggleNavDrawer}
          />
          <div
            onClick={() => navigate(`/share/${shareId}/`)}
            style={{ cursor: "pointer" }}
          >
            {logo}
          </div>
        </div>
        <div className={styles.navGroup}>
          <Menu positioning={{ positioningRef }}>
            <MenuTrigger>
              <Button
                appearance={"subtle"}
                icon={<CaretDownFilled ref={personaCaretRef} />}
                iconPosition={"after"}
              >
                <Persona
                  color={"brand"}
                  name={user?.email}
                  primaryText={width > 480 ? user?.email : ""}
                  secondaryText={width > 480 ? "Guest User" : ""}
                  presence={{ status: "available" }}
                  avatar={{
                    color: "brand",
                  }}
                />
              </Button>
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                <ThemeMenu />
                <MenuItem icon={<ArrowExitRegular />} onClick={logout}>
                  Logout
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
      </div>
    </FluentProvider>
  );
};

export default Navbar;
