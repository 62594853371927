import { IShareHierarchy } from "scout-sharing-models";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiContext } from "../../../api/IApiClient.ts";
import { AuthContext } from "../../../auth/AuthProvider.tsx";
import { Resource } from "../../../components/ActionBarProvider/ResourceBreadcrumb.tsx";

export type InitializedHierarchy = {
  state: "initialized";
  hierarchy: IShareHierarchy;
};

export type UninitializedHierarchy = {
  state: "uninitialized" | "initializing" | "failed";
  hierarchy: null;
};

export const ShareHierarchyContext = createContext<
  (InitializedHierarchy | UninitializedHierarchy) & {
    getResourcePath: (resource: Resource) => Resource[];
  }
>({
  state: "uninitialized",
  hierarchy: null,
  getResourcePath: () => [],
});

const ShareHierarchyProvider: React.FC<PropsWithChildren> = (props) => {
  const apiClient = useContext(ApiContext);
  const { user } = useContext(AuthContext);

  const [hierarchy, setHierarchy] = useState<IShareHierarchy | null>(null);
  const [data, setData] = useState<
    InitializedHierarchy | UninitializedHierarchy
  >({
    state: "uninitialized",
    hierarchy: null,
  });

  const navigate = useNavigate();
  const { shareId } = useParams();

  const getResourcePath = (resource: Resource) => {
    let resourcePath: Resource[] = [];
    hierarchy?.assets.forEach((asset) => {
      if (asset.id === resource.id) {
        resourcePath = [{ type: "directory", id: asset.id, name: asset.name }];
      }
      asset.targets.forEach((target) => {
        if (target.id === resource.id) {
          resourcePath = [
            { type: "directory", id: asset.id, name: asset.name },
            { type: "directory", id: target.id, name: target.name },
          ];
        }
        target.inspections.forEach((inspection) => {
          if (inspection.id === resource.id) {
            resourcePath = [
              { type: "directory", id: asset.id, name: asset.name },
              { type: "directory", id: target.id, name: target.name },
              { type: "inspection", id: inspection.id, name: inspection.name },
            ];
          }
          inspection.sessions.forEach((session) => {
            if (session.id === resource.id) {
              resourcePath = [
                { type: "directory", id: asset.id, name: asset.name },
                { type: "directory", id: target.id, name: target.name },
                {
                  type: "inspection",
                  id: inspection.id,
                  name: inspection.name,
                },
                {
                  type: "session",
                  id: session.id,
                  name: session.title ?? "Unamed Session",
                },
              ];
            }
          });
        });
      });
    });
    return resourcePath;
  };

  useEffect(() => {
    if (data.state !== "uninitialized" && hierarchy !== null) {
      setData({
        state: "initialized",
        hierarchy: hierarchy,
      });
    }
  }, [data.state, hierarchy]);

  useEffect(() => {
    if (hierarchy === null && shareId !== undefined) {
      setData((prev) => {
        if (prev.state === "uninitialized")
          return { ...prev, state: "initializing" };
        return prev;
      });
      apiClient
        .getShareHierarchy(shareId)
        .then((hierarchy) => {
          setHierarchy(hierarchy);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [shareId, user, apiClient, hierarchy, navigate]);
  return (
    <ShareHierarchyContext.Provider value={{ ...data, getResourcePath }}>
      {props.children}
    </ShareHierarchyContext.Provider>
  );
};

export default ShareHierarchyProvider;
