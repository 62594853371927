import { useContext } from "react";
import { useParams } from "react-router-dom";
import { IShareInspection } from "scout-sharing-models";
import { ShareHierarchyContext } from "../ShareHierarchyProvider.tsx";
import InspectionScene, {
  InspectionSceneFallback,
} from "./InspectionScene.tsx";

export const InspectionRoute = () => {
  const { state, hierarchy } = useContext(ShareHierarchyContext);
  const { inspectionId } = useParams();

  if (state !== "initialized") {
    return <InspectionSceneFallback />;
  } else {
    const inspection: IShareInspection | undefined = hierarchy.assets
      .flatMap((a) => a.targets.flatMap((t) => t.inspections))
      .find((i) => i.id === inspectionId);

    if (inspection === undefined) {
      return <p>inspection not found</p>;
    } else {
      return <InspectionScene inspection={inspection} />;
    }
  }
};
