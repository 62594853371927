import * as THREE from "three";
import { useContext, useEffect } from "react";
import { SceneContext } from "../virtualScene.tsx";
import { Object3D } from "three";
import { ScoutObject3DData } from "../../models.ts";
import { useInScene } from "../../hooks/useInScene.ts";

interface DronePathEntry {
  time: number;
  position: THREE.Vector3;
}

export const FlightPath = (props: { points: DronePathEntry[] }) => {
  useInScene(
    false,
    false,
    () => {
      const lineGeometry = new THREE.BufferGeometry();
      const points: number[] = [];
      props.points.forEach((p) => {
        points.push(p.position.x, p.position.y, p.position.z);
      });

      lineGeometry.setAttribute(
        "position",
        new THREE.Float32BufferAttribute(points, 3),
      );

      const material = new THREE.LineBasicMaterial({
        opacity: 0.6,
        color: new THREE.Color(0xd0ffff),
      });
      material.transparent = true;
      return new THREE.Line(lineGeometry, material);
    },
    undefined,
    [props.points],
  );

  const scene = useContext(SceneContext);

  useEffect(() => {
    const spheres = props.points
      .map((entry) => {
        const p = entry.position;
        const geometry = new THREE.SphereGeometry(0.015, 6, 6);
        const material = new THREE.MeshBasicMaterial({ color: 0xd0ffff });
        const sphere: Object3D = new THREE.Mesh(geometry, material);
        sphere.position.set(p.x, p.y, p.z);
        sphere.userData = {
          type: "flightpath",
          time: entry.time,
        } as ScoutObject3DData;

        // Click sphere is an invisible but larger sphere that is used for click detection
        const clickGeometry = new THREE.SphereGeometry(0.05, 6, 6);
        const clickMaterial = new THREE.MeshBasicMaterial({
          color: 0x00ff00,
        });
        clickMaterial.transparent = true;
        const clickSphere: Object3D = new THREE.Mesh(
          clickGeometry,
          clickMaterial,
        );

        // The click-sphere is invisible, we only want it to register clicks.
        clickSphere.visible = false;
        clickSphere.position.set(p.x, p.y, p.z);
        clickSphere.userData = {
          type: "flightpath",
          time: entry.time,
        } as ScoutObject3DData;
        return [clickSphere, sphere];
      })
      .flatMap((s) => s);

    spheres.forEach((s) => scene.clickables.add(s));
    scene.scene?.add(...spheres);
    scene.invalidate();
    return () => {
      spheres.forEach((s) => {
        scene.scene?.remove(s);
        scene.clickables.delete(s);
      });

      scene.invalidate();
    };
  }, [scene, props.points]);
  return <></>;
};
