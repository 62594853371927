import React from "react";
import {
  Button,
  makeStyles,
  mergeClasses,
  shorthands,
  Skeleton,
  SkeletonItem,
  tokens,
  Tooltip,
} from "@fluentui/react-components";
import { IFinding } from "scout-sharing-models";
import { useBaseStyles } from "../../../../../../styles/styles.ts";
import { useSearchParams } from "react-router-dom";
import { FlagFilled, VideoFilled } from "@fluentui/react-icons";

const useStyles = makeStyles({
  smallRadius: {
    ...shorthands.borderRadius(tokens.borderRadiusSmall),
  },

  image: {
    objectFit: "cover",
    aspectRatio: "1/1",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },

  iconsContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    ...shorthands.padding(tokens.spacingHorizontalXS),
    ...shorthands.gap(tokens.spacingHorizontalXS),
  },

  button: {
    backgroundColor: tokens.colorNeutralBackground1,
    opacity: 0.8,
  },
});

export type FindingCardProps = {
  finding: IFinding;
  handleViewInVideo: () => void;
};

const FindingCard: React.FC<FindingCardProps> = (props) => {
  const styles = useStyles();
  const baseStyles = useBaseStyles();

  const [searchParams, setSearchParams] = useSearchParams();
  const selected = searchParams.get("poi") === props.finding.id.toString();

  return (
    <div
      className={mergeClasses(
        baseStyles.hoverOutline,
        selected && baseStyles.outline,
        styles.smallRadius,
      )}
      style={{ position: "relative" }}
    >
      <div className={styles.iconsContainer}>
        {props.finding.flagged && (
          <Tooltip content="Flagged" relationship="label">
            <Button icon={<FlagFilled />} className={styles.button} />
          </Tooltip>
        )}
        <Tooltip content="View in session" relationship="label">
          <Button
            icon={<VideoFilled />}
            className={styles.button}
            onClick={props.handleViewInVideo}
          />
        </Tooltip>
      </div>
      <img
        className={mergeClasses(styles.image, styles.smallRadius)}
        src={
          props.finding.thumbnail ||
          props.finding.preview ||
          props.finding.image ||
          "/black.png"
        }
        onClick={() =>
          setSearchParams((prev) => {
            prev.set("poi", props.finding.id.toString());
            prev.set("poiDialog", "true");
            return prev;
          })
        }
      />
    </div>
  );
};

export const FindingCardFallback: React.FC = () => {
  const styles = useStyles();
  // const baseStyles = useBaseStyles();
  return (
    <Skeleton appearance={"translucent"}>
      <SkeletonItem
        className={mergeClasses(styles.image, styles.smallRadius)}
      />
    </Skeleton>
  );
};

export default FindingCard;
