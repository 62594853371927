import { IPCD } from "../models.ts";
import { useRef } from "react";
import { useAnimationLoop } from "./useAnimationLoop.ts";
import { getDronePose, getEntryAtTime } from "../utils/utils.ts";
import { loadPCD } from "../utils/3dutils.ts";
import { IDroneState, ITimeDepTransform } from "../../../../../../interface.ts";

export const useRecordedPlayback = (
  enabled: boolean,
  timeSource: { currentTime: () => number | undefined } | null,
  pcdEntries: IPCD[],
  dronePoses: IDroneState[],
  setDroneState: (state: IDroneState) => void,
  setPointCloud: (pcd: IPCD) => void,
  transforms?: ITimeDepTransform[],
) => {
  // todo give a more descriptive name for the prevRenderTimeRef
  const prevRenderTimeRef = useRef(0);
  const globalMapEnabled = transforms !== undefined;
  useAnimationLoop(
    enabled,
    (isFirstRun) => {
      const time = timeSource?.currentTime();
      if (
        time &&
        (Math.abs(time - prevRenderTimeRef.current) > 1 / 30 || isFirstRun)
      ) {
        prevRenderTimeRef.current = time;
        // if we are on global map mode, we will not load local point clouds
        if (!globalMapEnabled && pcdEntries.length > 0) {
          const pcdEntry = getEntryAtTime(pcdEntries, time, (e) => e.time);
          // todo handle pcdEntry can be undefined here
          if (pcdEntry === undefined) {
            return;
          }
          // todo handle memory leaks here
          if (pcdEntry.points !== undefined) {
            setPointCloud(pcdEntry);
          } else {
            loadPCD(pcdEntry.file).then((pointCloud) => {
              // todo check if the time is still the same
              pcdEntry.points = pointCloud;
              setPointCloud(pcdEntry);
            });
          }
        }

        if (dronePoses.length > 0) {
          const pose = getDronePose(time, dronePoses, transforms);
          setDroneState(pose);
        }
      }
    },
    [
      timeSource,
      dronePoses,
      pcdEntries,
      setPointCloud,
      setDroneState,
      globalMapEnabled,
    ],
  );
};
