import * as THREE from "three";
import React, { useContext, useEffect } from "react";
import { SceneContext } from "../virtualScene";
import {
  ScoutPointCloudMaterial,
  pointCloudColorPalettes,
} from "../../materials/ScoutPointCloudMaterial";
import { Uniforms } from "../../materials/ScoutPointMaterial.glsl";
import { useInScene } from "../../hooks/useInScene";
import { PointCloudAttribute } from "../../utils/3dutils.ts";

export const PointCloud = (props: {
  pointCloud: THREE.Points;
  pointSize: number;
  opacity: number;
  pointCloudAttribute: PointCloudAttribute;
  colorPalette: number;
}) => {
  const pointSize = props.pointSize;
  const opacity = props.opacity;
  const colorMode = props.pointCloudAttribute.code;
  const colorPalette = props.colorPalette;

  const uniforms = (props.pointCloud.material as ScoutPointCloudMaterial)
    .uniforms as unknown as Uniforms;
  uniforms.uPointSize = {
    value: pointSize,
  };
  uniforms.uOpacity = {
    value: opacity,
  };
  uniforms.uColorMode = {
    value: colorMode,
  };
  uniforms.uPaletteUVOffset = {
    // Vertical UV offset for the palette texture (0.0 is the top of the texture)
    value:
      (pointCloudColorPalettes.length - colorPalette - 0.5) /
      pointCloudColorPalettes.length,
  };
  const scene = useContext(SceneContext);
  useInScene(false, false, props.pointCloud, undefined, [props.pointCloud]);

  useEffect(() => {
    // invalidate scene if point size of opacity changes
    scene.invalidate();
  }, [pointSize, scene, opacity, colorMode, colorPalette]);

  return <></>;
};
