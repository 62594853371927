import style from "./poi-hover.module.scss";
import { MeasurementComponent } from "../measurements/measurement-component.tsx";
import moment from "moment";
import { ILocalizedPOIMeasurement } from "../../../../../interface.ts";
import { tokens } from "@fluentui/react-components";

interface MeasurementHover {
  hover: "measurement";
  sessionStartTime: moment.Moment;
  measurementIdx: number;
  localizedMeasurement: ILocalizedPOIMeasurement;
}

interface FindingHover {
  hover: "finding";
  finding: {
    thumbnail: string | null;
    image: string | null;
    preview: string | null;
    description: string | null;
  };
}

interface NoneHover {
  hover: "none";
}

const POIHoverView = (props: MeasurementHover | FindingHover | NoneHover) => {
  let hide = props.hover === "none";
  let contents: JSX.Element = <></>;
  if (props.hover === "measurement") {
    hide = false;
    contents = (
      <div
        style={{
          backgroundColor: tokens.colorNeutralBackground1,
          width: "400px",
          height: "205px",
          borderRadius: "5px",
          overflow: "hidden",
        }}
      >
        <MeasurementComponent
          isSelected={false}
          measurementIdx={props.measurementIdx}
          measurement={props.localizedMeasurement.measurement}
          height={160}
        />
      </div>
    );
  } else if (props.hover === "finding") {
    const imageClassName = `${style.onHoverThumbnail} ${style.visible} ${style.absoluteBottomPosition}`;
    const url =
      props.finding.thumbnail ?? props.finding.image ?? props.finding.preview;
    contents = (
      <img
        alt={props.finding.description ?? ""}
        className={imageClassName}
        src={url ?? ""}
      />
    );
  }

  const containerClassName = `${hide ? style.fadeOut : style.fadeIn}`;

  return <div className={containerClassName}>{contents}</div>;
};

export default POIHoverView;
