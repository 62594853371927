import * as React from "react";
import { Dismiss24Regular } from "@fluentui/react-icons";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerProps,
} from "@fluentui/react-components/unstable";
import { Button } from "@fluentui/react-components";
import Hierarchy from "../../scenes/root/share/inspections/components/Hierarchy/Hierarchy.tsx";
import useWindowDimensions from "../../hooks/useWindowDimensions.tsx";
import { useNavigate, useParams } from "react-router-dom";

type DrawerType = Required<DrawerProps>["type"];

type NavDrawerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const NavDrawer: React.FC<NavDrawerProps> = (props) => {
  const { width } = useWindowDimensions();
  const { shareId } = useParams();
  const navigate = useNavigate();

  const type: DrawerType = width > 600 ? "inline" : "overlay";

  return (
    <Drawer
      type={type}
      separator
      open={props.open}
      onOpenChange={(_, { open }) => props.setOpen(open)}
      size={width > 600 ? undefined : "full"}
    >
      <DrawerHeader>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={() => props.setOpen(false)}
            />
          }
        >
          Inspections
        </DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody>
        <Hierarchy
          onClickInspection={(inspectionId: string) => {
            props.setOpen(false);
            navigate(`/share/${shareId}/inspections/${inspectionId}`);
          }}
        />
      </DrawerBody>
    </Drawer>
  );
};

export default NavDrawer;
