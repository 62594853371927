import { PropsWithChildren, useContext } from "react";
import { ApiContext, buildApiClient } from "./IApiClient.ts";
import { AuthContext } from "../auth/AuthProvider.tsx";

export const ApiClientProvider = (p: PropsWithChildren) => {
  const { user } = useContext(AuthContext);
  const apiClient = buildApiClient(async () => user?.token ?? "");
  return (
    <ApiContext.Provider value={apiClient}>{p.children}</ApiContext.Provider>
  );
};
