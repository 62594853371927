import { createBrowserRouter } from "react-router-dom";
import Login from "./scenes/root/login/Login.tsx";
import React from "react";
import { Root } from "./scenes/root/Root.tsx";
import Share from "./scenes/root/share/Share.tsx";
import { StreamerRoute } from "./scenes/root/streamer/StreamerRoute.tsx";
import Browse from "./scenes/root/share/Browse.tsx";
import { InspectionRoute } from "./scenes/root/share/inspections/InspectionRoute.tsx";
import {
  ExpiredPage,
  ForbiddenPage,
  NotFoundPage,
  UnauthorizedPage,
} from "./components/ErrorPages/ErrorPages.tsx";

export const paramIds = {
  shareId: "shareId",
  sessionId: "sessionId",
};

const browserRouter = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "expired",
        element: <ExpiredPage />,
      },
      {
        path: "forbidden",
        element: <ForbiddenPage />,
      },
      {
        path: "unauthorized",
        element: <UnauthorizedPage />,
      },
      {
        path: `share/:${paramIds.shareId}`,
        element: <Share />,
        children: [
          {
            element: <Browse />,
            path: "",
          },
          {
            element: <InspectionRoute />,
            path: "inspections/:inspectionId",
          },
          {
            element: <StreamerRoute />,
            path: `session/:sessionId`,
          },
        ],
      },
    ],
  },
]);

export default browserRouter;
