import { IDroneState, ITimeDepTransform } from "../../../interface.ts";
import moment from "moment";
import { Quaternion, Vector3 } from "three";
import {
  IFindingAdditionalData,
  ILEDBrightness,
  IPointCloudModel,
  IPositionData,
  ITimeDepTransformDto,
} from "scout-sharing-models";
import { DronePathEntry, IPCD } from "./components/3dView/models.ts";
import * as THREE from "three";
import {
  getEntryAtTime,
  transformPose,
} from "./components/3dView/utils/utils.ts";

export const parsePositionTimestamp = (timestamp: string): moment.Moment => {
  if (timestamp.indexOf("Z") > 1) {
    // Moment.js' parser ignores separators in strict mode, so even though the actual format is hhmmss,
    // hh:mm:ss works just the same. If we are in strict mode, neither of the formats will work as it
    // expects there to be separators
    return moment(timestamp, "YYYY-MM-DD hh:mm:ss.SSSSSSSZZ").utc();
  } else {
    return moment.utc(timestamp, true);
  }
};

export const createDroneStateObjects = (
  sessionStartTime: moment.Moment,
  droneStateDtos: IPositionData,
): IDroneState[] => {
  const droneStates: IDroneState[] = [];

  for (const droneStateDto of droneStateDtos) {
    // Ignore position if it doesn't have timestamp, we can't use it
    if (droneStateDto[0] !== null) {
      const parsedTimestamp = parsePositionTimestamp(
        droneStateDto[0] as string,
      );

      const cameraOrientation =
        droneStateDto.length >= 12
          ? new Quaternion(
              droneStateDto[8] as number,
              droneStateDto[9] as number,
              droneStateDto[10] as number,
              droneStateDto[11] as number,
            )
          : new Quaternion(0, 0, 0, 1);
      const cameraPosition = new Vector3(0, 0, 0); // Even if these values are present in the data from the server, they are always 0
      droneStates.push({
        time: parsedTimestamp,
        relativeTime: parsedTimestamp.diff(sessionStartTime, "seconds", true),
        state: {
          position: new Vector3(
            droneStateDto[1] as number,
            droneStateDto[2] as number,
            droneStateDto[3] as number,
          ),
          orientation: new Quaternion(
            droneStateDto[4] as number,
            droneStateDto[5] as number,
            droneStateDto[6] as number,
            droneStateDto[7] as number,
          ),
          camera_position: cameraPosition,
          camera_orientation: cameraOrientation,
          additional_data: {
            distance_down: droneStateDto[15] as number,
            distance_up: droneStateDto[16] as number,
            distance_front: droneStateDto[17] as number,
            lights: {
              right_top: droneStateDto[18] as number,
              right_middle: droneStateDto[19] as number,
              right_bottom: droneStateDto[20] as number,
              left_bottom: droneStateDto[21] as number,
              left_middle: droneStateDto[22] as number,
              left_top: droneStateDto[23] as number,
            } as ILEDBrightness,
          } as IFindingAdditionalData,
        },
      });
    }
  }

  return droneStates;
};

export const relativeTimePointClouds = (
  sessionStart: moment.Moment,
  pointClouds: IPointCloudModel[],
): IPCD[] => {
  let timestamp;
  let parsedDate;
  return pointClouds.map((pointCloud) => {
    timestamp = pointCloud.timestamp;
    parsedDate = moment.utc(timestamp, true);
    return {
      file: pointCloud.file,
      opacity: 1.0,
      time: parsedDate.diff(sessionStart, "seconds", true),
      points: undefined,
    };
  });
};

export function buildDronePathVectors(
  sanitisedPositions: IDroneState[],
  transforms?: ITimeDepTransform[],
): DronePathEntry[] {
  const zeroOrientationTransform = new THREE.Quaternion(0, 0, 0, 1);

  let startVector = sanitisedPositions[0].state.position;
  const pathEntries: DronePathEntry[] = [];
  let trFn: (p: IDroneState, idx: number) => DronePathEntry;
  if (transforms) {
    trFn = (p) => {
      const transform = getEntryAtTime(
        transforms,
        p.relativeTime,
        (t) => t.relativeTime,
      );
      const { position } = transformPose(
        p.state.position,
        zeroOrientationTransform,
        transform,
      );
      return {
        time: p.relativeTime,
        position,
      };
    };
  } else {
    trFn = (p) => {
      return {
        time: p.relativeTime,
        position: p.state.position,
      };
    };
  }
  // add points which are at least 0.1m apart
  sanitisedPositions.forEach((p, idx) => {
    const v = p.state.position;
    if (v.distanceTo(startVector) > 0.1) {
      pathEntries.push(trFn(p, idx));
      startVector = v;
    }
  });
  return pathEntries;
}

export const relativeTimeTransforms = (
  sessionStart: moment.Moment,
  transforms: ITimeDepTransformDto[],
): ITimeDepTransform[] => {
  return transforms.map((t) => {
    const time = moment.utc(t.time, true);
    const relativeTime = time.diff(sessionStart, "seconds", true);
    return {
      time,
      relativeTime,
      orientation_transform: new THREE.Quaternion(
        t.orientation_transform.x,
        t.orientation_transform.y,
        t.orientation_transform.z,
        t.orientation_transform.w,
      ),
      position_transform: new THREE.Vector3(
        t.position_transform.x,
        t.position_transform.y,
        t.position_transform.z,
      ),
    };
  });
};

export const getIndexedEntry = <T>(
  entryOrIndex: T | number,
  entries: T[],
  getId: (entry: T) => string,
): { entry: T | undefined; index: number | undefined } => {
  const entry =
    typeof entryOrIndex === "number" ? entries[entryOrIndex] : entryOrIndex;
  const index =
    typeof entryOrIndex === "number"
      ? entryOrIndex
      : entries.findIndex((e) => getId(e) === getId(entry));
  return { entry, index };
};
