import React from "react";
import { ReactPictureAnnotation } from "react-picture-annotation";
import { IShapeStyle } from "react-picture-annotation/dist/types/src/Shape";
import { IFinding } from "scout-sharing-models";
import { ImageSize } from "../FindingsDialogImage";
import ZoomableImage, {
  IZoomableImageHandler,
} from "./zoomable-image/zoomable-image";
import style from "./old_styles.module.scss";
import { CustomInputWithText } from "./annotation-inputs";

type FindingImageProps = {
  finding: IFinding;
  isFullscreen: boolean;
  isPreview: boolean;
  imageSize: ImageSize;
  setZoomResetHandler: React.Dispatch<
    React.SetStateAction<IZoomableImageHandler | null>
  >;
};

const FindingImage: React.FC<FindingImageProps> = (props) => {
  const noImageAvailable =
    props.finding.image === null && props.finding.preview === null;

  if (noImageAvailable) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        No image available
      </div>
    );
  }

  if (props.isFullscreen || props.isPreview) {
    // switch to zoomable image if preview or on full screen
    return (
      <div
        style={{ width: props.imageSize.width, height: props.imageSize.height }}
      >
        <ZoomableImage
          className={style.zoomableImage}
          // it guaranteed that either findingImage or previewImage is not null
          imageSource={(props.finding.image || props.finding.preview)!}
          setZoomableImageHandler={props.setZoomResetHandler}
        />
      </div>
    );
  }

  // we have a full res image & we are not on fullscreen mode, allow image annotations
  return (
    <ReactPictureAnnotation
      annotationData={props.finding.annotations ?? []}
      annotationStyle={defaultScoutAnnotationStyle}
      // We are passing the width and height in the image URL to force redraw of the image
      // when the height and width changes. This is a work-around because the ReactPictureAnnotation
      // library doesn't resize the image to fit the given space when the size of the space changes.
      image={`${props.finding.image}#${props.imageSize.height}${props.imageSize.width}${props.isFullscreen}`}
      onSelect={() => {}}
      onChange={() => {}}
      width={props.imageSize.width}
      height={props.imageSize.height}
      defaultAnnotationSize={[100, 100]}
      scrollSpeed={0}
      inputElement={(value, onChange, onDelete) => (
        <CustomInputWithText
          value={value}
          onDelete={onDelete}
          onChange={onChange}
        />
      )}
    />
  );
};

const defaultScoutAnnotationStyle: IShapeStyle = {
  /** text area **/
  padding: 5, // text padding
  fontSize: 12, // text font size
  fontColor: "#fdfdfd", // text background color
  fontBackground: "#6c757d", // text font color
  fontFamily:
    "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial, sans-serif",

  /** stroke style **/
  lineWidth: 2, // stroke width
  shapeBackground: "hsla(210, 16%, 93%, 0.2)", // background color in the middle of the marker
  shapeStrokeStyle: "#f8f9fa", // shape stroke color
  shadowBlur: 10, // stroke shadow blur
  shapeShadowStyle: "hsla(210, 9%, 31%, 0.35)", // shape shadow color

  /** transformer style **/
  transformerBackground: "#ef4036",
  transformerSize: 10,
};

export default FindingImage;
