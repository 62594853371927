import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tooltip,
} from "@fluentui/react-components";
import React from "react";
import { InfoRegular, MoreVerticalFilled } from "@fluentui/react-icons";

type SessionCardMenuProps = {
  onShowDetails: () => void;
};

const SessionCardMenu: React.FC<SessionCardMenuProps> = (props) => {
  return (
    <Menu hasIcons>
      <MenuTrigger disableButtonEnhancement>
        <Tooltip content={"More actions"} relationship="label">
          <Button
            appearance="subtle"
            icon={<MoreVerticalFilled />}
            aria-label="More actions"
          />
        </Tooltip>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuItem icon={<InfoRegular />} onClick={props.onShowDetails}>
            Show details
          </MenuItem>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default SessionCardMenu;
