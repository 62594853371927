import { RouterProvider } from "react-router-dom";

import { useStaticStyles } from "./styles/styles.ts";
import browserRouter from "./router.tsx";
import { ThemeContext } from "./styles/themes.tsx";
import { FluentProvider, Toaster } from "@fluentui/react-components";
import { useContext } from "react";

function App() {
  useStaticStyles();
  const { theme } = useContext(ThemeContext);

  return (
    <FluentProvider theme={theme.fluentTheme}>
      <Toaster />
      <RouterProvider router={browserRouter} />
    </FluentProvider>
  );
}

export default App;
