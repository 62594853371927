import { Outlet, useNavigate, useParams } from "react-router-dom";
import PrivateComponent from "../../../auth/PrivateComponent.tsx";
import React, { useContext, useEffect, useState } from "react";
import ShareHierarchyProvider from "./ShareHierarchyProvider.tsx";
import Navbar from "../../../components/Navbar/Navbar.tsx";
import ActionBarProvider from "../../../components/ActionBarProvider/ActionBarProvider.tsx";
import NavDrawer from "../../../components/NavDrawer/NavDrawer.tsx";
import { ApiContext } from "../../../api/IApiClient.ts";

const Share = () => {
  const { shareId } = useParams();
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);

  const apiClient = useContext(ApiContext);
  const navigate = useNavigate();

  useEffect(() => {
    const errorHandler = (error: Response) => {
      // Basic errors
      if (error.status === 401) {
        navigate({ pathname: "/unauthorized", search: `si=${shareId}` });
      }

      // Share specific errors
      error.json().then((data) => {
        if (data.type !== undefined && data.type === "share") {
          if (error.status === 403) {
            navigate({ pathname: "/forbidden", search: `si=${shareId}` });
          } else if (error.status === 410) {
            navigate({ pathname: "/expired", search: `si=${shareId}` });
          }
        }
      });
    };
    apiClient.addErrorHandler(errorHandler);

    return () => {
      apiClient.removeErrorHandler(errorHandler);
    };
  }, [apiClient, navigate, shareId]);

  const toggleNavDrawer = () => {
    setNavDrawerOpen((prev) => !prev);
  };

  return (
    <PrivateComponent
      loginSearchParams={shareId !== undefined ? `si=${shareId}` : undefined}
    >
      <ShareHierarchyProvider>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100vh",
          }}
        >
          <Navbar onToggleNavDrawer={toggleNavDrawer} />
          <ActionBarProvider>
            <div style={{ flex: 1, display: "flex", overflow: "hidden" }}>
              <NavDrawer open={navDrawerOpen} setOpen={setNavDrawerOpen} />
              <Outlet />
            </div>
          </ActionBarProvider>
        </div>
      </ShareHierarchyProvider>
    </PrivateComponent>
  );
};

export default Share;
