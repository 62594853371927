import {
  fragmentShader,
  Uniforms,
  vertexShader,
} from "./ScoutPointMaterial.glsl";
import { ShaderMaterial, Texture, CanvasTexture } from "three";
import {
  interpolateInferno,
  interpolateMagma,
  interpolatePlasma,
  interpolateRainbow,
  interpolateTurbo,
  interpolateViridis,
} from "d3-scale-chromatic";

export const pointCloudColorPalettes = [
  {
    name: "Turbo",
    offset: 0,
    interpolateFn: interpolateTurbo,
  },
  {
    name: "Viridis",
    offset: 1,
    interpolateFn: interpolateViridis,
  },
  {
    name: "Magma",
    offset: 2,
    interpolateFn: interpolateMagma,
  },
  {
    name: "Inferno",
    offset: 3,
    interpolateFn: interpolateInferno,
  },
  {
    name: "Plasma",
    offset: 4,
    interpolateFn: interpolatePlasma,
  },
  {
    name: "Rainbow",
    offset: 5,
    interpolateFn: interpolateRainbow,
  },
];

export class ScoutPointCloudMaterial extends ShaderMaterial {
  /*
   * LUT texture for colorizing point cloud
   */
  private static lutTexture: Texture =
    ScoutPointCloudMaterial.generateLUTTexture();

  constructor(
    minZ: number,
    maxZ: number,
    viewPortHeight: number,
    settings: {
      pointSize: number;
    },
  ) {
    super();

    this.init(minZ, maxZ, viewPortHeight, settings);
  }

  init(
    minZ: number,
    maxZ: number,
    viewPortHeight: number,
    settings: {
      pointSize: number;
    },
  ) {
    const uniforms: Uniforms = {
      uMaxZ: { value: maxZ },
      uMinZ: { value: minZ },
      uPointSize: { value: settings.pointSize },
      uViewPortHeight: { value: viewPortHeight },
      uOpacity: { value: 1.0 },
      uPaletteTexture: { value: ScoutPointCloudMaterial.lutTexture },
      uColorMode: { value: 1 },
      uPaletteUVOffset: { value: 0.0 },
    };

    this.setValues({
      vertexShader: vertexShader,
      fragmentShader,
      transparent: true,
      uniforms: uniforms as Uniforms,
    });
  }

  private static generateLUTTexture() {
    const width = 256;
    const perPaletteHeight = 4;
    const height = pointCloudColorPalettes.length * perPaletteHeight;
    const canvas = document.createElement(
      "canvas",
    ) as unknown as HTMLCanvasElement;
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

    for (let y = 0; y < pointCloudColorPalettes.length; y++) {
      const interpolator = pointCloudColorPalettes[y].interpolateFn;
      for (let x = 0; x < 256; x++) {
        ctx.fillStyle = interpolator(x / 256);
        ctx.fillRect(x, y * perPaletteHeight, 1, perPaletteHeight);
      }
    }

    return new CanvasTexture(canvas);
  }
}
