import React from "react";
import style from "./company-brand.module.scss";
import { makeStyles, shorthands } from "@fluentui/react-components";

const useStyles = makeStyles({
  companyBrand: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  logo: {
    objectFit: "contain",
    width: "100%",
  },

  poweredBy: {
    color: "white",
    fontSize: "0.65rem",
    fontStyle: "italic",
    alignItems: "center",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    ...shorthands.gap("1ch"),
  },

  img: {
    visibility: "hidden",
    height: "1em",
    width: "auto ", //override NavBarBrand default width
  },
});

interface IProps {
  src: string;
  alt: string;
  showPoweredBy: boolean;
  poweredByColor?: string;
  logoSize?: "small" | "medium" | "large";
}

const CompanyBrand: React.FC<IProps> = (props: IProps) => {
  const styles = useStyles();
  return (
    <div className={styles.companyBrand}>
      <img
        src={props.src}
        alt={props.alt}
        className={styles.logo}
        style={{
          height:
            props.logoSize === "small"
              ? "2.5em"
              : props.logoSize === "medium"
                ? "4em"
                : "6em",
        }}
      />
      {props.showPoweredBy && (
        <div className={styles.poweredBy}>
          <div style={{ color: props.poweredByColor || "white" }}>
            Powered by
          </div>
          <div
            className={style.scoutLogo}
            style={{ backgroundColor: props.poweredByColor || "white" }}
          >
            <img
              className={styles.img}
              src="/logo_without_badge.svg"
              alt="ScoutDI"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyBrand;
