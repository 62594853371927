import React from "react";
import {
  Toolbar,
  ToolbarButton,
  useRestoreFocusTarget,
} from "@fluentui/react-components";
import { Action } from "./ActionBarProvider.tsx";
import useWindowDimensions from "../../hooks/useWindowDimensions.tsx";

type ActionToolbarProps = {
  actions: Action[];
};

const ActionToolbar: React.FC<ActionToolbarProps> = (props) => {
  const restoreFocusTargetAttribute = useRestoreFocusTarget();
  const { width } = useWindowDimensions();
  return (
    <Toolbar style={{ display: "flex", justifyContent: "flex-end" }}>
      {props.actions.map((action, index) => (
        <ToolbarButton
          key={index}
          {...restoreFocusTargetAttribute}
          {...action}
          disabled={action.disabled}
        >
          {width > 900 ? (
            <span style={{ whiteSpace: "nowrap" }}>{action.label}</span>
          ) : null}
        </ToolbarButton>
      ))}
    </Toolbar>
  );
};

export default ActionToolbar;
