import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { IShareInfo } from "scout-sharing-models";
import { ApiContext } from "./api/IApiClient";
import { matchPath } from "react-router-dom";

export const ShareInfoContext = createContext<IShareInfo | null>(null);

const ShareInfoProvider: React.FC<PropsWithChildren> = (props) => {
  const [shareInfo, setShareInfo] = useState<IShareInfo | null>(null);

  const apiClient = useContext(ApiContext);

  const pathShareId = matchPath("/share/:shareId/*", window.location.pathname)
    ?.params.shareId;
  const searchShareId = new URLSearchParams(window.location.search).get("si");
  const si = pathShareId || searchShareId;

  useEffect(() => {
    if (!si) return;
    apiClient.getShareInfo(si).then((shareInfo): void => {
      setShareInfo(shareInfo);
    });
  }, [apiClient, si]);

  return (
    <ShareInfoContext.Provider value={shareInfo}>
      {props.children}
    </ShareInfoContext.Provider>
  );
};

export default ShareInfoProvider;
