import {
  Body1,
  Caption1,
  Card,
  CardHeader,
  CardPreview,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import global_map from "/global_map.png";
import local_map from "/local_map.png";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("10px"),
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  card: {
    ...shorthands.margin("auto"),
    maxWidth: "100%",
    width: "250px",
    height: "220px",
    "@media (max-width: 360px)": {
      width: "200px",
    },
  },
});
export const VisualizationSettings = (props: {
  mode: "local" | "global";
  onSelected: (mode: "local" | "global") => void;
}) => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <Card
        className={styles.card}
        selected={props.mode === "local"}
        onClick={() => props.onSelected("local")}
      >
        <CardHeader
          header={<Body1>Original point clouds</Body1>}
          description={
            <Caption1>Only what is immediately around the drone</Caption1>
          }
        ></CardHeader>
        <CardPreview>
          <img src={local_map} height={200} alt="Original point clouds" />
        </CardPreview>
      </Card>
      <Card
        className={styles.card}
        selected={props.mode === "global"}
        onClick={() => props.onSelected("global")}
      >
        <CardHeader
          header={<Body1>Processed Point clouds</Body1>}
          description={
            <Caption1>
              The lidar data is processed with SLAM algorithms to provide a
              global map of the environment.
            </Caption1>
          }
        />
        <CardPreview>
          <img src={global_map} height={200} alt="Processed point clouds" />
        </CardPreview>
      </Card>
    </div>
  );
};
