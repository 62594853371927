import {
  Button,
  DialogActions,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  tokens,
  Tooltip,
} from "@fluentui/react-components";
import React, { useContext } from "react";
import useWindowDimensions from "../../../../../../../hooks/useWindowDimensions";
import { FindingsDialogControllerContext } from "../FindingsDialogControllerProvider.tsx";

const FindingsDialogFooter: React.FC = () => {
  const { width } = useWindowDimensions();
  const { currentFinding } = useContext(FindingsDialogControllerContext);
  const isPreview =
    currentFinding?.image === null && currentFinding?.preview !== null;
  return (
    <DialogActions
      fluid
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", gap: tokens.spacingHorizontalS }}>
        <Button disabled={true} appearance="outline">
          Delete
        </Button>
        <Button disabled={true} appearance="outline">
          Save
        </Button>
      </div>
      {isPreview && (
        <Tooltip
          content="A full resolution image will be available once this point of interest is fully processed"
          relationship="label"
        >
          <MessageBar intent={"warning"}>
            <MessageBarBody>
              <MessageBarTitle>Preview</MessageBarTitle>
              {width > 1024 &&
                "A full resolution image will be available once this point of interest is fully processed"}
            </MessageBarBody>
          </MessageBar>
        </Tooltip>
      )}
    </DialogActions>
  );
};

export default FindingsDialogFooter;
