import { useEffect } from "react";

type CleanupFn = () => void;
/**
 * A hook that runs a callback on every animation frame while it is enabled.
 *
 * Internally this uses requestAnimationFrame to schedule the [callback].
 * @param enabled schedule the callback if true
 * @param callback the callback to run on every animation frame
 * @param deps array of dependencies that will trigger a cleanup and re-registering of the callback
 */
export const useAnimationLoop = (
  enabled: boolean,
  callback: (isFirstRun: boolean) => CleanupFn | void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  deps?: any[],
) => {
  useEffect(() => {
    let running = enabled;
    let cleanup: CleanupFn | void = undefined;
    if (running) {
      // trigger initial animation frame
      cleanup = callback(true);
    }
    const animationFrame = () => {
      if (running) {
        cleanup = callback(false);
        requestAnimationFrame(animationFrame);
      }
    };

    requestAnimationFrame(animationFrame);

    return () => {
      running = false;
      cleanup?.();
    };

    // It is considered an anti-pattern to include additional dependencies in the depencency array.
    // Some other mechanism should be used to re-register the callback when the dependencies change.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps ?? []), enabled]);
};
