import { IFinding } from "scout-sharing-models";
import { FilterGroup, FilterOption } from "./FindingsFilters";

export type GroupedFindings = { [key: string]: IFinding[] };

export const filterFindings = (
  findings: IFinding[],
  filters: FilterOption[],
) => {
  if (filters.length === 0) return findings;
  return findings.filter((finding) => {
    return filters.every((filter) => filter.predicate(finding));
  });
};

export const groupFindings = (findings: IFinding[], grouping: FilterGroup) => {
  const groupedFindings: GroupedFindings = {};
  Object.entries(grouping.filters).forEach(([, option]) => {
    groupedFindings[option.name] = [];
  });
  findings.forEach((finding) => {
    Object.entries(grouping.filters).forEach(([, option]) => {
      if (option.predicate(finding)) {
        groupedFindings[option.name].push(finding);
      }
    });
  });
  return groupedFindings;
};

export const filterAndGroupFindings = (
  findings: IFinding[],
  filters: FilterOption[],
  grouping: FilterGroup,
) => {
  const filteredFindings = filterFindings(findings, filters);
  return groupFindings(filteredFindings, grouping);
};

export const orderGroupedFindings = (groupedFindings: GroupedFindings) => {
  return Object.entries(groupedFindings).flatMap(([, findings]) => {
    return findings;
  });
};
