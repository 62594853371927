import { IShareInspection } from "scout-sharing-models";

import {
  createTableColumn,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableColumnSizingOptions,
  TableRow,
  useTableColumnSizing_unstable,
  useTableFeatures,
} from "@fluentui/react-components";
import React, { useState } from "react";

type InspectionDatum = {
  key: string;
  description?: string;
};

const columnsDef: TableColumnDefinition<InspectionDatum>[] = [
  createTableColumn({
    columnId: "key",
  }),
  createTableColumn<InspectionDatum>({
    columnId: "description",
  }),
];

type SessionDetailsTableProps = {
  inspection: IShareInspection;
};

const InpsectionDetailsTable: React.FC<SessionDetailsTableProps> = (props) => {
  const [columns] =
    useState<TableColumnDefinition<InspectionDatum>[]>(columnsDef);
  const [columnSizingOptions] = useState<TableColumnSizingOptions>({
    key: {
      idealWidth: 80,
      minWidth: 50,
    },
    description: {
      minWidth: 100,
      defaultWidth: 100,
    },
  });

  const nSessions = props.inspection.sessions?.length || 0;
  const nPOIs = props.inspection.sessions?.reduce(
    (acc, session) => acc + (session.findings?.length || 0),
    0,
  );

  const details: InspectionDatum[] = [
    {
      key: "Sessions",
      description: nSessions.toString(),
    },
    {
      key: "POIs",
      description: nPOIs.toString(),
    },
    {
      key: "Reports",
      description: props.inspection.reports?.length.toString(),
    },
  ];

  const { getRows, columnSizing_unstable, tableRef } = useTableFeatures(
    {
      columns: columns,
      items: details,
    },
    [useTableColumnSizing_unstable({ columnSizingOptions })],
  );

  const rows = getRows();

  return (
    <Table ref={tableRef} {...columnSizing_unstable.getTableProps()}>
      <TableBody>
        {rows.map(({ item }) => (
          <TableRow key={item.key}>
            <TableCell {...columnSizing_unstable.getTableCellProps("key")}>
              <TableCellLayout>{item.key}</TableCellLayout>
            </TableCell>
            <TableCell
              {...columnSizing_unstable.getTableCellProps("description")}
            >
              <TableCellLayout>{item.description}</TableCellLayout>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default InpsectionDetailsTable;
