import React, { useEffect, useState } from "react";

import {
  IChartProps,
  ILineChartProps,
  AreaChart,
  ILineChartDataPoint,
} from "@fluentui/react-charting";
import { DirectionalHint } from "@fluentui/react";
import FluentV9Wrapper from "../../../../../components/utils/FluentV9Wrapper";

export interface IMeasurementAScanProps extends Omit<ILineChartProps, "data"> {
  data: number[];
  samplingRate: number;
  speedOfSound?: number;
}

const MeasurementAScan: React.FC<IMeasurementAScanProps> = ({
  data,
  samplingRate,
  speedOfSound,
  height,
  ...props
}) => {
  const [chartData, setChartData] = useState<IChartProps>({
    chartTitle: "A-scan",
  });

  const usingMillimeters = speedOfSound !== undefined;

  const xAxisUnit = {
    short: usingMillimeters ? "mm" : "µs",
    long: usingMillimeters ? "Millimeters" : "Microseconds",
  };

  useEffect(() => {
    const chartPoints: ILineChartDataPoint[] = data.map((d, i) => ({
      x: usingMillimeters
        ? toMillimeters(i, samplingRate, speedOfSound)
        : toMicroseconds(i, samplingRate),
      y: normalize(d),
    }));

    setChartData((prev: IChartProps) => {
      return {
        ...prev,
        lineChartData: [
          {
            lineOptions: {
              strokeWidth: 2,
            },
            legend: "Amplitude",
            data: chartPoints,
          },
        ],
      };
    });
  }, [data, samplingRate, speedOfSound, usingMillimeters]);

  return (
    <FluentV9Wrapper style={{ height, backgroundColor: "transparent" }}>
      <AreaChart
        enableReflow={true}
        enableGradient={true}
        margins={{ top: 10, right: 0, bottom: 55, left: 35 }}
        hideLegend
        optimizeLargeData
        legendProps={{
          allowFocusOnLegends: true,
        }}
        data={chartData}
        xAxisTitle={xAxisUnit.long}
        yAxisTickFormat={(d: number) => `${d.toFixed(0)}%`}
        yMinValue={0}
        yMaxValue={100}
        height={height! - 105}
        onRenderCalloutPerDataPoint={(data) => (
          <div>
            {data?.x !== undefined &&
              typeof data.x === "number" &&
              data.x.toFixed(2)}{" "}
            {xAxisUnit.short}
          </div>
        )}
        calloutProps={{
          directionalHint: DirectionalHint.topCenter,
        }}
        {...props}
        styles={{ chartWrapper: { overflow: "hidden" } }}
      />
    </FluentV9Wrapper>
  );
};

const toMicroseconds = (i: number, samplingRate: number) =>
  (i / samplingRate) * Math.pow(10, 6);
const toMillimeters = (
  i: number,
  samplingRate: number,
  speedOfSound: number,
) => {
  return (((i / samplingRate) * speedOfSound) / 2) * 1000;
};

/*
Clamp [-2,2] then normalize 0-100.
*/
const normalize = (data: number) => {
  const normalized = Math.min(2, Math.abs(data - 2)) * 50;
  return Math.max(0, Math.min(100, normalized));
};

export default MeasurementAScan;
