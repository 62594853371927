import React from "react";
import {
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerGroup,
  Tag,
} from "@fluentui/react-components";
import { ITag } from "scout-sharing-models";

const TagFlag = (prop: { color: string }) => {
  return (
    <div
      style={{
        backgroundColor: prop.color,
        width: "15px",
        height: "15px",
        borderRadius: "4px",
      }}
    />
  );
};

export const POITagPicker = (props: { tags: ITag[] }) => {
  const selectedTags: ITag[] = props.tags;
  return (
    <TagPicker
      disabled
      onOptionSelect={() => {}}
      selectedOptions={selectedTags.map((tag) => tag.name)}
    >
      <TagPickerControl>
        <TagPickerGroup>
          {selectedTags.map((option) => (
            <Tag
              key={option.name}
              shape="rounded"
              media={<TagFlag color={option.color} />}
              value={option.name}
            >
              {option.name}
            </Tag>
          ))}
        </TagPickerGroup>
        <TagPickerInput aria-label="Select tags" />
      </TagPickerControl>
      <TagPickerList>No tags</TagPickerList>
    </TagPicker>
  );
};
