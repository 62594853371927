import React, { createContext, PropsWithChildren, useState } from "react";
export type User = {
  email: string;
  token: string;
};

export const AuthContext = createContext<{
  user: User | null;
  login: (email: string, token: string) => void;
  logout: () => void;
}>({ user: null, login: () => {}, logout: () => {} });

const getStoredUser = (): User | null => {
  const storedUser = sessionStorage.getItem("user");
  if (storedUser !== null) {
    return JSON.parse(storedUser);
  }
  return null;
};

export const AuthProvider: React.FC<PropsWithChildren> = (props) => {
  const [user, setUser] = useState<User | null>(getStoredUser());

  const login = (email: string, token: string) => {
    sessionStorage.setItem("user", JSON.stringify({ email, token }));
    setUser({ email, token });
  };

  const logout = () => {
    sessionStorage.removeItem("user");
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {props.children}
    </AuthContext.Provider>
  );
};
