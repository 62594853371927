import { Outlet } from "react-router-dom";
import React from "react";
import { makeStyles } from "@fluentui/react-components";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import {
  loadApplicationInsights,
  reactPlugin,
} from "../../applicationInsights";
import { SomethingWentWrongPage } from "../../components/ErrorPages/ErrorPages";

loadApplicationInsights();

const useStyles = makeStyles({
  root: {
    height: "100vh",
  },
});

export const Root: React.FC = () => {
  const styles = useStyles();

  return (
    // Adding error boundary at top level of router as suggested by https://github.com/remix-run/react-router/discussions/10166.
    // This will catch any errors and send them to App Insights.
    // React router has built-in error boundary, which doesn't bubble the errors to app insights outside of the router.
    // Having the AppInsights inside the router also allows for using react router hooks in the error page.
    <AppInsightsErrorBoundary
      appInsights={reactPlugin}
      onError={() => <SomethingWentWrongPage />}
    >
      <div className={styles.root}>
        <Outlet />
      </div>
    </AppInsightsErrorBoundary>
  );
};
