import { tokens } from "@fluentui/react-components";
import { additionalDataInfo } from "../../../../../utils.ts";
import { useEffect, useRef, useState } from "react";

export function Vector(props: {
  direction: "up" | "down" | "front";
  value: number | undefined;
}) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const resizeObserver = useRef(
    new ResizeObserver(() => {
      if (ref.current === null) {
        return;
      }
      const { width, height } = ref.current.getBoundingClientRect();
      setWidth(width);
      setHeight(height);
    }),
  );
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    const ro = resizeObserver.current;
    ro.observe(ref.current);
    return () => {
      ro.disconnect();
    };
  }, [ref]);
  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        height: "100%",
        flexDirection: props.direction === "front" ? "column" : "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Arrow
        direction={props.direction}
        length={(props.direction === "front" ? width : height) * 0.7}
      />
      <div
        style={{
          minWidth: "55px",
        }}
      >
        {additionalDataInfo.distance_up.formatValue(props.value)}
      </div>
    </div>
  );
}

export const Arrow = (props: {
  direction: "up" | "down" | "front";
  length?: number;
}) => {
  const color = tokens.colorNeutralForeground1;
  const length = props.length ?? 200;
  const width = 20;
  const shaftWidth = 4;
  const headWidth = 10;
  const shaftLength = Math.max(length - headWidth, 0);
  if (props.direction === "front") {
    return (
      <svg
        style={{
          alignSelf: "center",
        }}
        width={length}
        height={width}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width={shaftLength}
          height={shaftWidth}
          y={(width - shaftWidth) / 2}
          x={0}
          fill={color}
        />
        <polygon
          fill={color}
          points={`${shaftLength},${width} 
          ${shaftLength},00 
          ${shaftLength + 10},${width / 2}`}
        ></polygon>
      </svg>
    );
  } else if (props.direction === "up") {
    return (
      <svg width={width} height={length} xmlns="http://www.w3.org/2000/svg">
        <rect
          width={shaftWidth}
          height={shaftLength}
          x={(width - shaftWidth) / 2}
          y={headWidth}
          fill={color}
        />
        <polygon fill={color} points="00,10 10,00 20,10"></polygon>
      </svg>
    );
  } else if (props.direction === "down") {
    return (
      <svg width={width} height={length} xmlns="http://www.w3.org/2000/svg">
        <rect
          width={shaftWidth}
          height={shaftLength}
          x={(width - shaftWidth) / 2}
          y={0}
          fill={color}
        />
        <polygon
          fill={color}
          points={`00,${shaftLength} ${width / 2},${
            shaftLength + 10
          } ${width},${shaftLength}`}
        ></polygon>
      </svg>
    );
  } else {
    throw new Error(`Invalid direction ${props.direction}`);
  }
};
