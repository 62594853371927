import { IGlobalMapConfig, Scout3DPlayer } from "./scout3DPlayer.tsx";
import moment from "moment/moment";
import { Body1Stronger, Spinner } from "@fluentui/react-components";
import VirtualSceneHelpDialog from "../../VirtualSceneHelpDialog/VirtualSceneHelpDialog.tsx";
import {
  Action,
  PopOverAction,
  StreamerButtonGroup,
} from "../../streamer-settings/StreamerButtonGroup.tsx";
import {
  BookQuestionMarkFilled,
  MapFilled,
  SlideSettingsFilled,
} from "@fluentui/react-icons";
import { VisualizationSettings } from "../../streamer-settings/modals/VisualizationSettings.tsx";
import { PointCloudSettings } from "../../streamer-settings/modals/PointCloudSettings.tsx";
import React, { useState } from "react";
import {
  IDroneState,
  ILocalizedPOIMeasurement,
} from "../../../../../../interface.ts";
import { DronePathEntry, IPCD, IStaticMapConfig } from "../models.ts";
import { IFinding, ISession } from "scout-sharing-models";
import { Vector3 } from "three";
import { PointCloudAttribute } from "../utils/3dutils.ts";
import { getSessionDurationMinutes } from "../../../../../../utils.ts";

type VirtualScenePaneProps = {
  session: ISession;
  currentDroneState: IDroneState | undefined;
  currentPointCloud: IPCD | undefined;
  findingsToShow: IFinding[];
  localizedMeasurements: ILocalizedPOIMeasurement[];
  dronePath: {
    enabled: boolean;
    path: DronePathEntry[];
  };
  globalMapConfig: IGlobalMapConfig;
  staticMapConfig: IStaticMapConfig;
  hasFlightData: boolean;
  onFindingClick: (id: number) => void;
  onMeasurementClick: (id: number) => void;
  onPathBubbleClick: (time: number) => void;
  initialCameraSubjectPosition: Vector3;
  pointSize: number;
  setPointSize: (pointSize: number) => void;
  pointOpacity: number;
  setPointOpacity: (pointOpacity: number) => void;
  pointCloudAttribute: PointCloudAttribute;
  setPointCloudAttribute: (pointCloudAttribute: PointCloudAttribute) => void;
  pointColorPalette: number;
  setPointColorPalette: (pointColorPalette: number) => void;
  pointCloudAttributes: PointCloudAttribute[];
  toggleVisualizationMode: (mode: "local" | "global") => void;
  globalMapLoaded: boolean;
};

const VirtualScenePane: React.FC<VirtualScenePaneProps> = (props) => {
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  const isSessionLong = (getSessionDurationMinutes(props.session) ?? 0) > 20;

  return (
    <>
      {props.currentDroneState ? (
        <Scout3DPlayer
          dronePose={props.currentDroneState}
          initialCameraSubjectPosition={props.initialCameraSubjectPosition}
          pointCloud={props.currentPointCloud}
          onFindingClick={props.onFindingClick}
          onPathBubbleClick={props.onPathBubbleClick}
          sessionStart={moment(props.session.start_time)}
          findings={props.findingsToShow ?? []}
          localizedMeasurements={props.localizedMeasurements}
          onMeasurementClick={props.onMeasurementClick}
          pointSize={props.pointSize}
          pointOpacity={props.pointOpacity}
          pointCloudAttribute={props.pointCloudAttribute}
          pointColorPalette={props.pointColorPalette}
          globalMap={props.globalMapConfig}
          dronePath={props.dronePath}
          staticMapConfig={props.staticMapConfig}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
          {isSessionLong && (
            <div style={{ marginTop: "10px" }}>
              <Body1Stronger>This may take a moment</Body1Stronger>
            </div>
          )}
        </div>
      )}
      <VirtualSceneHelpDialog
        open={helpDialogOpen}
        setOpen={setHelpDialogOpen}
      />
      <StreamerButtonGroup
        style={{
          position: "absolute",
          bottom: 120,
          right: 20,
          zIndex: 1000,
        }}
      >
        <Action
          toolTip={"Help"}
          icon={<BookQuestionMarkFilled />}
          onClick={() => setHelpDialogOpen(true)}
        />
        {/*<Action toolTip={"Select video analysis"} icon={<EyeLinesFilled />} />*/}
        {(import.meta.env.DEV || props.hasFlightData) && (
          <>
            {(import.meta.env.DEV || props.globalMapConfig.file) !==
              undefined && (
              <PopOverAction
                popOver={
                  <VisualizationSettings
                    mode={props.globalMapConfig.enabled ? "global" : "local"}
                    onSelected={props.toggleVisualizationMode}
                  />
                }
                icon={<MapFilled />}
                disabled={!props.globalMapLoaded}
              />
            )}
            <PopOverAction
              icon={<SlideSettingsFilled />}
              popOver={
                <PointCloudSettings
                  pointSize={props.pointSize}
                  onUpdatePointSize={(value) => props.setPointSize(value)}
                  pointOpacity={props.pointOpacity}
                  onUpdatePointOpacity={(value) => props.setPointOpacity(value)}
                  pointCloudAttribute={props.pointCloudAttribute}
                  setPointCloudAttribute={props.setPointCloudAttribute}
                  pointColorPalette={props.pointColorPalette}
                  setPointColorPalette={(value) =>
                    props.setPointColorPalette(value)
                  }
                  pointCloudAttributes={props.pointCloudAttributes}
                />
              }
              disabled={props.currentDroneState === undefined}
            />
          </>
        )}
      </StreamerButtonGroup>
    </>
  );
};

export default VirtualScenePane;
