import { Moment } from "moment";
import * as THREE from "three";
import {
  IFindingAdditionalData,
  IFindingSeverity,
  IFindingType,
  IPOIMeasurement,
  IPointCloudModel,
  IReactPictureAnnotationObject,
  IReport,
  ISession,
} from "scout-sharing-models";

export interface IGroup {
  id: number;
  name: string;
  logo: string | null;
}
export interface IAsset {
  id: string;
  name: string;
  description: string | null;
  url: string | null;
  image: string | null;
}

export interface ISimpleNestedObject {
  id: string;
  name: string;
}

export interface ISimpleNestedAsset extends ISimpleNestedObject {
  targets: ISimpleNestedTarget[];
}

export interface ICreateAsset {
  name: string;
  description: string;
  url: string;
  image: unknown | undefined;
}

export interface ILiveTarget {
  id: string;
  name: string;
  inspections: ILiveInspection[];
}

export interface ITarget {
  id: string;
  name: string;
  asset: string;
  description: string | null;
  url: string | null;
  image: string | null;
  static_model: IStaticMapModel | null;
  pcd_model: string;
}

export interface ISimpleNestedTarget extends ISimpleNestedObject {
  inspections: ISimpleInspection[];
}

export interface IInspection extends ISimpleInspection {
  target: string;
}

export interface ILiveInspection {
  id: string;
  name: string;
  session_set: ISession[];
}

export interface IInspectionMutate {
  name: string;
  description: string;
  target: string;
}

export interface ISimpleInspection extends ISimpleNestedObject {
  description: string | null;
}

export enum FindingSeverity {
  NOT_APPLICABLE = 4,
}

export interface ISessionMutate {
  replay_delay: number;
}

export interface IVisualizationDto {
  id: string;
  session: string | null;
  coordinate_system: string;
  name: string | null;
  created: string;
}

export interface IVisualization {
  id: string;
  session: string | null;
  created: Moment;
  coordinate_system: string;
  name: string | null;
  point_clouds: IPointCloudModel[];
}

export interface ISASToken {
  storage_account: string;
  container: string;
  token: string;
  session_id: string;
}

export interface IVisualizationUploadResponse {
  storage_account: string;
  container: string;
  token: string;
  upload_container_id: string;
}

export interface IStaticMapModel {
  id: string;
  name: string;
  model_file: string;
  artificial_lighting: boolean;
  position_x: number;
  position_y: number;
  position_z: number;
  scale_x: number;
  scale_y: number;
  scale_z: number;
  rotation_x: number;
  rotation_y: number;
  rotation_z: number;
}

export interface ICreatePointCloudModel {
  session: string;
  timestamp: string;
  file: string;
}

export interface IFindingSeverityOption {
  value: IFindingSeverity;
  label: string;
}

export interface IFindingTypeOption {
  value: IFindingType;
  label: string;
}

export interface IFindingMutate {
  id?: number;
  session: string;
  description: string | null;
  severity: number | null;
  type: number | null;
  time: string | null;
  position_text: string | null;
  position_x?: number | null;
  position_y?: number | null;
  position_z?: number | null;
  orientation_x?: number | null;
  orientation_y?: number | null;
  orientation_z?: number | null;
  orientation_w?: number | null;
  camera_orientation_x?: number | null;
  camera_orientation_y?: number | null;
  camera_orientation_z?: number | null;
  camera_orientation_w?: number | null;
  camera_position_x?: number | null;
  camera_position_y?: number | null;
  camera_position_z?: number | null;
  additional_data?: IFindingAdditionalDataMutate;
  include_in_report: boolean;
  preview_base64?: string;
  annotations: IReactPictureAnnotationObject[] | null;
  flagged: boolean;
}

export interface IFindingWithIdMutate extends IFindingMutate {
  id: number;
}

export interface IDroneState {
  readonly time: Moment;
  readonly relativeTime: number; // todo add units to the name?
  readonly state: IDroneAttitude & { additional_data: IFindingAdditionalData };
  // todo add dronePose, cameraPose and additional Data here as top level properties rather than nested in state property
}

export interface IDroneAttitude {
  readonly position: THREE.Vector3;
  readonly orientation: THREE.Quaternion;
  readonly camera_position: THREE.Vector3;
  readonly camera_orientation: THREE.Quaternion;
}

export interface IFindingAdditionalDataExtended extends IFindingAdditionalData {
  camera_pitch?: number;
}

export interface IFindingAdditionalDataMutate {
  distance_up?: number;
  distance_down?: number;
  distance_front?: number;
  lights_right_top?: number;
  lights_right_middle?: number;
  lights_right_bottom?: number;
  lights_left_bottom?: number;
  lights_left_middle?: number;
  lights_left_top?: number;
}

export interface IDroneStateDto {
  timestamp: string | null;
  position_x: number;
  position_y: number;
  position_z: number;
  orientation_x: number;
  orientation_y: number;
  orientation_z: number;
  orientation_w: number;
  camera_orientation_x: number;
  camera_orientation_y: number;
  camera_orientation_z: number;
  camera_orientation_w: number;
  additional_data?: IFindingAdditionalData | null;
}

export interface ITimeDepTransform {
  time: Moment;
  relativeTime: number;
  position_transform: THREE.Vector3;
  orientation_transform: THREE.Quaternion;
  is_identity?: boolean;
}

export interface IDownloadLink {
  url: string;
}

export interface IInitState {
  VOD_STREAM_ADRESS: string;
  WOWZA_WEBSOCKET: string;
  WOWZA_WEBRTC_APPLICATION: string;
  IS_PRODUCTION: boolean;
}

export interface ISessionShareResponse {
  expires_on: Moment;
  link: string;
}

export interface ISessionSharedConsumeResponse {
  api_token: string;
  name: string;
  session: ISession;
  is_temporary: boolean;
  expires_on: Moment;
}

export interface IDrone {
  id: string;
  name: string;
  token: string;
  token_created: Moment;
}

export interface ICreateDrone {
  name: string;
}

export interface IReportTemplate {
  id: string;
  name: string;
  file: string;
  last_edited: string;
  group: number | null;
}

export interface ICreateReportTemplate {
  name: string;
  file: string;
}

export interface IPaginatedReports {
  count: number;
  next: string | null;
  previous: string | null;
  results: IReport[];
}

export enum FileStatusEnum {
  None,
  Pending,
  Ready,
}

export interface IBoundingBox {
  id: number;
  label: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface IDetectionHistogram {
  id: number;
  categoryColor: string;
  bins: IDetectionHistogramBin[];
}

export interface IDetectionHistogramBin {
  time: number;
  boxes: number;
}

export interface IVideoAnalysisFrames {
  time: number;
  area: number;
  boxes: IBoundingBox[];
}

export interface IVideoAnalysis {
  id: string;
  name: string;
  file: string | null;
  inference_type: VideoAnalysisInferenceType;
  status: VideoAnalysisStatus;
  histogram: IDetectionHistogram[] | string | null;
  prediction_model: string;
}

export enum VideoAnalysisInferenceType {
  OBJECT_DETECTION = "object_detection",
}

export enum VideoAnalysisStatus {
  CREATED = "Created",
  PROCESSING = "Processing",
  COMPLETED = "Completed",
}

export interface IPredictionStatus {
  steps: IPredictionSteps;
  total_batches: number;
  finished_batches: number;
}

interface IPredictionSteps {
  prepare?: string;
  predict?: string;
  finalize?: string;
}

export interface IPredictionModel {
  id: string;
  name: string;
  description: string;
  pipeline_endpoint: string;
  ml_model_name: string;
  fps: number;
  max_nodes: number;
}

export interface IPredictionModelParams {
  model_load_name: string;
  video_url: string;
  videoanalysis_id: string;
  msec_skip: number;
  node_count: number;
  calculate_area: string;
}

export interface ICreateVideoAnalysis {
  name: string;
  session: string;
  prediction_model: string;
}

export interface IDeletionConsequences {
  has_consequence: boolean;
  target_count: number;
  inspection_count: number;
  session_count: number;
}

export enum ExternalJobStatus {
  CREATED = "Created",
  RUNNING = "Running",
  FAILED = "Failed",
  SUCCEEDED = "Succeeded",
}

export enum ExternalJobType {
  GENERIC = "Generic",
  SLAM_POST_PROCESSING = "SlamPostProcessing",
}

export enum UploadType {
  VIDEO = "Video",
  DETAILED_DATA = "Detailed data",
}

export interface ISlamPostProcessingJobParameters {
  sessionId: string;
}

export interface ISessionProcessingJobParameters {
  sessionId: string;
  inspectionId: string;
}

export interface ISlamPostProcessingJobInfo {
  image_used: string | undefined;
  visualization_id: string | undefined;
}

export interface IExternalJob {
  id: string;
  start_time: Moment | null;
  end_time: Moment | null;
  status: ExternalJobStatus;
  type: ExternalJobType;
  name: string;
  parameters:
    | ISlamPostProcessingJobParameters
    | ISessionProcessingJobParameters
    | null;
  progress_description: string | null;
  latest_progress: number | undefined;
  progress_status: "running" | "completed" | "failure" | undefined;
  info: ISlamPostProcessingJobInfo | null;
}

export interface IExternalJobMutate {
  status: ExternalJobStatus;
  type: ExternalJobType;
  name: string;
  parameters: ISlamPostProcessingJobParameters | null;
}

export interface ILocalizedPOIMeasurement {
  measurement: IPOIMeasurement;
  droneState: IDroneState;
}

export interface IAdditionalDataInfo {
  description: string;
  unit: string;
  formatValue: (
    value: number | undefined | null,
    defaultText?: string,
  ) => string;
}

export const enum SessionStatus {
  CREATED = "Created",
  LIVE = "Live",
  LIVE_PROCESSING = "LiveProcessing",
  LIVE_COMPLETED = "LiveCompleted",
  PREPARED = "Prepared",
  TRANSCODING = "Transcoding",
  COMPLETED = "Completed",
}
