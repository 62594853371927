import { ISession } from "scout-sharing-models";

import {
  createTableColumn,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableColumnSizingOptions,
  TableRow,
  useTableColumnSizing_unstable,
  useTableFeatures,
} from "@fluentui/react-components";
import React, { useState } from "react";
import { getSessionDuration } from "../../../../../../utils.ts";
import moment from "moment";

type SessionDatum = {
  key: string;
  description?: string;
};

const columnsDef: TableColumnDefinition<SessionDatum>[] = [
  createTableColumn({
    columnId: "key",
  }),
  createTableColumn<SessionDatum>({
    columnId: "description",
  }),
];

type SessionDetailsTableProps = {
  session: ISession;
};

export const SessionDetailsTable: React.FC<SessionDetailsTableProps> = (
  props,
) => {
  const [columns] = useState<TableColumnDefinition<SessionDatum>[]>(columnsDef);
  const [columnSizingOptions] = useState<TableColumnSizingOptions>({
    key: {
      idealWidth: 80,
      minWidth: 50,
    },
    description: {
      minWidth: 100,
      defaultWidth: 100,
    },
  });

  const details: SessionDatum[] = [
    {
      key: "Name",
      description: props.session.title || "Unamed Session",
    },
    {
      key: "Duration",
      description: getSessionDuration(props.session),
    },
    {
      key: "Start time",
      description: moment(props.session.start_time).format("LLL"),
    },
    {
      key: "End time",
      description: moment(props.session.end_time).format("LLL"),
    },
    {
      key: "POIs",
      description: props.session.findings?.length.toString(),
    },
  ];

  const { getRows, columnSizing_unstable, tableRef } = useTableFeatures(
    {
      columns: columns,
      items: details,
    },
    [useTableColumnSizing_unstable({ columnSizingOptions })],
  );

  const rows = getRows();

  return (
    <Table
      ref={tableRef}
      {...columnSizing_unstable.getTableProps()}
      size={"extra-small"}
    >
      <TableBody>
        {rows.map(({ item }) => (
          <TableRow key={item.key}>
            <TableCell {...columnSizing_unstable.getTableCellProps("key")}>
              <TableCellLayout>{item.key}</TableCellLayout>
            </TableCell>
            <TableCell
              {...columnSizing_unstable.getTableCellProps("description")}
            >
              <TableCellLayout>{item.description}</TableCellLayout>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
