import {
  Button,
  ButtonProps,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Tooltip,
} from "@fluentui/react-components";
import { CSSProperties, JSX, PropsWithChildren } from "react";

type PopOverActionProps = ButtonProps & { popOver: JSX.Element };

export const PopOverAction = (props: PopOverActionProps) => {
  return (
    <Popover
      withArrow
      openOnHover={true}
      mouseLeaveDelay={100}
      positioning={"before-top"}
    >
      <PopoverTrigger disableButtonEnhancement>
        <Button size={"large"} {...props}></Button>
      </PopoverTrigger>
      <PopoverSurface tabIndex={-1}>{props.popOver}</PopoverSurface>
    </Popover>
  );
};

type ActionProps = ButtonProps & { toolTip: JSX.Element | string };

export const Action = (props: ActionProps) => {
  return (
    <Tooltip
      content={props.toolTip}
      relationship={"description"}
      positioning={"before"}
    >
      <Button size={"large"} {...props} />
    </Tooltip>
  );
};

export const StreamerButtonGroup = (
  props: PropsWithChildren<{
    style: CSSProperties;
  }>,
) => {
  return (
    <div
      style={{
        ...props.style,
        display: "flex",
        flexDirection: "column",
        gap: "3px",
      }}
    >
      {props.children}
    </div>
  );
};
