import {
  Overflow,
  OverflowItem,
  Tab,
  TabList,
  tokens,
} from "@fluentui/react-components";
import React, { JSX, ReactElement, useEffect, useState } from "react";
import {
  CustomOverflowItem,
  OverflowMenu,
} from "../../../../../components/Overflow/Overflow";

export type Tab = {
  tabId: string;
  disabled: boolean;
  tabName: string;
  tabIcon: ReactElement;
  tabContent: JSX.Element | null;
};

type TabGroupProps = {
  tabs: Tab[];
  activeTabId: string;
};
export const TabGroup = (props: TabGroupProps) => {
  const [activeTab, setActiveTab] = useState(props.activeTabId);

  const items: CustomOverflowItem[] = props.tabs.map((tab, index) => {
    return {
      key: index,
      item: tab.tabName,
      itemProps: {
        icon: tab.tabIcon,
        onClick: () => setActiveTab(tab.tabId),
        disabled: tab.disabled,
      },
    };
  });

  useEffect(() => {
    setActiveTab(props.activeTabId);
  }, [props.activeTabId]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Overflow>
        <TabList
          size={"small"}
          selectedValue={activeTab}
          style={{
            width: "100%",
            backgroundColor: tokens.colorNeutralBackground3,
          }}
        >
          {props.tabs.map((tab, index) => {
            return (
              <OverflowItem
                key={index}
                id={index.toString()}
                groupId={tab.tabId}
                priority={tab.tabId === activeTab ? 2 : 1}
              >
                <Tab
                  disabled={tab.disabled}
                  icon={tab.tabIcon}
                  value={tab.tabId}
                  onClick={() => setActiveTab(tab.tabId)}
                >
                  {tab.tabName}
                </Tab>
              </OverflowItem>
            );
          })}
          <OverflowMenu items={items} />
        </TabList>
      </Overflow>
      {props.tabs.find((t) => t.tabId === activeTab)?.tabContent}
    </div>
  );
};
