import * as THREE from "three";
import {
  IAdditionalDataInfo,
  IDroneAttitude,
  IFindingAdditionalDataExtended,
} from "./interface.ts";
import {
  CAMERA_PITCH_DEFAULT_TEXT,
  DISTANCE_DEFAULT_TEXT,
  LIGHTS_DEFAULT_TEXT,
} from "./constants.ts";
import moment, { Moment } from "moment";
import { ISession } from "scout-sharing-models";
import FileSaver from "file-saver";

interface IOrientation {
  orientation_x?: number | null;
  orientation_y?: number | null;
  orientation_z?: number | null;
  orientation_w?: number | null;
  camera_orientation_x?: number | null;
  camera_orientation_y?: number | null;
  camera_orientation_z?: number | null;
  camera_orientation_w?: number | null;
}

function getDroneOrientation(
  object: IOrientation,
):
  | undefined
  | { orientation: THREE.Quaternion; camera_orientation: THREE.Quaternion } {
  const check = <T>(value: T) => value !== undefined && value !== null;
  if (
    check(object.orientation_x) &&
    check(object.orientation_y) &&
    check(object.orientation_w) &&
    check(object.orientation_z) &&
    check(object.camera_orientation_x) &&
    check(object.camera_orientation_y) &&
    check(object.camera_orientation_z) &&
    check(object.camera_orientation_w)
  ) {
    return {
      orientation: new THREE.Quaternion(
        object.orientation_x!,
        object.orientation_y!,
        object.orientation_z!,
        object.orientation_w!,
      ),
      camera_orientation: new THREE.Quaternion(
        object.camera_orientation_x!,
        object.camera_orientation_y!,
        object.camera_orientation_z!,
        object.camera_orientation_w!,
      ),
    };
  }
  return undefined;
}

export const getCameraPitchInRadians = (
  droneOrientation: Pick<IDroneAttitude, "orientation" | "camera_orientation">,
): number | undefined => {
  if (droneOrientation.camera_orientation !== undefined) {
    if (
      droneOrientation.camera_orientation.x &&
      droneOrientation.camera_orientation.y &&
      droneOrientation.camera_orientation.z &&
      droneOrientation.camera_orientation.w
    ) {
      const Q_drone = new THREE.Quaternion(
        droneOrientation.orientation.x,
        droneOrientation.orientation.y,
        droneOrientation.orientation.z,
        droneOrientation.orientation.w,
      );
      const R_drone = new THREE.Matrix4().makeRotationFromQuaternion(Q_drone);
      const Q_cam = new THREE.Quaternion(
        droneOrientation.camera_orientation.x,
        droneOrientation.camera_orientation.y,
        droneOrientation.camera_orientation.z,
        droneOrientation.camera_orientation.w,
      );
      const R_cam = new THREE.Matrix4().makeRotationFromQuaternion(Q_cam);
      const R_total = new THREE.Matrix4().multiplyMatrices(R_drone, R_cam);

      // Calculate Euler angles in yaw-pitch-roll order. This should allow us to extract the pitch component
      // without ending up with a weird value due to the order of Euler rotations. This works because we
      // don't expect the drone to roll upside down anytime soon.
      //
      // In the drone's frame of reference this will be the Z-Y-X axis order.
      const euler_total = new THREE.Euler().setFromRotationMatrix(
        R_total,
        "ZYX",
      );
      return euler_total.y;
    }
  } else {
    return undefined;
  }
};

export const radiansToDegrees = (radians: number): number =>
  (radians * 180) / Math.PI;

export const getCameraPitchInDegrees = (
  object: IOrientation,
): number | undefined => {
  const droneOrientation = getDroneOrientation(object);
  if (droneOrientation !== undefined) {
    let cameraPitch = getCameraPitchInRadians(droneOrientation);
    if (cameraPitch !== undefined) {
      cameraPitch = radiansToDegrees(cameraPitch);
    }
    // Negate camera pitch to make down negative and up positive.
    return cameraPitch !== undefined ? Math.floor(-cameraPitch) : undefined;
  }
  return undefined;
};

const formatDistance = (
  distance: number | undefined | null,
  defaultText?: string,
) => {
  if (distance === undefined || distance === null) {
    return defaultText ?? DISTANCE_DEFAULT_TEXT;
  }
  return `${distance.toFixed(2)} m`;
};

const formatCameraPitch = (
  pitch: number | undefined | null,
  defaultText?: string,
) => {
  if (pitch === undefined || pitch === null) {
    return defaultText ?? CAMERA_PITCH_DEFAULT_TEXT;
  }
  // Use Math.round to avoid showing -0.0 when pitch is very small negative numbers
  return `${Math.round(pitch)}\u00B0`; // \u00B0 is the degree symbol
};

const formatLightsPercentage = (
  percentage: number | undefined | null,
  defaultText?: string,
) => {
  if (percentage === undefined || percentage === null) {
    return defaultText ?? LIGHTS_DEFAULT_TEXT;
  } else if (percentage < 1) {
    return "OFF";
  }

  return `${percentage.toFixed(0)} %`;
};

export const additionalDataInfo: {
  [key in keyof IFindingAdditionalDataExtended]-?: IAdditionalDataInfo;
} = {
  distance_up: {
    description: "Distance up",
    unit: "m",
    formatValue: formatDistance,
  },
  distance_down: {
    description: "Distance down",
    unit: "m",
    formatValue: formatDistance,
  },
  distance_front: {
    description: "Distance front",
    unit: "m",
    formatValue: formatDistance,
  },
  camera_pitch: {
    description: "Camera pitch",
    unit: "\u00B0",
    formatValue: formatCameraPitch,
  },
  lights: {
    description: "Lights",
    unit: "%",
    formatValue: formatLightsPercentage,
  },
};

export const sleepMs = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const formatFindingTime = (seconds: number): string => {
  const milliseconds = Math.round(seconds * 1000);
  return moment.utc(milliseconds).format("HH:mm:ss.SSS");
};

export const formatFindingTimeMoment = (moment: Moment): string => {
  return moment.format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ");
};

const parseSessionDuration = (
  duration: string,
): { hours: number; minutes: number; seconds: number } => {
  const [hours, minutes, seconds] = duration
    .split(":")
    .map((s: string) => s.split("."))
    .flat()
    .map((n: string) => parseInt(n, 10));

  return { hours, minutes, seconds };
};

export const getSessionDuration = (session: ISession): string => {
  if (!session.duration) {
    return "NA";
  }

  const { hours, minutes, seconds } = parseSessionDuration(session.duration);

  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  } else if (minutes > 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
};

export const getSessionDurationMinutes = (session: ISession): number | null => {
  if (!session.duration) {
    return null;
  }

  const { hours, minutes, seconds } = parseSessionDuration(session.duration);
  return hours * 60 + minutes + seconds / 60;
};

export const downloadFile = (
  url: string,
  filename: string,
  onReject?: () => void,
) => {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      FileSaver.saveAs(blob, filename);
    })
    .catch(onReject);
};
