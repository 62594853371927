import {
  TreeOpenChangeData,
  TreeOpenChangeEvent,
} from "@fluentui/react-components";
import * as React from "react";
import {
  Tree,
  TreeItem,
  TreeItemLayout,
  TreeItemValue,
} from "@fluentui/react-components";
import {
  DocumentFolderRegular,
  FolderFilled,
  FolderRegular,
} from "@fluentui/react-icons";
import { useContext } from "react";
import { ShareHierarchyContext } from "../../../ShareHierarchyProvider.tsx";

type HierarchyProps = {
  onClickInspection: (inspectionId: string) => void;
};

const Hierarchy: React.FC<HierarchyProps> = (props) => {
  const [openItems, setOpenItems] = React.useState<TreeItemValue[]>([]);

  const { hierarchy } = useContext(ShareHierarchyContext);

  const handleOpenChange = (
    _: TreeOpenChangeEvent,
    data: TreeOpenChangeData,
  ) => {
    setOpenItems((curr) =>
      data.open
        ? [...curr, data.value]
        : curr.filter((value) => value !== data.value),
    );
  };

  return (
    <Tree
      aria-label="Expand Icon"
      openItems={openItems}
      onOpenChange={handleOpenChange}
      style={{ height: "100%", overflow: "auto" }}
    >
      {hierarchy?.assets.map((asset) => (
        <TreeItem
          itemType="branch"
          value={asset.id}
          key={"asset-branch" + asset.id}
        >
          <TreeItemLayout
            // style={{
            //   backgroundColor:
            //     asset.id === selectedResource?.asset.id &&
            //     !openItems.includes(asset.id)
            //       ? tokens.colorNeutralBackground2
            //       : "",
            // }}
            expandIcon={
              openItems.includes(asset.id) ? (
                <FolderRegular />
              ) : (
                <FolderFilled />
              )
            }
          >
            {asset.name}
          </TreeItemLayout>
          <Tree>
            {asset.targets.map((target) => (
              <TreeItem
                key={"target-branch" + target.id}
                itemType="branch"
                value={target.id}
              >
                <TreeItemLayout
                  // style={{
                  //   backgroundColor:
                  //     target.id === selectedResource?.target.id &&
                  //     !openItems.includes(target.id)
                  //       ? tokens.colorNeutralBackground2
                  //       : "",
                  // }}
                  expandIcon={
                    openItems.includes(target.id) ? (
                      <FolderRegular />
                    ) : (
                      <FolderFilled />
                    )
                  }
                >
                  {target.name}
                </TreeItemLayout>
                <Tree>
                  {target.inspections.map((inspection) => (
                    <TreeItem
                      key={"inspection-leaf" + inspection.id}
                      itemType="leaf"
                      onClick={() => props.onClickInspection(inspection.id)}
                    >
                      <TreeItemLayout
                        // style={{
                        //   backgroundColor:
                        //     inspection.id === selectedResource?.inspection.id
                        //       ? tokens.colorNeutralBackground2
                        //       : "",
                        // }}
                        expandIcon={<DocumentFolderRegular />}
                      >
                        {inspection.name}
                      </TreeItemLayout>
                    </TreeItem>
                  ))}
                </Tree>
              </TreeItem>
            ))}
          </Tree>
        </TreeItem>
      ))}
    </Tree>
  );
};

export default Hierarchy;
