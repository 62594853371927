import {
  MenuList,
  MenuItemRadio,
  MenuGroup,
  Menu,
  MenuPopover,
  MenuTrigger,
  Button,
  MenuProps,
} from "@fluentui/react-components";
import React, { useContext, useEffect, useState } from "react";
import { GroupList24Regular } from "@fluentui/react-icons";
import { ShareHierarchyContext } from "../../../ShareHierarchyProvider.tsx";
import useWindowDimensions from "../../../../../../hooks/useWindowDimensions.tsx";
import FindingsFilters, { FilterGroup } from "./FindingsFilters.tsx";

type GroupingMenuProps = {
  setSelectedGrouping: (grouping: FilterGroup) => void;
};

const GroupingMenu: React.FC<GroupingMenuProps> = ({ setSelectedGrouping }) => {
  const [checkedValues, setCheckedValues] = useState<Record<string, string[]>>({
    flagged: ["flagged"],
  });
  const { state } = useContext(ShareHierarchyContext);
  const { width } = useWindowDimensions();
  const onChange: MenuProps["onCheckedValueChange"] = (
    _,
    { name, checkedItems },
  ) => {
    setCheckedValues(() => {
      return { [name]: checkedItems };
    });
  };

  useEffect(() => {
    setSelectedGrouping(FindingsFilters[Object.keys(checkedValues)[0]]);
  }, [checkedValues, setSelectedGrouping]);

  return (
    <Menu checkedValues={checkedValues} onCheckedValueChange={onChange}>
      <MenuTrigger disableButtonEnhancement>
        <Button
          appearance={"transparent"}
          icon={<GroupList24Regular />}
          disabled={state !== "initialized"}
        >
          {width > 900 ? "Group" : null}
        </Button>
      </MenuTrigger>
      <MenuPopover>
        <MenuList>
          <MenuGroup>
            {Object.entries(FindingsFilters).map(([groupKey, filterGroup]) => {
              return (
                <MenuItemRadio name={groupKey} value={groupKey} key={groupKey}>
                  {filterGroup.groupName}
                </MenuItemRadio>
              );
            })}
          </MenuGroup>
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default GroupingMenu;
