import {
  Dropdown,
  Label,
  Slider,
  Subtitle2,
  Option,
} from "@fluentui/react-components";
import { useEffect, useId, useState } from "react";
import { PointCloudAttribute } from "../../3dView/utils/3dutils.ts";
import { pointCloudColorPalettes } from "../../3dView/materials/ScoutPointCloudMaterial.ts";

const Step = (props: {
  label: string;
  value: number;
  min: number;
  max: number;
  step?: number;
  scale?: number;
  onUpdate: (value: number) => void;
}) => {
  const id = useId();
  return (
    <>
      <Label htmlFor={id}>
        {props.label} {/*[{props.value}]*/}
      </Label>
      <Slider
        onChange={(_, data) => props.onUpdate(data.value * (props.scale ?? 1))}
        value={props.value}
        min={props.min}
        max={props.max}
        step={props.step}
        id={id}
      />
    </>
  );
};

export const PointCloudSettings = (props: {
  pointSize: number;
  onUpdatePointSize: (value: number) => void;
  pointOpacity: number;
  onUpdatePointOpacity: (value: number) => void;
  pointCloudAttribute: PointCloudAttribute;
  setPointCloudAttribute: (pointCloudAttribute: PointCloudAttribute) => void;
  pointColorPalette: number;
  setPointColorPalette: (pointColorPalette: number) => void;
  pointCloudAttributes: PointCloudAttribute[];
}) => {
  return (
    <div
      style={{
        width: "250px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Subtitle2>Point settings</Subtitle2>
      <Step
        label={"Point size"}
        value={props.pointSize * 1000}
        min={1}
        max={100}
        scale={0.001}
        onUpdate={props.onUpdatePointSize}
      />
      <Step
        label={"Point opacity"}
        value={Math.round(props.pointOpacity * 100)}
        min={10}
        max={100}
        onUpdate={(value) => props.onUpdatePointOpacity(value / 100)}
      />
      <Label>Coloring mode</Label>
      <Dropdown
        value={props.pointCloudAttribute.name}
        selectedOptions={[props.pointCloudAttribute.name]}
      >
        {props.pointCloudAttributes.map((a) => (
          <Option
            value={a.name}
            key={"color-mode-" + a.code}
            onClick={() => {
              props.setPointCloudAttribute(a);
            }}
          >
            {a.name}
          </Option>
        ))}
      </Dropdown>
      <Label>Color palette</Label>
      <Dropdown
        value={pointCloudColorPalettes[props.pointColorPalette].name}
        selectedOptions={[
          pointCloudColorPalettes[props.pointColorPalette].name,
        ]}
      >
        {pointCloudColorPalettes.map((p) => (
          <Option
            text={p.name}
            value={p.name}
            key={"color-palette-" + p.offset}
          >
            <PaletteDropdownItem
              onClick={() => {
                props.setPointColorPalette(p.offset);
              }}
              interpolateFn={p.interpolateFn}
              name={p.name}
            />
          </Option>
        ))}
      </Dropdown>
    </div>
  );
};

const PaletteDropdownItem = (props: {
  onClick: () => void;
  interpolateFn: (t: number) => string;
  name: string;
}) => {
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    const imageUrl = renderPaletteToPng(props.interpolateFn);
    setImageUrl(imageUrl);
  }, [props.name, props.interpolateFn]);
  return (
    <div onClick={props.onClick}>
      <span
        style={{
          minWidth: "60px",
        }}
      >
        {props.name}
      </span>
      <img
        style={{
          width: "100%",
          height: "20px",
          borderRadius: "5px",
          marginRight: "10px",
        }}
        src={imageUrl}
        alt={`${props.name} palette`}
      />
    </div>
  );
};

/**
 * Builds a PNG image as a data URL from a color palette interpolator function
 *
 * @param colorPaletteInterpolatorFn
 * @param width
 * @param height
 */
const renderPaletteToPng = (
  colorPaletteInterpolatorFn: (x: number) => string,
  { width, height } = {
    width: 256,
    height: 4,
  },
): string => {
  const canvas = document.createElement(
    "canvas",
  ) as unknown as HTMLCanvasElement;

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  for (let x = 0; x < 256; x++) {
    ctx.fillStyle = colorPaletteInterpolatorFn(x / 256);
    ctx.fillRect(x, 0, 1, height);
  }

  return canvas.toDataURL("image/png");
};
