import {
  Button,
  Card,
  Input,
  makeStyles,
  tokens,
  Link,
  shorthands,
} from "@fluentui/react-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  ArrowLeftRegular,
  CheckmarkRegular,
  MailRegular,
  SendRegular,
} from "@fluentui/react-icons";
import { ApiContext } from "../../../api/IApiClient.ts";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import { AuthContext } from "../../../auth/AuthProvider.tsx";
import useBrandLogo from "../../../components/CompanyBrand/useBrandLogo.tsx";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  card: {
    minWidth: "350px",
    maxWidth: "500px",
    width: "100%",
    height: "460px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: tokens.colorNeutralBackground2,
    "@media (max-width: 620px)": {
      backgroundColor: tokens.colorNeutralBackground1,
      height: "100vh",
      maxWidth: "100vw",
    },
  },
  innerCard: {
    maxHeight: "450px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
    paddingBottom: tokens.spacingVerticalXXXL,
    paddingTop: tokens.spacingVerticalXXXL,
    ...shorthands.gap(tokens.spacingHorizontalXXXL),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "100%",
    ...shorthands.gap(tokens.spacingHorizontalXXXL),
  },
  form: {
    display: "inherit",
    flexDirection: "inherit",
    justifyContent: "inherit",
    alignItems: "inherit",
    ...shorthands.gap("inherit"),
    width: "100%",
  },
  logo: {
    width: "250px",
  },
  input: {
    ...shorthands.borderRadius("8px"),
    width: "50px",
    height: "50px",
    fontSize: "50px",
    textAlign: "center",
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.borderStyle("solid"),
    color: tokens.colorNeutralForeground1,
    caretColor: "transparent",
    "::selection": {
      backgroundColor: "transparent",
    },
  },
  text: {
    textAlign: "center",
  },
});

type Data = {
  shareId: string;
  email: string;
  totp: string;
  token: string;
};

type VertificationStepProps = {
  data: Data;
  setData: (data: Data) => void;
  onFinished?: () => void;
  onFailed?: () => void;
};

const EmailStep = (props: VertificationStepProps) => {
  const styles = useStyles();
  const apiClient = useContext(ApiContext);
  const [failed, setFailed] = useState(false);

  const onSubmit = useCallback(
    (event: React.BaseSyntheticEvent) => {
      event.preventDefault();
      apiClient
        .requestTotp(props.data.shareId, props.data.email)
        .then(props.onFinished)
        .catch(() => setFailed(true));
    },
    [apiClient, props.data.email, props.data.shareId, props.onFinished],
  );

  const renderStep = () => {
    return (
      <>
        <p>
          You need to verify your email in order to gain access.
          <br />
          Please enter your email to receive a verification code.
        </p>
        <form onSubmit={onSubmit} className={styles.form}>
          <Input
            style={{ width: "100%" }}
            placeholder="Your email address"
            value={props.data.email}
            id={"input-email"}
            onChange={(e) =>
              props.setData({ ...props.data, email: e.target.value })
            }
            contentBefore={<MailRegular />}
          />
          <Button
            appearance={"primary"}
            icon={<SendRegular />}
            style={{ width: "200px" }}
            type={"submit"}
          >
            Send Code
          </Button>
        </form>
      </>
    );
  };

  const renderFailed = () => {
    return (
      <>
        <p>
          The provided email does not have access to these shared inspections.
          <br /> Please try another email or{" "}
          <Link href={"mailto:support@scoutdi.com"}>contact support</Link>.
        </p>
        <Button
          appearance={"primary"}
          icon={<ArrowLeftRegular />}
          style={{ width: "200px" }}
          onClick={() => setFailed(false)}
        >
          Try again
        </Button>
      </>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gap: tokens.spacingVerticalXXXL,
      }}
    >
      {failed ? renderFailed() : renderStep()}
    </div>
  );
};

const TotpStep = (props: VertificationStepProps) => {
  const styles = useStyles();
  const apiClient = useContext(ApiContext);
  const [failed, setFailed] = useState(false);

  const onSubmit = useCallback(
    (event: React.BaseSyntheticEvent) => {
      event.preventDefault();
      apiClient
        .verifyTotp(props.data.shareId, props.data.totp, props.data.email)
        .then((response) => {
          props.setData({ ...props.data, token: response.token });
          props.onFinished?.();
        })
        .catch(() => setFailed(true));
    },
    [apiClient, props],
  );

  const renderStep = () => {
    return (
      <>
        <p>
          We have sent a verification code to <br />
          <b>{props.data.email}</b>. <br />
          Please enter your verification code below.
        </p>
        <form onSubmit={onSubmit} className={styles.form}>
          <OtpInput
            shouldAutoFocus={true}
            containerStyle={{ width: "100%" }}
            value={props.data.totp}
            onChange={(value) => props.setData({ ...props.data, totp: value })}
            numInputs={6}
            renderSeparator={() => <span style={{ padding: "4px" }} />}
            inputType={"tel"}
            skipDefaultStyles={true}
            renderInput={(inputProps, index) => (
              <input
                id={`input-totp-${index}`}
                {...inputProps}
                className={styles.input}
              />
            )}
          />
          <Button
            type={"submit"}
            appearance={"primary"}
            icon={<CheckmarkRegular />}
            style={{ width: "200px" }}
          >
            Verify Code
          </Button>
        </form>
      </>
    );
  };

  const renderFailed = () => {
    return (
      <>
        <div />
        <p>
          The provided code is invalid.
          <br /> Please try again.
        </p>
        <Button
          appearance={"primary"}
          icon={<ArrowLeftRegular />}
          style={{ width: "200px" }}
          onClick={() => setFailed(false)}
        >
          Try again
        </Button>
      </>
    );
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        gap: tokens.spacingVerticalXXXL,
      }}
    >
      {failed ? renderFailed() : renderStep()}
    </div>
  );
};

const StepCounter = (props: { current: number; total: number }) => {
  // const styles = useStyles();
  return (
    <div
      style={{
        display: "flex",
        gap: tokens.spacingHorizontalL,
        alignItems: "center",
      }}
    >
      {Array(props.total)
        .fill(0)
        .map((_, i) => {
          return (
            <div
              key={i}
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "5px",
                backgroundColor:
                  i === props.current
                    ? tokens.colorCompoundBrandForeground1Pressed
                    : tokens.colorNeutralForeground3,
              }}
            />
          );
        })}
    </div>
  );
};

const Login: React.FC = () => {
  const styles = useStyles();
  const { user, login } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const shareId = searchParams.get("si") || "";

  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    email: "",
    totp: "",
    shareId: shareId,
    token: "",
  });

  const logo = useBrandLogo("large");

  useEffect(() => {
    if (data.token !== "") {
      login(data.email, data.token);
    }
  }, [data, login]);

  useEffect(() => {
    if (user !== null) {
      navigate(
        location?.state?.from || {
          pathname: `/share/${shareId}`,
        },
        {
          replace: true,
        },
      );
    }
  }, [location?.state?.from, navigate, shareId, user]);

  const steps = [
    <EmailStep
      data={data}
      setData={setData}
      onFinished={() => {
        setStep(1);
      }}
    />,
    <TotpStep data={data} setData={setData} />,
  ];

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <div className={styles.innerCard}>
          {logo}
          <div className={styles.cardContent}>{steps[step]}</div>
          <StepCounter current={step} total={steps.length} />
        </div>
      </Card>
    </div>
  );
};

export default Login;
