import { IFindingAdditionalData } from "scout-sharing-models";
import {
  ArrowDownRegular,
  ArrowRightRegular,
  ArrowUpRegular,
  FluentIcon,
} from "@fluentui/react-icons/lib/fonts";
import { additionalDataInfo } from "../../../../../../../utils.ts";
import {
  createTableColumn,
  Table,
  TableBody,
  TableCell,
  TableCellLayout,
  TableColumnDefinition,
  TableColumnSizingOptions,
  TableRow,
  useTableColumnSizing_unstable,
  useTableFeatures,
} from "@fluentui/react-components";
import React, { ReactComponentElement, useState } from "react";
import { VideoRegular } from "@fluentui/react-icons";

type PositionDatum = {
  key: string;
  icon: ReactComponentElement<FluentIcon>;
  description: string;
  value: string;
};

const columnsDef: TableColumnDefinition<PositionDatum>[] = [
  createTableColumn({
    columnId: "icon",
  }),
  createTableColumn<PositionDatum>({
    columnId: "description",
  }),
  createTableColumn<PositionDatum>({
    columnId: "value",
  }),
];

export const PositionTable = (props: {
  additionalData?: IFindingAdditionalData;
  cameraPitch?: number;
}) => {
  const [columns] =
    useState<TableColumnDefinition<PositionDatum>[]>(columnsDef);
  const [columnSizingOptions] = useState<TableColumnSizingOptions>({
    icon: {
      idealWidth: 30,
      minWidth: 30,
    },
    description: {
      minWidth: 100,
      defaultWidth: 100,
    },
    value: {
      minWidth: 50,
    },
  });

  const positions: PositionDatum[] = [
    {
      key: "distance_up",
      icon: <ArrowUpRegular />,
      description: additionalDataInfo.distance_up.description,
      value: additionalDataInfo.distance_up.formatValue(
        props.additionalData?.distance_up,
        "-",
      ),
    },
    {
      key: "distance_down",
      icon: <ArrowDownRegular />,
      description: additionalDataInfo.distance_down.description,
      value: additionalDataInfo.distance_down.formatValue(
        props.additionalData?.distance_down,
        "-",
      ),
    },
    {
      key: "distance_front",
      icon: <ArrowRightRegular />,
      description: additionalDataInfo.distance_front.description,
      value: additionalDataInfo.distance_front.formatValue(
        props.additionalData?.distance_front,
        "-",
      ),
    },
    {
      key: "camera_pitch",
      icon: <VideoRegular style={{ rotate: "-30deg" }} />,
      description: additionalDataInfo.camera_pitch.description,
      value: additionalDataInfo.camera_pitch.formatValue(
        props.cameraPitch,
        "-",
      ),
    },
  ];

  const { getRows, columnSizing_unstable, tableRef } = useTableFeatures(
    {
      columns: columns,
      items: positions,
    },
    [useTableColumnSizing_unstable({ columnSizingOptions })],
  );

  const rows = getRows();

  return (
    <Table ref={tableRef} {...columnSizing_unstable.getTableProps()}>
      <TableBody>
        {rows.map(({ item }) => (
          <TableRow key={item.key}>
            <TableCell {...columnSizing_unstable.getTableCellProps("icon")}>
              <TableCellLayout
                truncate
                media={item.icon}
                style={{ overflow: "hidden" }}
              />
            </TableCell>
            <TableCell
              {...columnSizing_unstable.getTableCellProps("description")}
            >
              <TableCellLayout truncate>{item.description}</TableCellLayout>
            </TableCell>
            <TableCell {...columnSizing_unstable.getTableCellProps("value")}>
              <TableCellLayout truncate>{item.value}</TableCellLayout>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
