import React, { ComponentProps, useCallback, useEffect, useState } from "react";
import * as THREE from "three";
import { Points } from "three";
import moment from "moment/moment";
import { getEntryAtTime, transformPose } from "../utils/utils.ts";
import { FindingCone } from "./sceneModels/findingCone.tsx";
import { DroneModel } from "./sceneModels/droneModel.tsx";
import { VirtualScene } from "./virtualScene.tsx";
import { FlightPath } from "./sceneModels/flightPath.tsx";
import { PointCloud } from "./sceneModels/pointCloud.tsx";
import {
  DronePathEntry,
  IPCD,
  IStaticMapConfig,
  ScoutObject3DData,
} from "../models.ts";
import { MeasurementsCylinder } from "./sceneModels/measurementsCylinder.tsx";
import { GltfModel } from "./sceneModels/gltfModel.tsx";
import {
  IDroneState,
  ILocalizedPOIMeasurement,
  ITimeDepTransform,
} from "../../../../../../interface.ts";
import POIHoverView from "../../virtual-scene-finding-thumbnail-on-hover/p-o-i-hover-view.tsx";
import { IFinding } from "scout-sharing-models";
import { PointCloudAttribute } from "../utils/3dutils.ts";

export type IGlobalMapConfig =
  | {
      enabled: true;
      file: string;
      points: Points;
      transforms: ITimeDepTransform[];
    }
  | {
      enabled: false;
      file: string | undefined;
      points: Points | undefined;
      transforms: ITimeDepTransform[] | undefined;
    };

// TODO establish correct terminology for the coordinate frames used here.

/**
 * This component is responsible for rendering the 3D scene of the drone and the findings.
 * Users of this component should expect the component to behave like a regular React component. That is
 * any updates to props should trigger a re-render of only the relevant parts of the scene. Hot reloading
 * during development should also work as expected.
 *
 * Design decisions regarding coordinate frames.
 * - There is only one coordinate frame in the scene that is the global coordinate frame
 * - Any transformations to drone pose should therefore be done outside this component.
 *
 * @param props
 * @constructor
 */
export const Scout3DPlayer = (props: {
  sessionStart: moment.Moment;
  dronePath: {
    readonly enabled: boolean;
    readonly path: DronePathEntry[];
  };
  dronePose: IDroneState;
  initialCameraSubjectPosition: THREE.Vector3;
  pointCloud: IPCD | undefined;
  globalMap: IGlobalMapConfig;
  staticMapConfig: IStaticMapConfig;
  pointSize: number;
  pointOpacity: number;
  pointCloudAttribute: PointCloudAttribute;
  pointColorPalette: number;
  onFindingClick: (id: number) => void;
  onPathBubbleClick: (time: number) => void;
  findings: ReadonlyArray<IFinding>;
  localizedMeasurements: ReadonlyArray<ILocalizedPOIMeasurement>;
  onMeasurementClick: (id: number) => void;
}) => {
  const dronePosition = props.dronePose.state.position;
  const droneOrientation = props.dronePose.state.orientation;
  const [findings, setFindings] = useState<ITimeSeriesDataPoint[]>([]);
  const [measurementClusters, setMeasurementClusters] = useState<
    ITimeSeriesDataPoint[]
  >([]);

  const [hoveringFindingIdx, setHoveringFindingIdx] = useState<
    number | undefined
  >(undefined);
  const [hoveringMeasurementIdx, setHoveringMeasurementIdx] = useState<
    number | undefined
  >(undefined);

  const onMouseClick = useCallback(
    (e: Event) => {
      const detail = (e as CustomEvent).detail as ScoutObject3DData;
      if (detail.type === "finding") {
        props.onFindingClick(detail.idx);
      } else if (detail.type === "measurement") {
        props.onMeasurementClick(detail.idx);
      } else if (detail.type === "flightpath") {
        props.onPathBubbleClick(detail.time);
      }
    },
    [props],
  );

  const onMouseEnter = useCallback((e: Event) => {
    const detail = (e as CustomEvent).detail as ScoutObject3DData;
    if (detail.type === "finding") {
      setHoveringFindingIdx(detail.idx);
    } else if (detail.type === "measurement") {
      setHoveringMeasurementIdx(detail.idx);
    }
  }, []);

  const onMouseLeave = useCallback((e: Event) => {
    const detail = (e as CustomEvent).detail as ScoutObject3DData;
    if (detail.type === "finding") {
      setHoveringFindingIdx(undefined);
    } else if (detail.type === "measurement") {
      setHoveringMeasurementIdx(undefined);
    }
  }, []);

  useEffect(() => {
    setFindings(
      getFindingPoses(
        props.sessionStart,
        props.findings,
        props.globalMap?.enabled === true
          ? props.globalMap.transforms
          : undefined,
      ),
    );
  }, [
    props.globalMap?.enabled,
    props.findings,
    props.sessionStart,
    props.globalMap.transforms,
  ]);

  useEffect(() => {
    setMeasurementClusters(
      getMeasurementPoses(
        props.sessionStart,
        props.localizedMeasurements,
        props.globalMap?.enabled === true
          ? props.globalMap.transforms
          : undefined,
      ),
    );
  }, [
    props.globalMap?.enabled,
    props.globalMap.transforms,
    props.localizedMeasurements,
    props.sessionStart,
  ]);

  // Render the point cloud
  let pointCloudComponent: React.ReactElement;
  const pointCloud = props.pointCloud;
  if (props.staticMapConfig.enabled && props.staticMapConfig.loaded) {
    // Do not show point clouds if static map model is enabled
    pointCloudComponent = <></>;
  } else if (props.globalMap.enabled) {
    pointCloudComponent = (
      <PointCloud
        key={"global-map"}
        pointSize={props.pointSize}
        opacity={props.pointOpacity}
        pointCloud={props.globalMap.points}
        pointCloudAttribute={props.pointCloudAttribute}
        colorPalette={props.pointColorPalette}
      />
    );
  } else if (pointCloud !== undefined) {
    pointCloudComponent = (
      <PointCloud
        key={pointCloud.file}
        pointSize={props.pointSize}
        pointCloud={pointCloud.points!}
        opacity={props.pointOpacity}
        pointCloudAttribute={props.pointCloudAttribute}
        colorPalette={props.pointColorPalette}
      />
    );
  } else {
    pointCloudComponent = <></>;
  }

  let hoverState: ComponentProps<typeof POIHoverView> = {
    hover: "none",
  };

  if (hoveringFindingIdx !== undefined) {
    const finding = props.findings[hoveringFindingIdx];
    if (finding !== undefined) {
      hoverState = {
        hover: "finding",
        finding,
      };
    }
  } else if (hoveringMeasurementIdx !== undefined) {
    const localizedMeasurement =
      props.localizedMeasurements[hoveringMeasurementIdx];
    if (localizedMeasurement !== undefined) {
      hoverState = {
        hover: "measurement",
        sessionStartTime: props.sessionStart,
        measurementIdx: hoveringMeasurementIdx,
        localizedMeasurement,
      };
    }
  }

  return (
    <div style={{ height: "100%", width: "100%", overflow: "hidden" }}>
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          left: "10px",
          zIndex: 30,
        }}
      >
        <POIHoverView {...hoverState} />
      </div>
      {/*<canvas*/}
      <VirtualScene
        initialCameraSubjectPosition={props.initialCameraSubjectPosition}
        onMouseClick={onMouseClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <DroneModel orientation={droneOrientation} position={dronePosition} />
        {props.staticMapConfig.enabled && props.staticMapConfig.loaded && (
          <GltfModel
            gltf={props.staticMapConfig.gltfModel}
            quaternion={props.staticMapConfig.transform.quaternion}
            position={props.staticMapConfig.transform.position}
            scale={props.staticMapConfig.transform.scale}
          />
        )}
        {pointCloudComponent}
        {findings.map((f, idx) => (
          <FindingCone
            key={`finding-${f.id}-${props.globalMap?.enabled === true}`}
            idx={idx}
            orientation={f.orientation}
            position={f.position}
          />
        ))}
        {measurementClusters.map((m, idx) => (
          <MeasurementsCylinder
            key={`measurements-${m.id}-${props.globalMap?.enabled === true}`}
            idx={idx}
            orientation={m.orientation}
            position={m.position}
          />
        ))}

        {props.dronePath.enabled && (
          <FlightPath points={props.dronePath.path} />
        )}
      </VirtualScene>
    </div>
  );
};

const transformTimeSeriesDatum = (
  datum: {
    id: number;
    relativeTime: number;
    position: THREE.Vector3;
    orientation: THREE.Quaternion;
  },
  transforms?: ITimeDepTransform[],
): ITimeSeriesDataPoint => {
  let position = datum.position;
  let orientation = datum.orientation;
  if (transforms) {
    const transform = getEntryAtTime(
      transforms,
      datum.relativeTime,
      (t) => t.relativeTime,
    );
    const t = transformPose(position, orientation, transform);
    position = t.position;
    orientation = t.orientation;
  }
  return {
    id: datum.id,
    relativeTime: datum.relativeTime,
    position,
    orientation,
  };
};

const getFindingPoses = (
  sessionStart: moment.Moment,
  findings: readonly IFinding[],
  transforms?: ITimeDepTransform[],
): ITimeSeriesDataPoint[] => {
  return findings.map((f) => {
    const position = new THREE.Vector3(
      f.position_x ?? 0,
      f.position_y ?? 0,
      f.position_z ?? 0,
    );
    position.add(
      new THREE.Vector3(
        f.camera_position_x ?? 0,
        f.camera_position_y ?? 0,
        f.camera_position_z ?? 0,
      ),
    );
    const orientation = new THREE.Quaternion(
      f.orientation_x ?? 0,
      f.orientation_y ?? 0,
      f.orientation_z ?? 0,
      f.orientation_w ?? 1,
    );
    orientation.multiply(
      new THREE.Quaternion(
        f.camera_orientation_x ?? 0,
        f.camera_orientation_y ?? 0,
        f.camera_orientation_z ?? 0,
        f.camera_orientation_w ?? 1,
      ),
    );
    const relativeTime = moment(f.time).diff(sessionStart, "seconds", true);
    return transformTimeSeriesDatum(
      { id: f.id, relativeTime, position, orientation },
      transforms,
    );
  });
};

const getMeasurementPoses = (
  sessionStart: moment.Moment,
  localizedMeasurements: readonly ILocalizedPOIMeasurement[],
  transforms?: ITimeDepTransform[],
): ITimeSeriesDataPoint[] => {
  return localizedMeasurements.map((entry, idx) => {
    const time = entry.measurement.time;
    const { position, orientation } = entry.droneState.state;
    const relativeTime = moment(time).diff(sessionStart, "seconds", true);
    return transformTimeSeriesDatum(
      { id: idx, relativeTime, position, orientation },
      transforms,
    );
  });
};

interface ITimeSeriesDataPoint {
  id: number;
  relativeTime: number;
  position: THREE.Vector3;
  orientation: THREE.Quaternion;
}
