import { FolderRegular } from "@fluentui/react-icons/lib/fonts";
import React from "react";
import {
  ArrowLeftRegular,
  DocumentFolderRegular,
  VideoRegular,
} from "@fluentui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  OverflowItem,
  useOverflowMenu,
  Overflow,
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
} from "@fluentui/react-components";
import {
  CustomOverflowItem,
  OverflowGroupDivider,
  OverflowMenu,
  OverflowMenuProps,
} from "../Overflow/Overflow.tsx";

export type ResourceType = "directory" | "inspection" | "session";

const resourceTypeToIcon = (type: ResourceType) => {
  switch (type) {
    case "directory":
      return <FolderRegular />;
    case "inspection":
      return <DocumentFolderRegular />;
    case "session":
      return <VideoRegular />;
  }
};

export type Resource = {
  type: ResourceType;
  name: string;
  id: string;
};

const renderBreadcrumbItem = (
  el: CustomOverflowItem,
  isLastItem: boolean = false,
) => {
  return (
    <React.Fragment key={`button-items-${el.key}`}>
      <OverflowItem
        id={el.key.toString()}
        groupId={el.key.toString()}
        key={`ofi-${el.key}`}
        priority={isLastItem ? el.key : undefined}
      >
        <BreadcrumbItem>
          <BreadcrumbButton
            {...el.itemProps}
            current={isLastItem}
            key={`button-${el.key}`}
          >
            <span
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {el.item}
            </span>
          </BreadcrumbButton>
        </BreadcrumbItem>
      </OverflowItem>
      {!isLastItem && <OverflowGroupDivider groupId={el.key.toString()} />}
    </React.Fragment>
  );
};

const BreadCrumbOverflowMenu: React.FC<OverflowMenuProps> = (props) => {
  const { isOverflowing, overflowCount } = useOverflowMenu<HTMLButtonElement>();

  if (!isOverflowing) {
    return null;
  }

  return (
    <>
      <OverflowMenu {...props} />
      {props.items.length - overflowCount > 0 && <BreadcrumbDivider />}
    </>
  );
};

type ResourceBreadcrumbProps = {
  resourcePath: Resource[];
};

const ResourceBreadcrumb: React.FC<ResourceBreadcrumbProps> = (
  props: ResourceBreadcrumbProps,
) => {
  const navigate = useNavigate();
  const { shareId } = useParams();

  const resourcePath = [
    {
      type: "directory",
      id: "shared-inspections",
      name: "Shared inspections",
    } as Resource,
    ...props.resourcePath,
  ];

  const items: CustomOverflowItem[] = resourcePath.map((resource, index) => ({
    key: index,
    item: resource.name,
    itemProps: {
      icon: resourceTypeToIcon(resource.type),
      onClick: () => navigateTo(resource),
    },
  }));

  const navigateTo = (resource: Resource) => {
    if (resource?.type === "inspection") {
      navigate({ pathname: `/share/${shareId}/inspections/${resource.id}` });
    } else if (resource?.type === "directory") {
      const toIdx = resourcePath.findIndex((r) => r.id === resource.id);
      const ri = resourcePath
        .slice(1, toIdx + 1)
        .reduce((acc, r) => acc + r.id + "/", "")
        .slice(0, -1);
      navigate({
        pathname: `/share/${shareId}`,
        search: ri.length > 0 ? `ri=${ri}` : "",
      });
    }
  };

  const handleBack = () => {
    const previous = resourcePath.at(-2);
    if (previous !== undefined) {
      navigateTo(previous);
    }
  };

  return (
    <Overflow overflowDirection={"start"}>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbButton
            icon={<ArrowLeftRegular />}
            style={{
              display: props.resourcePath.length > 0 ? "" : "none",
            }}
            onClick={handleBack}
          />
        </BreadcrumbItem>
        <BreadCrumbOverflowMenu items={items} />
        {items.map((item) => {
          return renderBreadcrumbItem(item, item.key === items.length - 1);
        })}
      </Breadcrumb>
    </Overflow>
  );
};

export default ResourceBreadcrumb;
