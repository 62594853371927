import React from "react";
import { additionalDataInfo } from "../../../../../utils.ts";
import { Body2, Subtitle2 } from "@fluentui/react-components";
import { Video48Filled } from "@fluentui/react-icons";

interface IProps {
  cameraPitch?: number;
}

const CameraPane: React.FC<IProps> = (props: IProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "12px",
        height: "100%",
        alignContent: "center",
      }}
    >
      <Subtitle2 align={"center"}>Camera</Subtitle2>
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Video48Filled
          style={{
            alignSelf: "center",
            transform: `rotate(${-(props.cameraPitch ?? 0.0)}deg)`,
          }}
        />
      </div>
      <Body2 align={"center"}>
        {additionalDataInfo.camera_pitch.formatValue(props.cameraPitch)}
      </Body2>
    </div>
  );
};

export default CameraPane;
