import React, {
  Children,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
} from "react";
import { GuidedTourContext } from "./GuidedTourProvider";

type GuidedTourStepProps = {
  title: string;
  text: string;
  priority?: number;
};

const GuidedTourStep: React.FC<PropsWithChildren<GuidedTourStepProps>> = (
  props,
) => {
  const { addStep } = useContext(GuidedTourContext);
  const firstChildRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (firstChildRef.current !== null) {
      addStep(props.title, {
        ref: firstChildRef,
        title: props.title,
        text: props.text,
        priority: props.priority || Infinity,
      });
    }
  }, [addStep, props.title, props.text, props.priority]);

  return (
    <>
      {Children.map(props.children, (child, index) => {
        return index === 0
          ? React.cloneElement(child as React.ReactElement, {
              ref: firstChildRef,
            })
          : child;
      })}
    </>
  );
};

export default GuidedTourStep;
