import { useEffect, useRef, useState } from "react";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { SceneContextData } from "../models.ts";
import {
  Scene,
  WebGLRenderer,
  PerspectiveCamera,
  DirectionalLight,
  AmbientLight,
  Vector3,
  Vector2,
  Object3D,
  Raycaster,
  Intersection,
  Camera,
  Mesh,
  MeshBasicMaterial,
  TorusGeometry,
} from "three";
import WebGL from "three/examples/jsm/capabilities/WebGL";

/**
 * This hook bootstraps the three.js scene and returns an instance of [SceneContextData]
 *
 * The instantiation involves the following,
 *
 * - Setting up a three.js scene.
 * - Setting up global lights (directional and ambient)
 * - Setting up a camera and orbit controls associated with the camera
 * - Setting up a ray caster with the camera
 * - Setting up an event target that emits hover and click events
 * - Setting up a resize observer on the container element that correctly updates the scene upon resizes
 * - Setting up a render loop that takes care of rendering the scene.
 *
 * The [SceneContextData] exposes,
 * - Three.js scene
 * - Three.js camera
 * - Three.js renderer
 * - A set of hoverable objects that is used by the ray caster for testing hovering over them
 * - A set of clickable objects that is used by the ray caster for testing clicking on them
 * - A invalidate function that can be used to invalidate the scene and trigger a re-render
 */
export const useThreeJsScene = (initialCameraSubjectPosition: Vector3) => {
  const invalidatedRef = useRef(true);
  const controlsRef = useRef<OrbitControls | undefined>(undefined);
  const containerRef = useRef<HTMLDivElement>(null);
  const hoveredRef = useRef<Object3D | undefined>();
  const pointerDownRef = useRef<Object3D | undefined>();
  const [sceneContextData, setSceneContextData] = useState<SceneContextData>({
    containerRef,
    scene: undefined,
    camera: undefined,
    renderer: undefined,
    hoverables: new Set(),
    clickables: new Set(),
    events: new EventTarget(),
    invalidate: () => (invalidatedRef.current = true),
  });

  const renderFnRef = useRef<(() => void) | undefined>(undefined);

  // By the time this useEffect hook is called the containerRef.current is already set because the
  // component is mounted.
  useEffect(() => {
    const currentContainer = containerRef.current;
    if (currentContainer === null) {
      throw new Error("Invalid state. containerRef.current is null");
    }
    const newCanvas = document.createElement(
      "canvas",
    ) as unknown as HTMLCanvasElement;
    const webglContext = getWebGLContext(newCanvas);
    if (webglContext === null) {
      return;
    }
    const scene = new Scene();

    const renderer = new WebGLRenderer({
      antialias: true,
      alpha: true,
      canvas: newCanvas,
      context: webglContext,
      preserveDrawingBuffer: true,
    });

    renderer.setClearColor(0xffffff, 0);

    getSceneGlobalLights().forEach((light) => scene.add(light));

    const camera = new PerspectiveCamera(
      75,
      currentContainer.clientWidth / currentContainer.clientHeight,
      0.1,
      1000,
    );
    camera.up = new Vector3(0, 0, 1);
    camera.position.copy(initialCameraSubjectPosition);
    camera.position.z = 15;
    camera.lookAt(initialCameraSubjectPosition);

    renderer.setSize(
      currentContainer.clientWidth,
      currentContainer.clientHeight,
    );

    currentContainer.appendChild(newCanvas);

    const marker = createRotationMarker();
    marker.visible = false;
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.addEventListener("change", () => {
      marker.position.copy(controls.target);
      invalidatedRef.current = true;
    });
    controls.target.set(0, 0, 0);
    controls.screenSpacePanning = true;
    controls.keyPanSpeed = 10;
    controls.rotateSpeed = 0.6;
    controls.enableZoom = true;
    controls.maxPolarAngle = Math.PI;
    controls.enabled = true;
    controls.zoomToCursor = true;
    controlsRef.current = controls;

    // Set the marker's position to match the OrbitControls target
    marker.position.copy(controls.target);
    const markerVisualizerHandler = (e: MouseEvent | TouchEvent) => {
      marker.visible = e.type === "pointerdown" || e.type === "touchstart";
      invalidatedRef.current = true;
    };

    renderer.domElement.addEventListener(
      "pointerdown",
      markerVisualizerHandler,
    );
    renderer.domElement.addEventListener("pointerup", markerVisualizerHandler);
    renderer.domElement.addEventListener("touchstart", markerVisualizerHandler);
    renderer.domElement.addEventListener("touchend", markerVisualizerHandler);

    const { events, hoverables, clickables, rayCastCleanupFn } =
      setupRayCasting(newCanvas, camera, hoveredRef, pointerDownRef);

    scene.add(marker);
    const sizeVector = new Vector2();
    const resizeObserverCleanup = observeContainerResize(
      currentContainer,
      (width, height) => {
        renderer.getSize(sizeVector);
        const aspect = width / height;
        renderer.setSize(width, height);
        camera.aspect = aspect;
        camera.updateProjectionMatrix();
        controlsRef.current?.update();
        invalidatedRef.current = true;
        renderer.render(scene, camera);
      },
    );

    setSceneContextData({
      containerRef,
      scene,
      renderer,
      camera,
      hoverables,
      events,
      clickables,
      invalidate: () => (invalidatedRef.current = true),
    });

    const renderFn = () => {
      if (invalidatedRef.current) {
        marker.position.copy(controls.target);
        renderer.render(scene, camera);
        invalidatedRef.current = false;
      }
      if (renderFnRef.current !== undefined) {
        requestAnimationFrame(renderFnRef.current);
      }
    };

    renderFnRef.current = renderFn;

    // perform initial render
    renderFn();

    return () => {
      scene.remove(marker);
      renderFnRef.current = undefined;
      currentContainer.removeChild(newCanvas);
      controls.dispose();
      renderer.dispose();
      renderer.domElement.removeEventListener(
        "pointerdown",
        markerVisualizerHandler,
      );
      renderer.domElement.removeEventListener(
        "pointerup",
        markerVisualizerHandler,
      );
      renderer.domElement.removeEventListener(
        "touchstart",
        markerVisualizerHandler,
      );
      renderer.domElement.removeEventListener(
        "touchend",
        markerVisualizerHandler,
      );
      resizeObserverCleanup();
      rayCastCleanupFn();
    };
  }, [initialCameraSubjectPosition]);

  return sceneContextData;
};

const observeContainerResize = (
  currentContainer: HTMLDivElement,
  onResize: (w: number, h: number) => void,
) => {
  const resizeObserver = new ResizeObserver((entries) => {
    const { width, height } = entries[0].contentRect;
    if (width === 0 || height === 0) return;
    onResize(width, height);
  });

  resizeObserver.observe(currentContainer);

  return () => resizeObserver.disconnect();
};
const setupRayCasting = (
  newCanvas: HTMLCanvasElement,
  camera: Camera,
  hoveredRef: React.MutableRefObject<Object3D | undefined>,
  pointerDownRef: React.MutableRefObject<Object3D | undefined>,
) => {
  const raycaster = new Raycaster();
  const mouse = new Vector2();
  const hoverables: Set<Object3D> = new Set();
  const clickables: Set<Object3D> = new Set();
  const events = new EventTarget();
  const pointerEventHandler = (event: PointerEvent) => {
    const rect = (event.target as HTMLCanvasElement).getBoundingClientRect();
    mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);

    const hovering = raycaster.intersectObjects(Array.from(hoverables));
    const clicking = raycaster.intersectObjects(Array.from(clickables));

    newCanvas.style.cursor =
      clicking.length > 0 || hovering.length > 0 ? "pointer" : "default";

    const prevHover = hoveredRef.current;
    // reduce by distance
    let hoverCandidate: Intersection | undefined;
    hovering.forEach((intersection) => {
      if (
        hoverCandidate === undefined ||
        hoverCandidate.distance > intersection.distance
      ) {
        hoverCandidate = intersection;
      }
    });

    if (prevHover !== hoverCandidate?.object) {
      if (prevHover !== undefined) {
        events.dispatchEvent(
          new CustomEvent("mouseleave", { detail: prevHover.userData }),
        );
      }
      if (hoverCandidate !== undefined) {
        events.dispatchEvent(
          new CustomEvent("mouseenter", {
            detail: hoverCandidate.object.userData,
          }),
        );
      }
    }

    hoveredRef.current = hoverCandidate?.object;

    const isLeftClickDown =
      event.type === "pointerdown" && (event.buttons & 1) !== 0;
    const isLeftClickUp =
      event.type === "pointerup" && (event.buttons & 1) === 0;
    if (isLeftClickDown) {
      let clickCandidate: Intersection | undefined;
      clicking.forEach((intersection) => {
        if (
          clickCandidate === undefined ||
          clickCandidate.distance > intersection.distance
        ) {
          clickCandidate = intersection;
        }
      });

      if (clickCandidate !== undefined) {
        pointerDownRef.current = clickCandidate.object;
      }
    } else if (isLeftClickUp) {
      let clickCandidate: Intersection | undefined;
      clicking.forEach((intersection) => {
        if (
          clickCandidate === undefined ||
          clickCandidate.distance > intersection.distance
        ) {
          clickCandidate = intersection;
        }
      });
      if (
        clickCandidate !== undefined &&
        clickCandidate.object === pointerDownRef.current
      ) {
        events.dispatchEvent(
          new CustomEvent("click", { detail: clickCandidate.object.userData }),
        );
      }
    } else {
      pointerDownRef.current = undefined;
    }
  };

  newCanvas.addEventListener("pointermove", pointerEventHandler);
  newCanvas.addEventListener("pointerdown", pointerEventHandler);
  newCanvas.addEventListener("pointerup", pointerEventHandler);
  return {
    events,
    hoverables,
    clickables,
    rayCastCleanupFn: () => {
      newCanvas.removeEventListener("pointermove", pointerEventHandler);
      newCanvas.removeEventListener("pointerdown", pointerEventHandler);
      newCanvas.removeEventListener("pointerup", pointerEventHandler);
    },
  };
};

const getSceneGlobalLights = () => {
  const topFront = new DirectionalLight(0xffffff, 2);
  topFront.position.set(-10, 0, 10);
  topFront.rotateY(Math.PI / 4);

  const bottomFront = new DirectionalLight(0xffffff, 2);
  bottomFront.position.set(10, 0, -10);
  bottomFront.rotateY(-Math.PI / 4);

  const ambientLight = new AmbientLight(0x404040, 10); // soft white light

  return [topFront, bottomFront, ambientLight];
};

const getWebGLContext = (
  canvas: HTMLCanvasElement,
): WebGLRenderingContext | WebGL2RenderingContext | null => {
  if (WebGL.isWebGL2Available()) {
    return canvas.getContext("webgl2");
  } else if (WebGL.isWebGLAvailable()) {
    return canvas.getContext("webgl");
  } else {
    return null;
  }
};

const createRotationMarker = () => {
  const radius = 0.2;
  const tubeRadius = 0.02;
  const redTorusGeometry = new TorusGeometry(radius, tubeRadius, 100);
  const redTorusMaterial = new MeshBasicMaterial({
    color: 0xabc1d1,
    transparent: true,
    opacity: 0.3,
  });
  return new Mesh(redTorusGeometry, redTorusMaterial);
};
