import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Subtitle2,
  tokens,
} from "@fluentui/react-components";
import React, { PropsWithChildren } from "react";
import { DismissRegular } from "@fluentui/react-icons";
import {
  hotKeyDecreasePointOpacity,
  hotKeyDecreasePointSize,
  hotKeyIncreasePointOpacity,
  hotKeyIncreasePointSize,
  hotKeyShowHidePath,
  hotKeyTogglePointColorMode,
  hotKeyTogglePointColorPalette,
} from "../../StreamerScene.tsx";

type VirtualSceneHelpDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const VirtualSceneHelpDialog: React.FC<VirtualSceneHelpDialogProps> = (
  props,
) => {
  return (
    <Dialog
      open={props.open}
      onOpenChange={(_, data) => {
        props.setOpen(data.open);
      }}
    >
      <DialogSurface>
        <DialogBody style={{ minHeight: "300px" }}>
          <DialogTitle
            action={
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="subtle" icon={<DismissRegular />} />
              </DialogTrigger>
            }
          >
            Virtual scene interaction
          </DialogTitle>
          <DialogContent>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: tokens.spacingVerticalXL,
              }}
            >
              <Card title={"Camera interaction"}>
                <CardHeader
                  header={<Subtitle2> Camera interaction </Subtitle2>}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: tokens.spacingHorizontalM,
                  }}
                >
                  <div style={{ width: "24px" }}>
                    <LeftClickIcon />
                  </div>
                  <span>
                    Left click and drag to <strong>rotate</strong> the camera
                    around.
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: tokens.spacingHorizontalM,
                  }}
                >
                  <div style={{ width: "24px" }}>
                    <RightClickIcon />
                  </div>
                  <span>
                    Right click and drag to move the camera{" "}
                    <strong>up, down, left and right</strong>.
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: tokens.spacingHorizontalM,
                  }}
                >
                  <div style={{ width: "24px" }}>
                    <MiddleClickIcon />
                  </div>
                  <span>
                    Use scroll wheel to move the camera{" "}
                    <strong>forward and backwards</strong>.
                  </span>
                </div>
              </Card>
              <Card title={"3D environment interaction"}>
                <CardHeader
                  header={<Subtitle2> 3D environment interaction</Subtitle2>}
                />
                <InteractionItem
                  alt="Drone path icon"
                  src={"/images/help_path.png"}
                >
                  Use <HotKey>{hotKeyShowHidePath.toLocaleUpperCase()}</HotKey>{" "}
                  hotkey to show/hide the drone flight path. You can{" "}
                  <strong>click</strong> on desired locations on the path to
                  move drone and seek video to that position.
                </InteractionItem>
                <InteractionItem
                  alt="Drone POI cone"
                  src={"/images/help_poi_cone.png"}
                >
                  Red cones represent a point of interest image taken by the
                  camera. <strong>Hover the mouse</strong> to get a preview or{" "}
                  <strong>click</strong> to move drone and seek video to that
                  position.
                </InteractionItem>
                <InteractionItem
                  alt="Point cloud"
                  src={"/images/help_point_clouds.png"}
                >
                  The point size could be increased with{" "}
                  <HotKey>{hotKeyIncreasePointSize}</HotKey> and decreased with{" "}
                  <HotKey>{hotKeyDecreasePointSize}</HotKey> hotkeys. Point
                  opacity is increased with{" "}
                  <HotKey>{hotKeyIncreasePointOpacity}</HotKey> and decreased
                  with <HotKey>{hotKeyDecreasePointOpacity}</HotKey>.
                </InteractionItem>
                <InteractionItem
                  alt="Point cloud palette"
                  src={"/images/help_point_palette.png"}
                >
                  Point clouds could be colored by <strong>height</strong>, or{" "}
                  <strong>environment information from the lidar sensor</strong>{" "}
                  if available. This could be changed with{" "}
                  <HotKey>
                    {hotKeyTogglePointColorMode.toLocaleUpperCase()}
                  </HotKey>{" "}
                  hotkey. Point color palette could be changed with{" "}
                  <HotKey>
                    {hotKeyTogglePointColorPalette.toLocaleUpperCase()}
                  </HotKey>{" "}
                  hotkey.
                </InteractionItem>
              </Card>
            </div>
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

const LeftClickIcon = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 14H24V23C24 27.9706 19.9706 32 15 32H9C4.02944 32 0 27.9706 0 23V14Z"
      fill="#D9D9D9"
    />
    <rect width="11" height="12" fill="#EF4036" />
    <rect x="13" width="11" height="12" fill="#D9D9D9" />
  </svg>
);

const RightClickIcon = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 14H24V23C24 27.9706 19.9706 32 15 32H9C4.02944 32 0 27.9706 0 23V14Z"
      fill="#D9D9D9"
    />
    <rect width="11" height="12" fill="#D9D9D9" />
    <rect x="13" width="11" height="12" fill="#EF4036" />
  </svg>
);

const MiddleClickIcon = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H24V23C24 27.9706 19.9706 32 15 32H9C4.02944 32 0 27.9706 0 23V0Z"
      fill="#D9D9D9"
    />
    <rect x="10" y="4" width="4" height="7" fill="#EF4036" />
  </svg>
);

/**
 * Creates a 50px by 50px image with 5px rounded corners with the given src
 * @param props
 * @constructor
 */
const InteractionItem = (
  props: PropsWithChildren<{ src: string; alt: string }>,
) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingTop: "5px",
        paddingBottom: "5px",
        alignItems: "center",
      }}
    >
      <img
        alt={props.alt}
        src={props.src}
        style={{
          width: "50px",
          objectFit: "cover",
          height: "50px",
          borderRadius: "5px",
          marginRight: "10px",
        }}
      />
      <div>{props.children}</div>
    </div>
  );
};

const HotKey = (props: PropsWithChildren) => {
  return (
    <span
      style={{
        backgroundColor: tokens.colorNeutralBackground3,
        color: "#D9D9D9",
        paddingRight: "5px",
        paddingTop: "2px",
        paddingBottom: "2px",
        paddingLeft: "5px",
        borderRadius: "5px",
      }}
    >
      {props.children}
    </span>
  );
};

export default VirtualSceneHelpDialog;
