import { makeStyles } from "@fluentui/react-components";
import React from "react";

const useStyles = makeStyles({
  previewImage: {
    width: "100px",
    height: "100px",
    cursor: "pointer",
  },
});

export type PreviewImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  selected: boolean;
};

export const PreviewImage: React.FC<PreviewImageProps> = (props) => {
  const { selected, ...rest } = props;
  const styles = useStyles();

  return (
    <img
      className={styles.previewImage}
      style={{
        border: selected ? "2px solid white" : "",
      }}
      {...rest}
      alt={rest.alt || "Preview image"}
    />
  );
};
