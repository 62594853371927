import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Checkbox,
  Dropdown,
  Label,
  makeStyles,
  mergeClasses,
  shorthands,
  Textarea,
  tokens,
} from "@fluentui/react-components";
import React, { useContext } from "react";
import { getCameraPitchInDegrees } from "../../../../../../../utils.ts";
import { PositionTable } from "./PositionTable.tsx";
import LightInformationPanel from "./LightInformation.tsx";
import { FindingsDialogControllerContext } from "../FindingsDialogControllerProvider.tsx";
import { POITagPicker } from "./POITagPicker.tsx";

const useStyles = makeStyles({
  sidePanel: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minWidth: "300px",
    maxWidth: "300px",
    paddingLeft: tokens.spacingHorizontalL,
    paddingRight: tokens.spacingHorizontalL,
    ...shorthands.overflow("none", "auto"),
    // ...shorthands.padding(tokens.spacingHorizontalL),
    "@media (max-width: 900px)": {
      ...shorthands.flex(1),
      minWidth: "100%",
      maxWidth: "100%",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  accordionPanel: {
    marginRight: 0,
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingVerticalM),
  },
  accordionContent: {
    minWidth: "100%",
  },

  accordionSpacing: {
    paddingRight: 0,
    ...shorthands.padding(tokens.spacingHorizontalL),
    ...shorthands.gap(tokens.spacingVerticalM),
  },
  accordionBottomGroup: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  row: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    ...shorthands.gap(tokens.spacingHorizontalL),
    justifyContent: "space-between",
  },
});

const FindingDataPanel: React.FC = () => {
  const styles = useStyles();
  const { currentFinding } = useContext(FindingsDialogControllerContext);

  return (
    <>
      <div className={styles.sidePanel}>
        <Accordion
          collapsible
          multiple
          defaultOpenItems={["notes", "position", "lights", "category", "tags"]}
        >
          <AccordionItem value={"notes"}>
            <AccordionHeader>Notes</AccordionHeader>
            <AccordionPanel className={styles.accordionPanel}>
              <Textarea
                className={styles.accordionContent}
                placeholder={"No notes"}
                value={currentFinding?.description || ""}
                resize="vertical"
                readOnly={true}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value={"position"}>
            <AccordionHeader className={styles.accordionPanel}>
              Position
            </AccordionHeader>
            <AccordionPanel className={styles.accordionPanel}>
              <Textarea
                className={styles.accordionContent}
                placeholder={"No position notes"}
                value={currentFinding?.position_text || ""}
                resize="vertical"
                readOnly={true}
              />
              <PositionTable
                additionalData={currentFinding?.additional_data}
                cameraPitch={
                  currentFinding
                    ? getCameraPitchInDegrees(currentFinding)
                    : undefined
                }
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value={"lights"}>
            <AccordionHeader>Lights</AccordionHeader>
            <AccordionPanel className={styles.accordionPanel}>
              <LightInformationPanel
                lightData={currentFinding?.additional_data.lights}
              />
            </AccordionPanel>
          </AccordionItem>
          <AccordionItem value={"tags"}>
            <AccordionHeader>Tags</AccordionHeader>
            <AccordionPanel className={styles.accordionPanel}>
              <POITagPicker tags={currentFinding?.tags ?? []} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>

        <div
          className={mergeClasses(
            styles.accordionBottomGroup,
            styles.accordionSpacing,
          )}
        >
          <Label id={"type"}>Type</Label>
          <Dropdown
            aria-labelledby={"type"}
            placeholder="Other"
            disabled={true}
            value={currentFinding?.type.name}
            className={styles.accordionContent}
          />
          <Label id={"severity"}>Severity</Label>
          <Dropdown
            aria-labelledby={"severity"}
            placeholder="NOT APPLICABLE"
            disabled={true}
            defaultValue={currentFinding?.severity}
            className={styles.accordionContent}
          />
          <div className={styles.row}>
            <Checkbox
              label={"Flagged"}
              checked={currentFinding?.flagged}
              disabled={true}
            />
            <Checkbox
              label={"In report"}
              checked={currentFinding?.include_in_report}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FindingDataPanel;
