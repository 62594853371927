import React, { PropsWithChildren } from "react";
import { ThemeProvider, ThemeProviderProps } from "@fluentui/react";
import { ThemeContext_unstable as V9ThemeContext } from "@fluentui/react-shared-contexts";
import { useContext } from "react";
import { BrandVariants, Theme, webDarkTheme } from "@fluentui/react-components";
import { createV8Theme } from "@fluentui/react-migration-v8-v9";
import * as d3Color from "d3-color";

const brandInvariant: BrandVariants = {
  10: "",
  20: "",
  30: "",
  40: "",
  50: "",
  60: "",
  70: "",
  80: "",
  90: "",
  100: "",
  110: "",
  120: "",
  130: "",
  140: "",
  150: "",
  160: "",
};

const FluentV9Wrapper: React.FC<PropsWithChildren<ThemeProviderProps>> = ({
  children,
  ...props
}) => {
  const parentV9Theme = useContext(V9ThemeContext) as Theme;
  const v9Theme: Theme = parentV9Theme ? parentV9Theme : webDarkTheme;
  const backgroundColor = d3Color.hsl(v9Theme.colorNeutralBackground2);
  const foregroundColor = d3Color.hsl(v9Theme.colorNeutralForeground1);
  const myV8Theme = createV8Theme(
    brandInvariant,
    v9Theme,
    backgroundColor.l < foregroundColor.l,
  );

  return (
    <ThemeProvider theme={myV8Theme} {...props}>
      {children}
    </ThemeProvider>
  );
};

export default FluentV9Wrapper;
